import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';
import { IWithRouteProps } from 'reports/utils/router';
import { withRoute } from 'react-router5';
import { actions as routerActions } from 'redux-router5';
import * as analytics from 'reports/analytics';

import { IconNames } from '@blueprintjs/icons';

import { CreateButtonDropdown } from 'reports/components/core/controls/CreateButtonDropdown';
import { LibraryMain } from 'reports/components/library/main/components';

import ProfilesList from 'reports/modules/profile/components/ProfilesList';
import * as auth from 'reports/modules/auth';
import * as pro from 'reports/models/profile';
import { createUniqueDescription } from 'reports/utils/helpers';

interface IOwnProps {
    preview?: React.ReactNode;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps & IWithRouteProps;

class ProfileLibrary extends React.PureComponent<IProps> {
    render() {
        const { user, preview, route, getProfiles, refreshItem } = this.props;
        const filters = [
            { name: 'type', text: `Project`, value: 'project' },
            { name: 'type', text: `Conditions`, value: 'scenario' },
            { name: 'type', text: `Electrical`, value: 'electrical' },
            { name: 'type', text: `Mechanical`, value: 'mechanical' },
        ];
        const profileTypes: pro.IProfileType[] = ['project', 'scenario', 'electrical', 'mechanical'];
        const canViewFinancials = user.hasFinancialsAccess();
        if (canViewFinancials) {
            filters.push({
                name: 'type',
                text: `Financial`,
                value: 'financial',
            });
            profileTypes.push('financial');
        }
        return (
            <LibraryMain
                resourceIdName="profile_id"
                loadItems={getProfiles}
                refreshItem={refreshItem}
                views={[
                    {
                        view: <ProfilesList />,
                        icon: IconNames.LIST,
                        id: 'profile-list',
                    },
                ]}
                quickFilters={[
                    {
                        name: 'bookmarked',
                        icon: IconNames.STAR,
                        text: 'Favorited',
                    },
                    {
                        name: 'team_id',
                        icon: IconNames.PEOPLE,
                        text: `Only Show My Team`,
                        value: user.team.team_id,
                    },
                    ...filters,
                ]}
                id="profiles-views"
                preview={preview}
                contextBarControls={
                    <CreateButtonDropdown<pro.IProfileType>
                        createText="New Profile"
                        items={profileTypes}
                        itemRenderer={(profileType) => pro.ProfileTypes[profileType]}
                        onItemSelect={(profileType) => this.createDefaultProfile(profileType)}
                    />
                }
                selectedItemId={route.params.profileId}
            />
        );
    }

    async createDefaultProfile(profileType: pro.IProfileType) {
        const { getProfiles, createProfile, getProfileDefaults, navigateTo } = this.props;
        const defaultProfileData = await getProfileDefaults();
        const baseProfileName = `New ${pro.ProfileTypes[profileType]} Profile`;
        const matchingProfiles = await getProfiles({ q: baseProfileName });

        const newProfile = await createProfile({
            type: profileType,
            name: createUniqueDescription(
                baseProfileName,
                matchingProfiles.map((p) => p.name),
                true,
            ),
            description: '',
            data: defaultProfileData[profileType],
            public: false,
        });

        analytics.track('profile.new', { profile_type: profileType });
        navigateTo('app.profiles.profile.edit', {
            profileId: newProfile.profile_id,
        });
    }
}

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state)!,
    refreshItem: (item) => pro.selectors.byObject(state, item, { creator: true, team: true }) || item,
});

const mapDispatchToProps = bindActions(() => ({
    getProfiles: pro.api.index,
    createProfile: pro.api.create,
    getProfileDefaults: pro.api.defaults,
    navigateTo: routerActions.navigateTo,
}));

export default connect(mapStateToProps, mapDispatchToProps)(withRoute(ProfileLibrary));
