/* tslint:disable:variable-name */
import * as React from 'react';
import { connect } from 'react-redux';
import { withRoute } from 'react-router5';
import { actions as routerActions } from 'redux-router5';

import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';
import { IWithRouteProps } from 'reports/utils/router';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';
import UpsellBanner from 'reports/components/UpsellBanner';

import * as finTemp from 'reports/models/financial_template';

import * as auth from 'reports/modules/auth';
import { actions } from 'reports/modules/financials/';
import { FinancialTemplatesList } from 'reports/modules/financials/components/financial_template';

interface IOwnProps {
    preview?: React.ReactNode;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps & IWithRouteProps;

class FinancialTemplateLibrary extends React.PureComponent<IProps> {
    render() {
        const { preview, user, route, loadItems, refreshItem } = this.props;

        return (
            <>
                <UpsellBanner />
                <LibraryMain
                    loadItems={loadItems}
                    refreshItem={refreshItem}
                    resourceIdName="financial_template_id"
                    views={[
                        {
                            view: <FinancialTemplatesList />,
                            icon: IconNames.LIST,
                            id: 'financial-template-list',
                        },
                    ]}
                    id="financial-models-views"
                    quickFilters={[
                        {
                            name: 'bookmarked',
                            icon: IconNames.STAR,
                            text: 'Favorited',
                        },
                        {
                            name: 'team_id',
                            icon: IconNames.PEOPLE,
                            text: `Only Show My Team`,
                            value: user.team.team_id,
                        },
                    ]}
                    contextBarControls={
                        <CreateButton text="New Financial Model" onClick={() => this.createSimpleModel()} />
                    }
                    preview={preview}
                    selectedItemId={route.params.finTemplateId}
                />
            </>
        );
    }

    async createSimpleModel() {
        const { createFinancialModel, navigateTo } = this.props;
        const model = await createFinancialModel();
        navigateTo('app.financial-templates.financial-template.edit', {
            finTemplateId: model.financial_template_id,
        });
    }
}

const mapStateToProps = (state) => ({
    refreshItem: (item) =>
        finTemp.selectors.byObject(state, item, {
            creator: true,
            team: true,
        }) || item,
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    createFinancialModel: actions.createModel,
    loadItems: finTemp.api.index,
    navigateTo: routerActions.navigateTo,
}));

export default connect(mapStateToProps, mapDispatchToProps)(withRoute(FinancialTemplateLibrary));
