import React, { useState } from 'react';
import { ForgotPasswordFormData, api as authAPI } from 'reports/modules/auth';
import { useDispatch } from 'react-redux';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordMessage from './ForgotPasswordMessage';
import { ModalBody, ModalFooter, ModalHeader, Text, Modal, StyleProvider } from '@aurorasolar/ds';

import { HelioScopeTheme } from 'reports/styles/theme';
import { FormikHelpers } from 'formik';
import { formikSetFieldErrors } from './common';

const ForgotPasswordDialog = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const forgotPassword = async ({ email }) => {
        return await dispatch(
            authAPI.forgotPassword({
                email,
            }),
        );
    };

    const onSubmit = async (formData: ForgotPasswordFormData, formikHelpers: FormikHelpers<ForgotPasswordFormData>) => {
        const { email } = formData;
        const { setSubmitting } = formikHelpers;
        try {
            setLoading(true);
            await forgotPassword({
                email,
            });

            setSuccess(true);
        } catch (ex) {
            formikSetFieldErrors(ex, formikHelpers);
            setLoading(false);
            throw ex;
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <StyleProvider theme={HelioScopeTheme} colorMode="light">
            <Modal bg="uiBg" open>
                <ModalHeader>
                    <Text as="h2" text="h2">
                        {success ? "We've sent you a new reset link" : 'Reset your password'}
                    </Text>
                </ModalHeader>
                <ModalBody>
                    {success ? <ForgotPasswordMessage /> : <ForgotPasswordForm onSubmit={onSubmit} loading={loading} />}
                </ModalBody>
                <ModalFooter />
            </Modal>
        </StyleProvider>
    );
};

export { ForgotPasswordDialog };
