import { FormGroup } from '@blueprintjs/core';
import * as React from 'react';
import { Link } from 'react-router5';

import { ReferrerType } from 'reports/analytics/ReferrerTypes';
import { Subscription } from 'reports/models/subscription';
import { Team } from 'reports/models/team';
import { Invoice } from 'reports/models/stripe/invoice';
import { DetailLine } from 'reports/modules/settings/common';
import { formatBillingCurrency } from 'reports/modules/settings/billing';

import { Discount } from './Discount';
import { PurchaseLink } from './PurchaseLink';

interface Props {
    manageBilling: boolean;
    showUpdateCTA: boolean;
    subscription: Subscription;
    team: Team;
    upcomingInvoice: Invoice | undefined;
    referrer: ReferrerType;
}

const CurrentPeriod = ({ manageBilling, referrer, showUpdateCTA, subscription, team, upcomingInvoice }: Props) => {
    const { current_period_end, plan_type } = subscription;

    if (current_period_end == null) {
        // Subscription is pending purchase. Period end hasn't been decided yet.
        return <></>;
    }

    const periodEndDateShort = current_period_end.format('ll');

    const showPurchasePlan =
        manageBilling &&
        team.can_purchase_subscription &&
        (subscription.is_self_serve || subscription.enable_self_serve);
    const showUpgrade =
        showUpdateCTA &&
        subscription.is_active &&
        plan_type === 'month' &&
        !subscription.scheduled_changes &&
        subscription.is_self_serve;

    const content =
        manageBilling && upcomingInvoice ? (
            <DetailLine>
                {periodEndDateShort} for {formatBillingCurrency(upcomingInvoice.total)}
            </DetailLine>
        ) : (
            <DetailLine>{periodEndDateShort}</DetailLine>
        );

    const upgradeLink = showUpdateCTA ? (
        <DetailLine>
            <Link
                routeName="app.settings.team.billing"
                routeParams={{
                    referrer,
                    dialog: 'change',
                    interval: 'year',
                    product: subscription.price?.product.id,
                }}
            >
                Switch to Annual <Discount>Save 15%</Discount>
            </Link>
        </DetailLine>
    ) : (
        <></>
    );

    return (
        <>
            <FormGroup label="Renewal Date">
                {content}
                {showPurchasePlan && <PurchaseLink referrer={referrer} />}
                {showUpgrade && upgradeLink}
            </FormGroup>
            {subscription.is_contract && (
                <DetailLine>
                    <a href="mailto:customer.success@helioscope.com?subject=MSA%20Copy%20Request" target="_blank">
                        Send us an email for a copy of your MSA
                    </a>
                </DetailLine>
            )}
        </>
    );
};

export { CurrentPeriod };
