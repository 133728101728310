import { Link } from 'react-router5';

import Colors from 'reports/styles/colors';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const UnstyledLink = styled(Link)`
    // Remove all default link styling
    color: inherit;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover,
    &:visited {
        color: inherit;
        text-decoration: none;
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
        color: ${Colors.TEXT_DISABLED};
    }
`;

export default UnstyledLink;
