import * as React from 'react';
import * as analytics from 'reports/analytics';
import * as events from 'reports/analytics/eventSchemas';

import { AnchorButton, IAnchorButtonProps } from '@blueprintjs/core';
import { useRoute } from 'react-router5';

type TargetValue = '_blank';

export type ILinkButtonTrackingOpts = {
    name: string;
    options: events.IEventProps;
};

export interface ILinkButtonProps extends IAnchorButtonProps {
    routeName: string;
    routeParams?: any;
    routeOptions?: {
        replace?: boolean;
        reload?: boolean;
        skipTransition?: boolean;
        force?: boolean;
    };
    style?: React.CSSProperties;
    target?: TargetValue;
    theme?: string;
    tracking?: ILinkButtonTrackingOpts;
}

const LinkButton = ({
    routeName,
    routeParams = {},
    routeOptions = {},
    loading,
    theme,
    tracking,
    onClick,
    ...buttonProps
}: ILinkButtonProps) => {
    const [navigating, setNavigating] = React.useState(false);
    const { router } = useRoute();
    const href = router.buildUrl(routeName, routeParams);

    const style =
        theme === 'black'
            ? {
                  ...buttonProps.style,
                  backgroundColor: 'black',
                  backgroundImage: 'none',
                  color: 'white',
                  textDecoration: 'none',
              }
            : buttonProps.style;

    const trackOnClick = (evt) => {
        onClick && onClick(evt);

        if (evt.metaKey || evt.ctrlKey || evt.shiftKey || buttonProps.target === '_blank') {
            // early exit to enable users to modifier-click and open new tabs
            return;
        }

        evt.preventDefault();

        if (tracking) {
            analytics.track(tracking.name, tracking.options);
        }

        setNavigating(true);
        router.navigate(routeName, routeParams, routeOptions, () => setNavigating(false));
    };

    return (
        <AnchorButton
            {...buttonProps}
            onClick={trackOnClick}
            href={href}
            loading={loading || navigating}
            style={style}
        />
    );
};

export default LinkButton;
