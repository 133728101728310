import * as React from 'react';

import * as DS from '@aurorasolar/ds';

import { useNavigationItems } from 'frontend/routing/use-navigation-items';

/*
* `setActionBar` - This function calls `DS.useContextualNavigation` hook to add components to the right side of the top
* navigation bar (ActionBar) in the page layout.
* @param actions - React.ReactNode: a set of components to add to the ActionBar.
* */
const setActionBar = (actions: React.ReactNode) => {
    const { contextualNavigation } = useNavigationItems();

    DS.useContextualNavigation({
        ...contextualNavigation,
        topRight: actions,
    });
};

export { setActionBar };
