/* tslint:disable:variable-name */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as authSelectors } from 'reports/modules/auth';
import { actions as routerActions } from 'redux-router5';

import { FavoriteStar } from 'reports/components/core/controls';
import { ColWidth, SortType } from 'reports/components/core/tables/DataTable';
import { LibraryConsumer } from 'reports/components/library/main/context';
import ResourceTable from 'reports/components/library/main/components/view_profiles/ResourceTable';
import { CommonColumns } from 'reports/components/library/helpers';

import * as finTemp from 'reports/models/financial_template';
import * as projfinTemp from 'reports/models/project_financial_template';
import { addPromiseToastsToggle } from 'reports/components/toasts';

const FinancialTemplatesList = () => {
    const user = useSelector((state) => authSelectors.getUser(state)!);
    const dispatch = useDispatch();
    const openPreview = ({ financial_template_id }) =>
        dispatch(
            routerActions.navigateTo('app.financial-templates.financial-template.preview', {
                finTemplateId: financial_template_id,
            }),
        );

    const isFinancialTemplateInUse = async ({ financial_template_id }) => {
        const projectFinancialTemplate = await dispatch(projfinTemp.api.index({ financial_template_id }));
        return projectFinancialTemplate.length > 0;
    };

    const setBookmarked = ({ financial_template_id }, bookmarked) =>
        dispatch(finTemp.api.patchTeamSettings({ financial_template_id, bookmarked }));

    const deleteFinancialModel = ({ financial_template_id }: finTemp.FinancialTemplate) =>
        dispatch(finTemp.api.delete({ financial_template_id }));

    const checkIsFinancialTemplateInUse = async (financial_template) => {
        const isInUse = await isFinancialTemplateInUse({
            financial_template_id: financial_template.financial_template_id,
        });
        if (isInUse) {
            return `This financial model is currently in use by one or more projects.
            You must remove the financial model from all projects before you are able to
            delete this financial model.`;
        }
        return null;
    };

    return (
        <LibraryConsumer>
            {({ updateSearch }) => (
                <ResourceTable<finTemp.FinancialTemplate>
                    columns={[
                        {
                            colWidth: ColWidth.SMALL,
                            headerText: 'Favorite',
                            renderCell: (template) => (
                                <FavoriteStar
                                    empty={!template.team_settings.bookmarked}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addPromiseToastsToggle(
                                            'favorite',
                                            setBookmarked(template, !template.team_settings.bookmarked),
                                            'model',
                                            template.description,
                                            template.team_settings.bookmarked,
                                        );
                                    }}
                                />
                            ),
                        },
                        {
                            sort: { name: 'description', type: SortType.ALPHA },
                            colWidth: ColWidth.LARGE,
                            headerText: 'Model Name',
                            renderCell: (template) => (
                                <div style={{ width: '100%', textAlign: 'left' }}>{template.description}</div>
                            ),
                        },
                        CommonColumns.lastModified(),
                        CommonColumns.created(),
                        CommonColumns.creator(),
                        CommonColumns.team(),
                        CommonColumns.publicCol(user),
                        CommonColumns.actionsCol(
                            user,
                            {
                                title: 'Delete Financial Model',
                                prompt: `Deleting this financial model will remove
                                    this model for all users on your team.
                                    Would you like to delete this financial model?`,
                                resName: 'Financial Model',
                                checkFn: checkIsFinancialTemplateInUse,
                            },
                            deleteFinancialModel,
                            updateSearch,
                        ),
                    ]}
                    onRowClick={(template) => openPreview(template)}
                />
            )}
        </LibraryConsumer>
    );
};

export default FinancialTemplatesList;
