import { find, isEqual, mapValues, range } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';
import Logger from 'js-logger';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import { Button, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { BYTES_PER_MEGABYTE } from 'reports/utils/units';

import * as proj from 'reports/models/project';
import * as uc from 'reports/models/user_consumption';
import * as cpo from 'reports/models/consumption_parser_options';
import * as up from 'reports/models/usage_profile';
import * as s3file from 'reports/models/s3file';

import { Toaster } from 'reports/modules/Toaster';
import DropzoneContainer from 'reports/modules/files/components/DropzoneContainer';
import { actions as uploadActions, IUpload } from 'reports/modules/files/uploads';
import { FormGroupGrid, Header } from 'reports/modules/project/common';
import { actions, consumptionCreateMessages, IConsumptionInputForm } from 'reports/modules/consumption';

import { Section2 } from 'reports/components/core/containers';
import { CreateButton } from 'reports/components/core/controls';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';

import ConsumptionEditorDialog from './ConsumptionEditorDialog';
import ConsumptionUploadDialog, { ConsumptionFileType } from './ConsumptionUploadDialog';
import SingleMonthConsumptionForm from './SingleMonthConsumptionForm';

const UploadButton = styled(CreateButton)`
    margin-left: 0px;
    padding: 4px;
`;

const logger = Logger.get('consumption');

type IConsumptionMode = 'single' | 'multi' | 'file';

interface IOwnProps {
    project: proj.Project;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
    setProjectConsumption: (uc: uc.UserConsumption, saveNow?: boolean) => any;
    loadConsumptionData: () => Promise<uc.UserConsumption[]>;
    createNewManualConsumption: () => Promise<uc.UserConsumption>;
    updateConsumption: (cons: uc.UserConsumption) => Promise<uc.UserConsumption>;
    createConsumption: (form: uc.UserConsumptionForm) => Promise<uc.UserConsumption>;
    uploadFile: (file: IUpload) => Promise<s3file.S3File>;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    consumptionMode: IConsumptionMode;
    monthlyEnergy: number[];
    userInputKwh?: uc.IUserInput;
    usageProfile?: up.UsageProfile;
    isMultiDialogOpen: boolean;
    isFileDialogOpen: boolean;
    waiting: boolean;
    unsupportedS3FileId: number | null;
    uploadingFilename?: string;
    uploadingS3File?: Promise<s3file.S3File>;
    uploadingFileType?: ConsumptionFileType;
}

class ConsumptionEditor extends React.PureComponent<IProps, IState> {
    dropzoneRef = React.createRef<Dropzone>();
    loading: Promise<any> | undefined;

    state: IState = {
        consumptionMode: 'single',
        monthlyEnergy: this.getMonthlySeries(),
        waiting: false,
        usageProfile: this.props.projectUsageProfile || undefined,
        isMultiDialogOpen: false,
        isFileDialogOpen: false,
        unsupportedS3FileId: null,
    };

    getMonthlySeries() {
        const { userConsumption } = this.props;
        if (userConsumption) {
            const kwh = userConsumption.localMonthlyKWh();
            return range(1, 13).map((m) => kwh[m]);
        }
        return [];
    }

    loadConsumptionData() {
        this.loading = this.props.loadConsumptionData().finally(() => {
            this.loading = undefined;
        });
    }

    componentDidMount() {
        this.loadConsumptionData();
        this.setConsumptionMode();
    }

    // Single month consumption input.
    // We default to this when there is no consumption data available for the user.
    setSingleMonthEdit = async () => {
        const { userConsumption } = this.props;
        try {
            await this.loading;

            let generatedConsumption = this.getManualConsumption();
            if (generatedConsumption == null) {
                generatedConsumption = await this.props.createNewManualConsumption();
            }

            if (!userConsumption || userConsumption.user_consumption_id !== generatedConsumption.user_consumption_id) {
                this.props.setProjectConsumption(generatedConsumption, true);
            }

            this.setState({
                userInputKwh: generatedConsumption.userInputKWh(),
                usageProfile: generatedConsumption.usage_profile,
                consumptionMode: 'single',
            });
        } catch (e) {
            Toaster.show({
                message: 'Error creating new consumption file',
                intent: Intent.DANGER,
            });
            logger.warn(e);
        }
    };

    setMultiMonthEdit = () => {
        const { userConsumption } = this.props;
        if (userConsumption == null) {
            logger.error('Tried to enter advanced edit without consumption');
            return;
        }

        const userInputKwh = userConsumption.userInputKWh();
        const usageProfile = userConsumption.usage_profile;

        this.setState({
            userInputKwh,
            usageProfile,
            monthlyEnergy: this.getMonthlySeries(),
            consumptionMode: 'multi',
        });
    };

    userInputChanged() {
        if (this.props.userConsumption == null) {
            logger.error('Input Change though no user consumption exists');
            return false;
        }
        // setSingleMonthEdit should ensure that userConsumption as been created
        const userInputKwh = this.props.userConsumption.userInputKWh();
        return !isEqual(userInputKwh, this.state.userInputKwh);
    }

    getManualConsumption = () => find(this.props.allConsumptions, (cons) => cons.source_type === 'user_input');

    onDrop = async (acceptedFiles: File[], rejectedFiles: File[], event) => {
        const { uploadFile } = this.props;
        event.stopPropagation();

        if (rejectedFiles.length > 0) {
            Toaster.show({
                message: `Error uploading ${rejectedFiles[0].name}: invalid file type.`,
                intent: Intent.DANGER,
            });
            return;
        }

        const file = acceptedFiles[0];
        const lowercaseFilename = file.name.toLowerCase();
        let fileType;

        if (lowercaseFilename.endsWith('.csv')) {
            fileType = 'csv';
        } else if (lowercaseFilename.endsWith('.xml')) {
            fileType = 'xml';
        } else {
            Toaster.show({
                message: `Error uploading ${file.name}: we expected a filename ending in .csv or .xml.`,
                intent: Intent.DANGER,
            });
            return;
        }

        const uploadingS3File = uploadFile({
            file,
            meta: { tags: ['consumption'] },
        });
        this.setState({
            uploadingS3File,
            uploadingFilename: file.name,
            uploadingFileType: fileType,
            consumptionMode: 'file',
            isFileDialogOpen: true,
        });
    };

    createFileConsumption = async (parserOptions?: cpo.ConsumptionParserOptions) => {
        const { project, createConsumption, setProjectConsumption } = this.props;
        const { uploadingS3File, uploadingFilename } = this.state;
        const messages = consumptionCreateMessages(uploadingFilename);
        const toast = Toaster.show({ message: messages.initial, timeout: 0 });

        const s3file = await uploadingS3File!;

        const cpoArgs = parserOptions?.consumption_parser_options_id
            ? {
                  consumption_parser_options_id: parserOptions.consumption_parser_options_id,
              }
            : { consumption_parser_options: parserOptions };

        return createConsumption({
            name: s3file.name,
            file_id: s3file.file_id,
            project_id: project.project_id,
            ...cpoArgs,
        })
            .then((consumption) => {
                Toaster.show({ message: messages.onSuccess, intent: Intent.SUCCESS }, toast);

                setProjectConsumption(consumption, true);
                this.setState({ consumptionMode: 'file' });
            })
            .catch((exc) => {
                const failureResponse = exc.response.body;
                if (failureResponse['file_id'] != null) {
                    Toaster.dismiss(toast);

                    // Open UnsupportedFileDialog to ask survey question.
                    this.setState({ unsupportedS3FileId: s3file.file_id });
                } else if (failureResponse['csv'] != null) {
                    const { row, col, val, err } = failureResponse['csv'];
                    Toaster.show(
                        {
                            message:
                                `${messages.onCatch}` +
                                `${row != null ? `, row ${row + 1}` : ''}` +
                                `${col != null ? `, column ${col + 1}` : ''}` +
                                `${val != null ? `, value '${val}'` : ''}: ${err}`,
                            intent: Intent.DANGER,
                        },
                        toast,
                    );
                } else {
                    Toaster.show({ message: messages.onCatch, intent: Intent.DANGER }, toast);
                    logger.warn(failureResponse);
                }
            });
    };

    _onParserSave = async (parserOptions?: cpo.ConsumptionParserOptions) => {
        this.createFileConsumption(parserOptions);
        this.cancel();
    };

    setConsumptionMode() {
        const consumptionMode = this.getConsumptionMode();
        if (consumptionMode === 'single') {
            this.setSingleMonthEdit();
        } else if (consumptionMode === 'multi') {
            this.setMultiMonthEdit();
        } else if (consumptionMode === 'file') {
            this.setState({ consumptionMode: 'file' });
        }
    }

    getConsumptionMode(): IConsumptionMode {
        const { userConsumption } = this.props;
        if (userConsumption == null) {
            logger.warn('Checking single month input but no user consumption available');
            return 'single';
        }
        if (userConsumption.file_id) {
            return 'file';
        }

        const userInputKwh = this.state.userInputKwh || userConsumption.userInputKWh();

        if (Object.keys(userInputKwh).length > 1) {
            return 'multi';
        }
        return 'single';
    }

    saveOrCancel = () => {
        const { usageProfile } = this.state;
        const { projectUsageProfile } = this.props;

        const usageProfileChanged =
            usageProfile &&
            (!projectUsageProfile || usageProfile.usage_profile_id !== projectUsageProfile.usage_profile_id);

        if (this.userInputChanged() || usageProfileChanged) {
            this.save();
        }
    };

    save = async () => {
        this.setState({ waiting: true });
        try {
            const { userConsumption } = this.props;
            const { usageProfile, userInputKwh } = this.state;

            const userInputWh = mapValues(userInputKwh, (month) => ({
                ...month,
                energy: month.energy! * 1000,
            }));
            if (
                !isEqual(userInputWh, userConsumption!.user_input) ||
                (usageProfile &&
                    usageProfile.usage_profile_id !==
                        (userConsumption!.usage_profile && userConsumption!.usage_profile_id))
            ) {
                // Convert userInput to Wh
                const updated = new uc.UserConsumption({
                    ...userConsumption!,
                    user_input: userInputWh,
                    usage_profile: usageProfile,
                    usage_profile_id: usageProfile ? usageProfile.usage_profile_id : null,
                });
                await this.props.updateConsumption(updated);
            }
            this.cancel();
        } catch (error) {
            Toaster.show({
                message: error.message,
                intent: Intent.DANGER,
            });
            logger.warn(error);
        } finally {
            this.setState({ waiting: false });
        }
    };

    _onInputSave = (consumptionInput: IConsumptionInputForm) => this.setState(consumptionInput, this.saveOrCancel);

    cancel = () =>
        this.setState({
            userInputKwh: this.props.userConsumption?.userInputKWh(),
            consumptionMode: this.getConsumptionMode(),
            isMultiDialogOpen: false,
            isFileDialogOpen: false,
        });

    render() {
        const { project, userConsumption, allConsumptions } = this.props;
        const {
            consumptionMode,
            monthlyEnergy,
            userInputKwh,
            usageProfile,
            isMultiDialogOpen,
            isFileDialogOpen,
            uploadingFileType,
            uploadingFilename,
            uploadingS3File,
            waiting,
        } = this.state;
        return (
            /**
             * We're setting height to be auto b/c DropzoneContainer has an inherited
             * height of 100% which ends up filling the Card and pushing the incentives
             * section to the bottom and leaving a lot of whitespace in between.
             * Auto just has the container height set to the size of its children.
             **/
            <DropzoneContainer
                ref={this.dropzoneRef}
                onDrop={this.onDrop}
                accept="application/*,text/*"
                maxSize={10 * BYTES_PER_MEGABYTE}
                disableClick={true}
                style={{ height: 'auto' }}
            >
                <Section2
                    title="Consumption"
                    contextEl={
                        <BasicSelect<uc.UserConsumption>
                            dataSource={{
                                items: allConsumptions || [],
                                filterBy: 'name',
                            }}
                            itemRenderer={(uc: uc.UserConsumption) => ({
                                key: uc.user_consumption_id,
                                text: uc.name,
                            })}
                            actionButton={
                                <UploadButton
                                    icon={IconNames.UPLOAD}
                                    text="Upload New"
                                    onClick={() => this.dropzoneRef.current!.open()}
                                    fill={true}
                                />
                            }
                            value={userConsumption || null}
                            onChange={async (uc) => {
                                this.setState({ waiting: true });
                                await this.props.setProjectConsumption(uc, true);
                                this.setConsumptionMode();
                                this.setState({ waiting: false });
                            }}
                            noneSelected="No consumption selected"
                        />
                    }
                >
                    {waiting && <Spinner size={SpinnerSize.SMALL} />}
                    {!waiting && consumptionMode === 'single' && userInputKwh && (
                        <FormGroupGrid>
                            <Header>Consumption</Header>
                            <SingleMonthConsumptionForm
                                onSave={(userInputKwh) => this._onInputSave({ userInputKwh })}
                                userInputKwh={userInputKwh}
                                usageProfile={usageProfile}
                            />
                            <a
                                onClick={() => {
                                    this.setMultiMonthEdit();
                                    this.setState({
                                        isMultiDialogOpen: true,
                                    });
                                }}
                            >
                                Advanced Configuration
                            </a>
                        </FormGroupGrid>
                    )}
                    {!waiting && consumptionMode === 'multi' && userInputKwh && (
                        <>
                            <ConsumptionEditorDialog
                                userInputKwh={userInputKwh}
                                usageProfile={usageProfile}
                                location={project.location}
                                monthlyEnergy={monthlyEnergy}
                                isOpen={isMultiDialogOpen}
                                onSave={this._onInputSave}
                                onCancel={this.cancel}
                                onClose={this.cancel}
                            />
                            <Button
                                text="Edit Configuration"
                                icon={IconNames.WRENCH}
                                onClick={() => {
                                    this.setMultiMonthEdit();
                                    this.setState({
                                        isMultiDialogOpen: true,
                                    });
                                }}
                            />
                        </>
                    )}
                    {!waiting && uploadingS3File && (
                        <ConsumptionUploadDialog
                            uploadingS3File={uploadingS3File}
                            project={project}
                            filename={uploadingFilename}
                            fileType={uploadingFileType}
                            isOpen={isFileDialogOpen}
                            onSave={this._onParserSave}
                            onClose={this.cancel}
                        />
                    )}
                </Section2>
            </DropzoneContainer>
        );
    }
}

const mapStateToProps = (state, { project }: IOwnProps) => ({
    allConsumptions: proj.selectors.userConsumptions(state, project),
    userConsumption: proj.selectors.userConsumption(state, project),
    projectUsageProfile: proj.selectors.usageProfile(state, project, {
        usage_site: true,
        building_type: true,
    }),
});

const mapDispatchToProps = bindActions((props: IOwnProps) => ({
    setProjectConsumption: (userConsumption, saveNow) =>
        proj.saver.get(props.project).patch({ user_consumption_id: userConsumption.user_consumption_id }, saveNow),
    loadConsumptionData: () => uc.api.index({ project_id: props.project.project_id }),
    createNewManualConsumption: () => actions.createNewManualConsumption(props.project),
    updateConsumption: actions.updateConsumption,
    createConsumption: uc.api.create,
    uploadFile: uploadActions.uploadFile,
}));

export default connect(mapStateToProps, mapDispatchToProps)(ConsumptionEditor);
