import { BBox } from 'geojson';
import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import useSupercluster from 'use-supercluster';

import * as ws from 'reports/models/weather_source';

import ClusterMarker from './ClusterMarker';
import { SatelliteSourceType, toGLatLng } from './WeatherSourceMap';
import WeatherSourceMarker from './WeatherSourceMarker';

interface IProps {
    weatherSources: ws.WeatherSource[];
    selectedSourceId?: number;
    onSelectSource: (src: number, position) => void;
    onMouseOverSource: (src, position) => void;
    onMouseOutSource: () => void;
    closeInfoWindow: (src) => void;
    bounds?: BBox;
    zoom: number;
    satelliteRegion?: SatelliteSourceType;
}

const ClusterGoogleMapReact: React.FC<GoogleMapReact.Props & IProps> = (props) => {
    const {
        weatherSources,
        selectedSourceId,
        onSelectSource,
        onMouseOverSource,
        onMouseOutSource,
        closeInfoWindow,
        bounds,
        zoom,
        satelliteRegion,
        ...rest
    } = props;
    const points = weatherSources.map((source) => ({
        type: 'Feature',
        properties: {
            source,
            cluster: false,
            sourceId: source.weather_source_id,
            name: source.name,
            sourceType: source.source_type,
        },
        geometry: {
            type: 'Point',
            coordinates: [toGLatLng(source.location)['lng'], toGLatLng(source.location)['lat']],
        },
    }));

    const { clusters } = useSupercluster({
        points,
        zoom,
        bounds,
        options: { radius: 100, maxZoom: 20 },
    });

    return (
        <GoogleMapReact {...rest}>
            {!satelliteRegion &&
                clusters.map((pin) => {
                    if (pin.properties.cluster) {
                        return (
                            <ClusterMarker
                                lat={pin.geometry.coordinates[1]}
                                lng={pin.geometry.coordinates[0]}
                                cluster={pin}
                            />
                        );
                    }

                    const sourceId = pin.properties.sourceId;
                    const isSourceSelected = sourceId === selectedSourceId;
                    const srcPosition = {
                        lat: pin.geometry.coordinates[1],
                        lng: pin.geometry.coordinates[0],
                    };
                    return (
                        <WeatherSourceMarker
                            key={`${pin.id}-${pin.properties.sourceId}`}
                            srcId={pin.properties.sourceId}
                            lat={pin.geometry.coordinates[1]}
                            lng={pin.geometry.coordinates[0]}
                            isSourceSelected={isSourceSelected}
                            onClick={() => onSelectSource(pin.properties.source, srcPosition)}
                            onMouseOver={() => onMouseOverSource(pin.properties.source, srcPosition)}
                            onMouseOut={onMouseOutSource}
                            closeInfoWindow={closeInfoWindow}
                        />
                    );
                })}
        </GoogleMapReact>
    );
};

export default ClusterGoogleMapReact;
