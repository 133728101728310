import { Button, Classes, Checkbox } from '@blueprintjs/core';
import { has, mapValues } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { bindActions } from 'reports/utils/redux';
import { IAppState } from 'reports/types';
import { RouterStateLock } from 'reports/utils/router';

import { Card, Flex } from 'reports/components/core/containers';
import { SaveCancelButtons } from 'reports/components/core/controls';
import { Form, FormInput, NestedFields } from 'reports/components/forms';

import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import Section from 'reports/components/helpers/Section';

import * as inc from 'reports/models/incentive';

import * as auth from 'reports/modules/auth';
import INC_PARAMETERS from 'reports/modules/incentive/parameters';
import { addPromiseToasts, Toaster } from 'reports/modules/Toaster';

import IncentiveFields from './IncentiveFields';
import IncentiveTypeSelect from './IncentiveTypeSelect';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const StandaloneFieldsCard = styled(Card)`
    .${Classes.INLINE} {
        .${Classes.LABEL} {
            width: auto;
        }
    }
`;

interface IOwnProps {
    incentive: inc.Incentive;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

type IProps = IOwnProps & IStateProps & IDispatchProps;

const DEFAULT_CONFIG_BY_INC_TYPE = mapValues(INC_PARAMETERS, (params) => {
    return mapValues(params, (paramConfig) => paramConfig.default);
});

const EditableHeader: React.SFC<{
    name: string;
    updateName: (val: string) => any;
    onDelete: () => any;
    disabled: boolean;
}> = ({ name, updateName, onDelete, disabled }) => (
    <div className="content-header">
        <EditableTitleSubHeader
            value={name}
            updateFn={updateName}
            right={
                <div style={{ display: 'flex' }}>
                    <div style={{ margin: '0px 2px' }}>
                        <Button icon="trash" onClick={onDelete} disabled={disabled} />
                    </div>
                </div>
            }
            disabled={disabled}
        />
    </div>
);

class IncentiveEditor extends React.PureComponent<IProps> {
    render() {
        const { incentive, user } = this.props;
        const canModify = auth.permissions.canModifySharedTeamResource(user, incentive);
        const canPublish = auth.permissions.canMakeResourcePublic(user, incentive);

        return (
            <>
                <EditableHeader
                    name={incentive.name}
                    updateName={(name) => this.props.saveIncentive({ ...incentive, name })}
                    onDelete={this.deleteIncentive}
                    disabled={!canModify}
                />
                <div className="sub-content-inner" style={{ padding: '8px' }}>
                    <Form baseValue={incentive} onSubmit={this.saveForm}>
                        {({ dirty, submitForm, formData, updateValue, clearForm }) => (
                            <>
                                <RouterStateLock
                                    title="Save incentive changes?"
                                    prompt={`You have unsaved changes on ${
                                        this.props.incentive.name || 'this incentive'
                                    }`}
                                    cancellable={true}
                                    showExitPrompt={dirty}
                                    onSave={submitForm}
                                    onDontSave={clearForm}
                                />

                                <Flex.ContainerV>
                                    <StandaloneFieldsCard>
                                        <FormInput
                                            label="Public"
                                            path="public"
                                            inputComponent={Checkbox}
                                            onUpdate={() => updateValue('public', !formData.public)}
                                            inputProps={{
                                                checked: formData.public,
                                            }}
                                            disabled={!(canModify && canPublish)}
                                        />
                                        <FormInput
                                            label="Incentive Type"
                                            path="incentive_type"
                                            inputComponent={IncentiveTypeSelect}
                                            onUpdate={(newIncType) => {
                                                // ensure there is always a compatible configuration
                                                if (incentive.incentive_type !== newIncType) {
                                                    updateValue(
                                                        'configuration',
                                                        DEFAULT_CONFIG_BY_INC_TYPE[newIncType],
                                                    );
                                                }
                                            }}
                                            inline={true}
                                            disabled={!canModify}
                                        />
                                    </StandaloneFieldsCard>
                                    <Card>
                                        <Section.Header>
                                            <Section.Text>Configuration</Section.Text>
                                        </Section.Header>
                                        <Section.Body>
                                            <NestedFields path="configuration">
                                                <IncentiveFields type={formData.incentive_type} disabled={!canModify} />
                                            </NestedFields>
                                            <SaveCancelButtons
                                                onSave={submitForm}
                                                hasChanges={dirty}
                                                onCancel={clearForm}
                                            />
                                        </Section.Body>
                                    </Card>
                                </Flex.ContainerV>
                            </>
                        )}
                    </Form>
                </div>
            </>
        );
    }

    saveForm = async (form: inc.IIncentiveForm) => {
        return await addPromiseToasts(this.props.saveIncentive(form), {
            initial: 'Saving incentive...',
            onSuccess: 'Successfully saved incentive.',
            onCatch: 'Error saving incentive.',
        });
    };

    deleteIncentive = async () => {
        try {
            await this.props.deleteIncentive();
            this.goToIncentiveList();
        } catch (e) {
            if (
                has(e, 'response.body.incentive_id') &&
                e.response.body.incentive_id.includes('Cannot delete: currently in use by projects')
            ) {
                Toaster.show({
                    icon: 'warning-sign',
                    message: <div>Cannot delete incentive: in use by one or more projects</div>,
                    timeout: 2500,
                });
            } else {
                Toaster.show({
                    icon: 'warning-sign',
                    message: <div>Error deleting incentive</div>,
                    timeout: 2500,
                });
            }
        }
    };

    goToIncentiveList() {
        this.props.navigateTo('app.incentives', {});
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions((props: IOwnProps) => ({
    saveIncentive: (data) =>
        inc.api.save({
            incentive_id: props.incentive.incentive_id,
            ...data,
        }),
    deleteIncentive: () => inc.api.delete({ incentive_id: props.incentive.incentive_id }),
    updateIncentive: (patch) => inc.saver.get(props.incentive).patch(patch),
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
}));

export default connect(mapStateToProps, mapDispatchToProps)(IncentiveEditor);
