import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';
import { fromNow } from 'reports/utils/formatters';
import { RouterStateLock } from 'reports/utils/router';
import * as analytics from 'reports/analytics';

import * as pro from 'reports/models/profile';
import * as usr from 'reports/models/user';

import { Form, handleRequestException } from 'reports/components/forms';
import { FormTextAreaInput, FormTextInput } from 'reports/components/forms/inputs/experimental';
import { SaveCancelButtons } from 'reports/components/core/controls';
import { StyledFormGroup } from 'reports/components/core/forms';
import { Card } from 'reports/components/core/containers';

import * as auth from 'reports/modules/auth';
import { makeCellTempParams } from 'reports/modules/condition_sets/components/common';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { LABEL_WIDTH, Section2 } from 'reports/modules/profile/components/common';
import ElectricalProfileEditor from 'reports/modules/profile/components/ElectricalProfileEditor';
import MechProfileEditor from 'reports/modules/profile/components/MechanicalProfileEditor';
import ProjectProfileEditor from 'reports/modules/profile/components/ProjectProfileEditor';
import ConditionsProfileEditor from 'reports/modules/profile/components/ConditionsProfileEditor';
import FinancialProfileEditor from 'reports/modules/profile/components/FinancialProfileEditor';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const EditorContainer = styled.div`
    padding: 10px 10px 100px;
`;

interface IOwnProps {
    profile: pro.Profile;
    user: usr.User;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IDispatchProps;

class ProfileEditor extends React.PureComponent<IProps> {
    render() {
        const { user, profile, navigateToPreview } = this.props;
        const lastModified = profile.last_modified || profile.created;

        return (
            <Form
                baseValue={this.preProcess(profile)}
                onSubmit={this.saveForm}
                exceptionHandler={this.customExceptionHandler}
            >
                {({ dirty, submitForm, submitting, clearForm }) => (
                    <>
                        <RouterStateLock
                            title="Save Profile?"
                            prompt="You have unsaved changes on this profile."
                            cancellable={true}
                            showExitPrompt={dirty}
                            onSave={submitForm}
                            onDontSave={clearForm}
                        />
                        <EditorContainer>
                            <Card>
                                <Section2 title="Overview">
                                    <FormTextInput inline bold path="name" label="Name" labelWidth={LABEL_WIDTH} />
                                    <StyledFormGroup inline label="Owner" labelWidth={LABEL_WIDTH}>
                                        <div style={{ marginTop: 5 }}>
                                            {`${
                                                profile.creator ? profile.creator.full_name : 'None'
                                            } (last modified ${fromNow(lastModified)})`}
                                        </div>
                                    </StyledFormGroup>
                                    <FormTextAreaInput
                                        inline
                                        bold
                                        path="description"
                                        label="Description"
                                        labelWidth={LABEL_WIDTH}
                                    />
                                </Section2>
                            </Card>
                            {profile instanceof pro.ConditionsProfile && <ConditionsProfileEditor user={user} />}
                            {profile instanceof pro.ElectricalProfile && <ElectricalProfileEditor />}
                            {profile instanceof pro.MechanicalProfile && <MechProfileEditor user={user} />}
                            {profile instanceof pro.ProjectProfile && <ProjectProfileEditor profile={profile} />}
                            {profile instanceof pro.FinancialProfile && <FinancialProfileEditor />}
                            <SaveCancelButtons
                                onSave={async () => {
                                    await submitForm();
                                    navigateToPreview(profile);
                                }}
                                onCancel={() => {
                                    analytics.track('profile.cancel_edit', {
                                        profile_id: profile.profile_id,
                                        profile_type: profile.type,
                                    });
                                    navigateToPreview(profile);
                                }}
                                hasChanges={dirty}
                                disabled={submitting}
                            />
                        </EditorContainer>
                    </>
                )}
            </Form>
        );
    }

    preProcess(profile) {
        if (profile.type !== 'scenario') return profile;
        const preProcessedDiffuseParams = makeCellTempParams({
            cell_temp_parameters: profile.data.all_temperature_parameters.diffuse,
            cell_temp_model: 'diffuse',
        });
        const preProcessedSandiaParams = makeCellTempParams({
            cell_temp_parameters: profile.data.all_temperature_parameters.sandia,
            cell_temp_model: 'sandia',
        });
        return {
            ...profile,
            data: {
                ...profile.data,
                all_temperature_parameters: {
                    diffuse: preProcessedDiffuseParams,
                    sandia: preProcessedSandiaParams,
                },
            },
        };
    }

    postProcess(profile: pro.IProfileSaveForm) {
        const postProcessedDiffuseParams = Object.values(profile.data.all_temperature_parameters.diffuse);
        const postProcessedSandiaParams = Object.values(profile.data.all_temperature_parameters.sandia);
        const allTempParams = {
            diffuse: postProcessedDiffuseParams,
            sandia: postProcessedSandiaParams,
        };
        return {
            ...profile,
            data: {
                ...profile.data,
                all_temperature_parameters: allTempParams,
            },
        };
    }

    customExceptionHandler = (exc) => {
        const { fieldErrors, formErrors } = handleRequestException(exc);
        surfaceCellTempErrors(fieldErrors?.data?.all_temperature_parameters?.diffuse);
        surfaceCellTempErrors(fieldErrors?.data?.all_temperature_parameters?.sandia);
        return { fieldErrors, formErrors };
    };

    saveForm = async (form: pro.IProfileSaveForm) => {
        const postProcessedForm = form.type === 'scenario' ? this.postProcess(form) : form;
        return await addPromiseToasts(this.props.saveProfile(postProcessedForm), {
            initial: 'Saving profile...',
            onSuccess: 'Successfully saved profile.',
            onCatch: 'Error saving profile.',
        });
    };
}

// Surfaces errors out of cell temp rows to the top-level
// Exported for shared use with ConditionSetEdit.tsx
export const surfaceCellTempErrors = (root) => {
    if (root) {
        for (const row of root) {
            for (const [key, val] of Object.entries(row)) {
                root[key] = val;
            }
        }
    }
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state),
});

const mapDispatchToProps = bindActions(() => ({
    saveProfile: pro.api.save,
    navigateToPreview: (profile) =>
        routerActions.navigateTo('app.profiles.profile.preview', {
            profileId: profile.profile_id,
        }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditor);
