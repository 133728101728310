import * as React from 'react';

import { IconName, TabId } from '@blueprintjs/core';

import { LibraryList as List } from 'reports/components/core/layout';
import { ContextBarControls } from 'reports/components/ContextBar';

import { IAPIQueryOpts } from 'reports/models/types';

import { SearchControls } from './search';
import SearchableLibrary from './SearchableLibrary';
import { Filter } from '../types';
import { ViewTabs } from './ViewTabs';

interface ILibraryListProps<T> {
    id: TabId;
    loadItems: (opts: IAPIQueryOpts) => Promise<T[]>;
    refreshItem: (item: T) => T;
    resourceIdName: string;
    views: { view: JSX.Element; icon: IconName; id: TabId }[];
    contextBarControls?: React.ReactNode;
    disableSearch?: boolean;
    preview?: React.ReactNode;
    quickFilters?: Filter<T>[];
    extraFilterControls?: JSX.Element;
    searchControls?: JSX.Element;
    searching?: boolean;
    selectedItemId?: number | string;
}

export class LibraryMain<T> extends React.Component<ILibraryListProps<T>> {
    render() {
        const {
            id,
            loadItems,
            refreshItem,
            resourceIdName,
            views,
            contextBarControls,
            disableSearch = false,
            preview,
            quickFilters,
            extraFilterControls,
            searchControls,
            searching,
            selectedItemId,
        } = this.props;
        return (
            <SearchableLibrary
                resourceIdName={resourceIdName}
                loadItems={loadItems}
                refreshItem={refreshItem}
                selectedItemId={selectedItemId}
            >
                <List.Container>
                    {!disableSearch &&
                        (searchControls ? (
                            searchControls
                        ) : (
                            <SearchControls filters={quickFilters} extraFilterControls={extraFilterControls} />
                        ))}
                    <List.Main>
                        <List.ContentPane>
                            <ViewTabs id={id} views={views} searching={searching} />
                        </List.ContentPane>
                    </List.Main>
                    {preview}
                </List.Container>
                <ContextBarControls>{contextBarControls}</ContextBarControls>
            </SearchableLibrary>
        );
    }
}

export default LibraryMain;
