import { Classes, Colors, Dialog, Intent, Spinner } from '@blueprintjs/core';
import * as React from 'react';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import { Flex } from 'reports/components/core/containers';
import { LinkButton } from 'reports/components/core/controls';
import { AddProjectsCard, ICardContent } from './AddProjectsCard';

import * as styles from 'reports/styles/styled-components';
import { IProduct } from 'reports/config';
import { UPSELL_DIALOG_CONTACT_SALES_TEMPLATE } from 'reports/utils/UpsellDialogConstants';
// import * as analytics from 'reports/analytics';
const styled = styles.styled;

export enum INTERVAL {
    month = 'month',
    year = 'year',
}

export type IntervalPrices = {
    [intervalName in INTERVAL]?: number;
};

export interface IAddProjectsDialogProps {
    isAdmin: boolean;
    isAnnualSubscription: boolean;
    isLoading: boolean;
    isOpen: boolean;
    licenseCount: number;
    onClose: () => void;
    product?: IProduct;
    intervalPrices: IntervalPrices;
    usePortal?: boolean; // Used only for unit tests, rendering with portal breaks userEvent
}

const StyledDialog = styled(Dialog)`
    background-color: ${Colors.WHITE};
    width: 745px;
    gap: 24px;
    padding: 32px 16px 16px 16px;
    font-family: Helvetica;

    a[role='button'],
    button {
        // Override Button's margin-left 8px
        margin-left: 0;
    }
`;

const HeaderItem = styled.div`
    flex: 1;
`;

const Title = styled.div`
    font-size: 28px;
    font-weight: 700;
    color: #607d8b;
`;

const Subtitle = styled.div`
    margin-bottom: 8px;
    font-size: 18px;
`;

const CardContainer = styled(Flex.Container)`
    width: 100%;
    margin: 0px;
    gap: 24px;
`;

const LearnMoreContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DialogHeaderContainer = styled.div`
    box-shadow: 0 0 0 0;
    padding: 0px;
`;

const DialogHeader = ({ title, subtitle }) => (
    <DialogHeaderContainer className={Classes.DIALOG_HEADER}>
        <Flex.ContainerV>
            <HeaderItem>
                <Title>{title}</Title>
            </HeaderItem>
            <HeaderItem>
                <Subtitle>{subtitle}</Subtitle>
            </HeaderItem>
        </Flex.ContainerV>
    </DialogHeaderContainer>
);

const CARD_CONTENT: { [contentName: string]: ICardContent } = {
    updateToAnnual: {
        title: 'Update to annual billing',
        subTitleFormatter: (annualProjectLimit) => `Unlock ${annualProjectLimit} projects for the next year (save 15%)`,
        buttonText: 'Update Plan',
        planTypeText: 'annual',
        buttonRoute: 'app.settings.team.billing',
        buttonRouteParams: { dialog: 'change', interval: 'year', referrer: ReferrerTypes.add_projects_dialog },
        ctaTracking: {
            name: 'paywall.consumption_enforcement.cta',
            options: {
                cta_action: 'upgrade_to_annual',
                modal_name: 'add_projects',
            },
        },
    },
    addProjectsMonthly: {
        title: 'Add more projects',
        subTitleFormatter: (projectsPerLicense) =>
            `Unlock an additional ${projectsPerLicense} projects / month by adding a license`,
        buttonText: 'Add a License',
        planTypeText: 'monthly',
        buttonRoute: 'app.settings.team.billing',
        buttonRouteParams: { dialog: 'licenses', referrer: ReferrerTypes.add_projects_dialog },
        ctaTracking: {
            name: 'paywall.consumption_enforcement.cta',
            options: {
                cta_action: 'add_monthly_license',
                modal_name: 'add_projects',
            },
        },
    },
    upgradeToCustom: {
        title: 'Upgrade to Custom Plan',
        subTitle: 'Get the right number of projects for your business ',
        buttonText: 'Talk to Sales',
        planTypeText: 'annual',
        externalLink: UPSELL_DIALOG_CONTACT_SALES_TEMPLATE,
        ctaTracking: {
            name: 'paywall.consumption_enforcement.cta',
            options: {
                cta_action: 'upgrade_to_custom',
                modal_name: 'add_projects',
            },
        },
    },
    addProjectsAnnual: {
        title: 'Add more projects',
        subTitleFormatter: (projectsPerLicense) =>
            `Unlock an additional ${projectsPerLicense} projects / year by adding a license`,
        buttonText: 'Add a License',
        planTypeText: 'annual',
        buttonRoute: 'app.settings.team.billing',
        buttonRouteParams: { dialog: 'licenses', referrer: ReferrerTypes.add_projects_dialog },
        ctaTracking: {
            name: 'paywall.consumption_enforcement.cta',
            options: {
                cta_action: 'add_annual_license',
                modal_name: 'add_projects',
            },
        },
    },
};

export const AddProjectsDialog: React.FC<IAddProjectsDialogProps> = ({
    intervalPrices,
    isAdmin,
    isAnnualSubscription,
    isLoading,
    isOpen,
    licenseCount,
    product,
    onClose,
    usePortal = true, // Default to true so entire screen is covered
}) => {
    let annualProjectLimit = 0;
    let projectsPerLicensePerMonth = 0;
    let projectsPerLicensePerYear = 0;

    if (product) {
        const { projects_per_license_per_month, annual_multiplier } = product.limits;
        annualProjectLimit = licenseCount * projects_per_license_per_month * annual_multiplier;
        projectsPerLicensePerMonth = projects_per_license_per_month;
        projectsPerLicensePerYear = projects_per_license_per_month * annual_multiplier;
    }

    const showButton = !!isAdmin;

    return (
        <StyledDialog
            isOpen={isOpen}
            onClose={onClose}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            usePortal={usePortal}
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <DialogHeader
                        title="Get more projects for your team"
                        subtitle="Add more licenses or update your plan to continue creating new projects before your next billing cycle."
                    />

                    <CardContainer className={Classes.DIALOG_BODY}>
                        {isAnnualSubscription ? (
                            <>
                                <AddProjectsCard showButton={showButton} {...CARD_CONTENT.upgradeToCustom} />
                                <AddProjectsCard
                                    showButton={showButton}
                                    {...CARD_CONTENT.addProjectsAnnual}
                                    subTitleProjectCount={projectsPerLicensePerYear}
                                    price={intervalPrices[INTERVAL.year]}
                                />
                            </>
                        ) : (
                            <>
                                <AddProjectsCard
                                    showButton={showButton}
                                    {...CARD_CONTENT.updateToAnnual}
                                    subTitleProjectCount={annualProjectLimit}
                                    price={intervalPrices[INTERVAL.year]}
                                />
                                <AddProjectsCard
                                    showButton={showButton}
                                    {...CARD_CONTENT.addProjectsMonthly}
                                    subTitleProjectCount={projectsPerLicensePerMonth}
                                    price={intervalPrices[INTERVAL.month]}
                                />
                            </>
                        )}
                    </CardContainer>
                    {!isAdmin && (
                        <LearnMoreContainer>
                            <LinkButton
                                style={{ color: Colors.WHITE }}
                                text="Learn More"
                                routeName="app.settings.team.billing"
                                large
                                intent={Intent.PRIMARY}
                                tracking={{
                                    name: 'paywall.consumption_enforcement.learnMore',
                                    options: {
                                        modal_name: 'add_projects',
                                    },
                                }}
                            />
                        </LearnMoreContainer>
                    )}
                </>
            )}
        </StyledDialog>
    );
};
