import React from 'react';

import styled from '@xstyled/styled-components';

import { Outlet } from 'react-router-dom';

import * as DS from '@aurorasolar/ds';

import { useNavigationItems } from 'frontend/routing/use-navigation-items';

const StyledPage = styled(DS.Page)<{ shortenActionBar: boolean }>`
    nav[data-component='navigation-bar'] {
        background-color: uiActionBar.defaultBgColor;
        grid-template-columns: auto auto auto;
        z-index: 1;
    }

    nav[data-component='navigation-bar'] button {
        color: uiButtonContextualNavigation.dropdownTextColor;
    }

    div[data-component='contextual-navigation'] {
        margin-top: -45px;
        z-index: 2;
    }

    // Since we set margin-top to -45px, we set max-height to none to allow for the contextual navigation to grow to the
    // bottom of the page.
    div[data-subcomponent='contextual-navigation-body'] {
        max-height: none;
    }
`;

const Page = React.memo(({ children }: React.PropsWithChildren<{}>) => {
    const { contextualNavigation, pageNavigation } = useNavigationItems();

    // Shorten the ActionBar if there are groups in the contextualNavigation.
    const shortenActionBar = !!contextualNavigation.groups.length;
    return (
        <StyledPage pageNavigation={pageNavigation} shortenActionBar={shortenActionBar} variant="tertiary">
            {children}
            <DS.ContextualNavigation contextualNavigation={contextualNavigation} />
            <Outlet />
        </StyledPage>
    );
});

export { Page };
