import { defaultTheme } from '@xstyled/styled-components';
import * as DS from '@aurorasolar/ds';

const colors = {
    red: '#DB3737',
    'red-100': '#FFEBEB',
    'red-200': '#FF7373', // blueprint $red5,
    'red-300': '#f36464',
    'red-400': '#F55656', // blueprint $red4,
    'red-500': '#E74B4B',
    'red-600': '#DB3737', // blueprint $red3,
    'red-700': '#d13838',
    'red-800': '#C23030', // blueprint $red2,
    'red-900': '#B52A2A',
    'red-1000': '#A82A2A', // blueprint $red1,
    orange: '#D9822B',
    'orange-100': '#FFF5E0',
    'orange-200': '#FFB366', // blueprint $orange5
    'orange-300': '#FFA04D',
    'orange-400': '#F29D49', // blueprint $orange4
    'orange-500': '#E08A3E',
    'orange-600': '#D9822B', // blueprint $orange3
    'orange-700': '#C97B2A',
    'orange-800': '#BF7326', // blueprint $orange2
    'orange-900': '#B06A21',
    'orange-1000': '#A66321', // blueprint $orange1
    yellow: '#D99E0B',
    'yellow-100': '#FFF7E0',
    'yellow-200': '#FFC940', // blueprint $gold5
    'yellow-3000': '#FFC940',
    'yellow-400': '#F2B824', // blueprint $gold4
    'yellow-500': '#E8A31A',
    'yellow-600': '#D99E0B', // blueprint $gold3
    'yellow-700': '#C88F0A',
    'yellow-800': '#BF8C0A', // blueprint $gold2
    'yellow-900': '#B17A08',
    'yellow-1000': '#A67908', // blueprint $gold1
    green: '#0F9960',
    'green-100': '#d9f3ff',
    'green-200': '#3DCC91', // blueprint $green5
    'green-300': '#2FBC7B',
    'green-400': '#15B371', // blueprint $green4
    'green-500': '#0fa867',
    'green-600': '#0F9960', // blueprint $green3
    'green-700': '#0e8e58',
    'green-800': '#0D8050', // blueprint $green2
    'green-900': '#0b7045',
    'green-1000': '#0A6640', // blueprint $green1
    cyan: '#2965CC',
    'cyan-100': '#d9f3ff',
    'cyan-200': '#669EFF', // blueprint $cobalt5
    'cyan-300': '#558bec',
    'cyan-400': '#4580E6', // blueprint $cobalt4
    'cyan-500': '#3D7EE4',
    'cyan-600': '#2965CC', // blueprint $cobalt3
    'cyan-700': '#2A5AC3',
    'cyan-800': '#2458B3', // blueprint $cobalt2
    'cyan-900': '#1F4F9D',
    'cyan-1000': '#1F4B99', // blueprint $cobalt1
    blue: '#137CBD',
    'blue-100': '#d9f3ff',
    'blue-200': '#48AFF0', // blueprint $blue5
    'blue-300': '#3599d8',
    'blue-400': '#2B95D6', // blueprint $blue4
    'blue-500': '#1E88E5',
    'blue-600': '#137CBD', // blueprint $blue3
    'blue-700': '#1070ac',
    'blue-800': '#106BA3', // blueprint $blue2
    'blue-900': '#0f5e91',
    'blue-1000': '#0E5A8A', // bluprint $blue1
    purple: '#8F398F',
    'purple-100': '#FFD4E6',
    'purple-200': '#C274C2', // blueprint $violet5
    'purple-300': '#a85ca8',
    'purple-400': '#A854A8', // blueprint $violet4
    'purple-500': '#934193',
    'purple-600': '#8F398F', // blueprint $violet3
    'purple-700': '#832F83',
    'purple-800': '#752F75', // blueprint $violet2
    'purple-900': '#6A286A',
    'purple-1000': '#5C255C', // blueprint $violet1
    maroon: '#DB2C6F',
    'maroon-100': '#FFD4E6',
    'maroon-200': '#FF66A1', // blueprint $rose5
    'maroon-300': '#f35793',
    'maroon-400': '#F5498B', // blueprint $rose4
    'maroon-500': '#E73D7B',
    'maroon-600': '#DB2C6F', // blueprint $rose3
    'maroon-700': '#cf2a6a',
    'maroon-800': '#C22762', // blueprint $rose2
    'maroon-900': '#B0245A',
    'maroon-1000': '#A82255', // blueprint $rose1
};

const tokens = {
    uiFocus: DS.xCSSToken('blue-100'),
    uiButtonPrimary: {
        ...DS.BorealisTheme.colors.uiButtonPrimary,
        defaultBgColor: DS.xCSSToken('blue-600'),
        hoverBgColor: DS.xCSSToken('blue-800'),
    },
    uiButtonSecondary: {
        ...DS.BorealisTheme.colors.uiButtonSecondary,
        defaultTextColor: DS.xCSSToken('blue-600'),
        hoverTextColor: DS.xCSSToken('blue-600'),
    },
};

// This theme is temporary, and used as a transition theme between Blueprint and Borealis styling
const theme = {
    ...defaultTheme,
    ...DS.BorealisTheme,
    name: 'HelioScopeLegacy',
    colors: {
        ...DS.BorealisTheme.colors,
        ...colors,
        ...tokens,
    },
    radii: {
        ...DS.BorealisTheme.radii,
        default: '4px',
    },
};

// @ts-ignore/no-unused-vars
// This allows us to have autocomplete for component props.
export const HelioScopeLegacyTheme: DS.ITheme = theme;
