// This file is intended to be a proof of concept. If you need to make changes
// in here, please ensure that you are working with the MBU epic owner.
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as DS from '@aurorasolar/ds';

const useNavigationItems = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPathName = location.pathname;
    const isActive = useCallback(
        (
            pathName:
                | '/projects'
                | '/libraries'
                | '/settings'
                | '/profiles'
                | '/modules'
                | '/overview'
                | '/designer'
                | '/conditions'
                | '/simulations'
                | '/financials'
                | '/sharing'
                | '/reports'
                | '/user-overview'
                | '/preferences'
                | '/team-overview'
                | '/team-management'
                | '/integrations'
                | '/themes'
                | '/billing'
                | '/subscriptions'
                | '/team-merge'
                | '/team-features'
                | '/user-features',
        ) => currentPathName?.includes(pathName),
        [currentPathName],
    );

    const contextualNavigationGroups = () => {
        if (isActive('/projects')) {
            return [
                {
                    items: [
                        {
                            action: () => navigate('/overview'),
                            active: isActive('/overview'),
                            activeIcon: DS.IconDashboardFill,
                            children: 'Overview',
                            icon: DS.IconDashboard,
                        },
                        {
                            action: () => navigate('/designer'),
                            active: isActive('/designer'),
                            activeIcon: DS.IconDesignMode,
                            children: 'Designer',
                            icon: DS.IconDesignMode,
                        },
                        {
                            action: () => navigate('/conditions'),
                            active: isActive('/conditions'),
                            activeIcon: DS.IconShade,
                            children: 'Conditions',
                            icon: DS.IconShade,
                        },
                        {
                            action: () => navigate('/simulations'),
                            active: isActive('/simulations'),
                            activeIcon: DS.IconPlugFill,
                            children: 'Simulations',
                            icon: DS.IconPlug,
                        },
                        {
                            action: () => navigate('/financials'),
                            active: isActive('/financials'),
                            activeIcon: DS.IconDollarCircleFill,
                            children: 'Financials',
                            icon: DS.IconDollarCircle,
                        },
                        {
                            action: () => navigate('/sharing'),
                            active: isActive('/sharing'),
                            activeIcon: DS.IconUserFill,
                            children: 'Sharing',
                            icon: DS.IconUser,
                        },
                        {
                            action: () => navigate('/reports'),
                            active: isActive('/reports'),
                            activeIcon: DS.IconPaperLinesFill,
                            children: 'Reports & proposals',
                            icon: DS.IconPaperLines,
                        },
                    ],
                },
            ];
        }

        if (isActive('/libraries')) {
            return [
                {
                    title: 'Libraries',
                    items: [
                        {
                            action: () => navigate('/profiles'),
                            active: isActive('/profiles'),
                            children: 'Profiles',
                        },
                        {
                            action: () => navigate('/modules'),
                            active: isActive('/modules'),
                            children: 'Modules',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Inverters & Optimizers',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Weather Sources',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Wires',
                        },
                    ],
                },
                {
                    title: 'Reports and proposals',
                    items: [
                        {
                            action: () => console.log('clicked'),
                            children: 'Report templates',
                        },
                    ],
                },
                {
                    title: 'Quoting',
                    items: [
                        {
                            action: () => console.log('clicked'),
                            children: 'Financial models',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Incentives',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Utility rates',
                        },
                    ],
                },
            ];
        }

        if (isActive('/settings')) {
            return [
                {
                    title: 'Account',
                    items: [
                        {
                            action: () => navigate('/user-overview'),
                            active: isActive('/user-overview'),
                            children: 'Account Overview',
                        },
                        {
                            action: () => navigate('/preferences'),
                            active: isActive('/preferences'),
                            children: 'Preferences',
                        },
                    ],
                },
                {
                    title: 'Team',
                    items: [
                        {
                            action: () => navigate('/team-overview'),
                            active: isActive('/team-overview'),
                            children: 'Team Overview',
                        },
                        {
                            action: () => navigate('/team-management'),
                            active: isActive('/team-management'),
                            children: 'Manage Team',
                        },
                        {
                            action: () => navigate('/integrations'),
                            active: isActive('/integrations'),
                            children: 'Integrations',
                        },

                        {
                            action: () => navigate('/themes'),
                            active: isActive('/themes'),
                            children: 'Themes',
                        },
                        {
                            action: () => navigate('/billing'),
                            active: isActive('/billing'),
                            children: 'Billing & Usage',
                        },
                    ],
                },
                {
                    title: 'HS Admin',
                    items: [
                        {
                            action: () => navigate('/settings/hs-admin/subscriptions'),
                            active: isActive('/subscriptions'),
                            children: 'Subscription',
                        },
                        {
                            action: () => navigate('/settings/hs-admin/team-merge'),
                            active: isActive('/team-merge'),
                            children: 'Team Merge',
                        },

                        {
                            action: () => navigate('/settings/hs-admin/team-features'),
                            active: isActive('/team-features'),
                            children: 'Team Features',
                        },
                        {
                            action: () => navigate('/settings/hs-admin/user-features'),
                            active: isActive('/user-features'),
                            children: 'User Features',
                        },
                    ],
                },
            ];
        }

        return [];
    };

    const pageNavigation = useMemo<DS.INavigationItemGroup[]>(
        () => [
            {
                items: [
                    {
                        action: () => navigate('/projects'),
                        active: isActive('/projects'),
                        activeIcon: DS.IconProjectFill,
                        children: 'Projects',
                        icon: DS.IconProject,
                    },
                    {
                        action: () => navigate('/libraries'),
                        active: isActive('/libraries'),
                        activeIcon: DS.IconDatabaseFill,
                        children: 'Libraries',
                        icon: DS.IconDatabase,
                    },
                    {
                        action: () => navigate('/settings'),
                        active: isActive('/settings'),
                        activeIcon: DS.IconGearFill,
                        children: 'Settings',
                        icon: DS.IconGear,
                    },
                ],
            },
        ],
        [currentPathName, isActive],
    );

    const contextualNavigation = useMemo(
        () => ({
            groups: contextualNavigationGroups(),
        }),
        [currentPathName],
    );

    return { contextualNavigation, pageNavigation };
};

export { useNavigationItems };
