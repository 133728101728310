import { Coords } from 'google-map-react';
import * as React from 'react';
import styled from 'styled-components';
import Supercluster from 'supercluster';

import { Flex } from 'reports/components/core/containers';
import Colors from 'reports/styles/colors';

const ClusterCircle = styled(Flex.Container)`
    background-color: ${Colors.HELIO_ORANGE_MEDIUM};
    border: medium solid ${Colors.HELIO_ORANGE_DARK};
    border-radius: 100%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
`;

const MIN_CLUSTER_SIZE = 25;
const MAX_ADDITIONAL_CLUSTER_SIZE = 25;
// cluster won't grow in size after reaching this number of points
const POINT_COUNT_SCALING_CAP = 200;

const ClusterMarker = React.memo(
    (
        props: Coords & {
            cluster:
                | Supercluster.PointFeature<Supercluster.AnyProps>
                | Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>;
        },
    ) => {
        const { cluster } = props;
        const { point_count: pointCount } = cluster.properties;
        const diameter =
            MIN_CLUSTER_SIZE +
            (Math.min(pointCount, POINT_COUNT_SCALING_CAP) / POINT_COUNT_SCALING_CAP) * MAX_ADDITIONAL_CLUSTER_SIZE;

        return (
            <ClusterCircle
                style={{
                    width: `${diameter}px`,
                    height: `${diameter}px`,
                }}
            >
                {pointCount}
            </ClusterCircle>
        );
    },
);

export default ClusterMarker;
