import * as React from 'react';
import { useDispatch } from 'react-redux';

import * as usr from 'reports/models/user';

import { Form, handleRequestException, IFormUpdateCallback } from 'reports/components/forms';
import FormTextInput from 'reports/components/forms/inputs/experimental/FormTextInput';
import { FormErrorCallout } from 'reports/components/helpers/errors';

interface Props {
    onUpdate: IFormUpdateCallback;
    user: usr.User;
}

const PasswordForm = ({ onUpdate, user }: Props) => {
    const dispatch = useDispatch();
    const changePassword: (form: usr.IPasswordForm) => Promise<usr.User> = (form: usr.IPasswordForm) =>
        dispatch(usr.api.save({ ...form, email: user.email }, { action: 'change_password' }));

    const onSubmit = async (formData: usr.IPasswordForm) => await changePassword(formData);

    return (
        <Form
            baseValue={{ email: user.email }}
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            exceptionHandler={handleRequestException}
        >
            {({ formErrors }) => {
                return (
                    <>
                        {formErrors.length > 0 && <FormErrorCallout errorMsg={formErrors[0]} />}

                        <FormTextInput inline fill bold path="password" label="Current Password" type="password" />

                        <FormTextInput inline fill bold path="new_password" label="New Password" type="password" />

                        <FormTextInput
                            inline
                            fill
                            bold
                            path="confirm_password"
                            label="Confirm New Password"
                            type="password"
                        />
                    </>
                );
            }}
        </Form>
    );
};

export default PasswordForm;
