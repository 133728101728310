import { applyMiddleware, createStore, Store, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import { Router } from 'router5';
import { router5Middleware, router5Reducer } from 'redux-router5';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

import * as cfg from 'reports/config';
import * as auth from 'reports/modules/auth';
import * as project from 'reports/modules/project';
import * as report from 'reports/modules/report';
import * as financial from 'reports/modules/financials/state';
import * as activity from 'reports/modules/activity';
import * as navigation from 'reports/modules/navigation';

import * as schema from 'reports/models/schema';

import { analyticsMiddleware } from 'reports/analytics';

import * as angularBridge from 'reports/angular-bridge';
import * as errorReports from 'reports/error-reports';
import * as clientIdentifier from 'reports/client-identifier';

export interface IAppState {
    // external libs
    router: any;

    // internal libs
    schema: any;

    // application
    config: cfg.IConfigState;
    auth: auth.IAuthState;
    project: project.IProjectState;
    report: report.IReportState;
    financial: financial.IFinancialState;
    activity: activity.IActivityState;
    navigation: navigation.INavigationState;
}

export type IAppStore = Store<IAppState>;

export function configureStore(router: Router, config: cfg.IAppConfig) {
    const appReducer = combineReducers<IAppState>({
        router: router5Reducer,

        schema: schema.createReducer('schema'),
        config: cfg.reducer,
        auth: auth.reducer,
        project: project.reducer,
        report: report.reducer,
        financial: financial.reducer,
        activity: activity.reducer,
        navigation: navigation.reducer,
    });

    const appMiddleWare = applyMiddleware(
        router5Middleware(router),
        ReduxThunk,

        analyticsMiddleware,
        errorReports.middleware(config),
        angularBridge.middleware, // TODO: delete with angular
        clientIdentifier.middleware,
    );

    return createStore(appReducer, composeWithDevToolsDevelopmentOnly(appMiddleWare));
}
