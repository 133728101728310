import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { DeepPartial, IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';
import { fromNow } from 'reports/utils/formatters';

import { CopyButton, EditButton, FavoriteStar, PublishButton } from 'reports/components/core/controls';
import { LibraryPreview as Preview } from 'reports/components/core/layout';
import { PreviewDrawer } from 'reports/components/library/main/components';

import * as auth from 'reports/modules/auth';
import { canModifySharedTeamResource, canMakeResourcePublic } from 'reports/modules/auth/permissions';

import { FinancialTemplate as FinancialModel, FinancialModelTeam, api, saver } from 'reports/models/financial_template';
import { User } from 'reports/models/user';

import { actions } from 'reports/modules/financials';
import { FinancialPipelineTree } from 'reports/modules/financials/model/pipeline';
import { verifyAdvancedPipeline } from 'reports/modules/financials/model/pipeline/utils';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import EditorPreview from 'reports/modules/financials/components/financial_template/EditorPreview';

interface IOwnProps {
    model: FinancialModel;
}

interface IDispatchProps {
    navigateToEditor: (finTemplateId: number) => any;
    navigateToMain: () => any;
    saveAsNew: () => Promise<FinancialModel>;
    setBookmarked: (bookmarked: boolean) => Promise<FinancialModelTeam>;
    updateFinancialModel: (patch: DeepPartial<FinancialModel>) => Promise<FinancialModel>;
}

interface IStateProps {
    user: User;
}

interface IState {
    drawerOpen: boolean;
}

type IProps = IOwnProps & IDispatchProps & IStateProps;

class FinModelPreview extends React.PureComponent<IProps, IState> {
    isAdvancedMode = verifyAdvancedPipeline(FinancialPipelineTree.fromRawData(this.props.model.data.root));

    state: IState = {
        drawerOpen: true,
    };

    render() {
        const { model, user } = this.props;

        const bookmarked = model.bookmarked();
        const lastModified = model.last_modified || model.created;

        return (
            <PreviewDrawer
                title={
                    <Preview.Header
                        title={model.description}
                        subtitle={lastModified ? `Last modified ${fromNow(lastModified)}` : undefined}
                        favorite={
                            <FavoriteStar
                                empty={!bookmarked}
                                onClick={() => {
                                    addPromiseToastsToggle(
                                        'favorite',
                                        this.props.setBookmarked(!bookmarked),
                                        'financial model',
                                        model.description,
                                        bookmarked,
                                    );
                                }}
                            />
                        }
                    />
                }
                publishButton={
                    <PublishButton
                        isPublic={model.public}
                        resourceName={model.description}
                        resourceType={'financial model'}
                        publicPrompt={
                            <p>
                                Making this financial model public will give all HelioScope users access to this model.
                                Everyone will be able to find and use this model, but not make any changes to the
                                resource itself.
                            </p>
                        }
                        privatePrompt={
                            <p>
                                Making this financial model private will also revoke access to this resource for any
                                HelioScope users that are not on the Folsom Labs team and are currently using this
                                model.
                            </p>
                        }
                        togglePublic={this.togglePublic}
                        disabled={!canMakeResourcePublic(user, model)}
                    />
                }
                editButton={
                    canModifySharedTeamResource(user, model) ? (
                        <EditButton onClick={() => this.props.navigateToEditor(model.financial_template_id)} />
                    ) : (
                        <CopyButton text="Copy to Library" onClick={this.onCopy} />
                    )
                }
                isOpen={this.state.drawerOpen}
                onClose={() => this.setState({ drawerOpen: false })}
                onClosed={this.props.navigateToMain}
            >
                <div style={{ padding: 5 }}>
                    <EditorPreview template={model} advanced={this.isAdvancedMode} />
                </div>
            </PreviewDrawer>
        );
    }

    onCopy = async () => {
        const newCopy = await this.props.saveAsNew();
        this.props.navigateToEditor(newCopy.financial_template_id);
    };

    togglePublic = () => this.props.updateFinancialModel({ public: !this.props.model.public });
}

const mapStateToProps = (state: IAppState) => {
    const { getUser } = auth.selectors;
    return {
        user: getUser(state),
    };
};

const mapDispatchToProps = bindActions(({ model }: IOwnProps) => ({
    navigateToEditor: (finTemplateId) =>
        routerActions.navigateTo('app.financial-templates.financial-template.edit', { finTemplateId }),
    navigateToMain: () => routerActions.navigateTo('app.financial-templates'),
    saveAsNew: () => actions.saveAsNew(model),
    setBookmarked: (bookmarked) =>
        api.patchTeamSettings({
            bookmarked,
            financial_template_id: model.financial_template_id,
        }),
    updateFinancialModel: (patch) => saver.get(model).patch(patch, true),
}));

export default connect(mapStateToProps, mapDispatchToProps)(FinModelPreview);
