import * as React from 'react';
import { actions as routerActions } from 'redux-router5';

import { Router } from 'router5';
import { connect } from 'react-redux';
import { withRoute } from 'react-router5';

import { IconNames } from '@blueprintjs/icons';

import { IAppState, IRoute } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import { ContextBarControls } from 'reports/components/ContextBar';
import { PrimaryButton, PrimaryIntent } from 'reports/components/core/controls';
import { PlaceholderContainer } from 'reports/components/helpers/Image';

import * as proj from 'reports/models/project';
import * as des from 'reports/models/design';
import { actions as projActions } from 'reports/modules/project';
import IFrameDriver from 'reports/modules/ogdesigner/IFrameDriver';
import HelioScope from 'reports/modules/ogdesigner/components/HelioScope';
import { addPromiseToasts } from 'reports/modules/Toaster';
import Logger from 'js-logger';

const logger = Logger.get('RouteLinkedDesigner');

// Nameplate at which designs start to only save metadata. Must match MAX_WIRING_SIZE in backend.
const METADATA_ONLY_NAMEPLATE = 7.5 * 1000 * 1000;

interface IOwnProps {
    design: des.Design; // Will not reflect pending changes that haven't been saved yet.
    router: Router;
    route: IRoute;
    previousRoute: IRoute;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;
interface IState {
    dispatcherReady: boolean;
}

class RouteLinkedDesigner extends React.PureComponent<IProps, IState> {
    driver: IFrameDriver | null = null;
    dispatcher: any | null = null;
    state = { dispatcherReady: false };

    deactivateHandler = (_router) => async (toState, fromState) => {
        if (toState.params.designId !== fromState.params.designId || toState.name !== 'app.projects.project.designer') {
            try {
                await this.saveAndTrySimulate();
            } catch (e) {
                // If we don't add this try catch block here the navigation becomes permanently broken
                // because of the CanDeactivate method used in Helioscope component's prop onAcquire.
                logger.error(e);
            }
        }
        return true;
    };

    saveAndTrySimulate = async () => {
        const {
            design: { design_id, project },
        } = this.props;
        const design = this.dispatcher.design;

        await this.dispatcher.stateHandler.updateQueue.flush();
        if (!design.locked) {
            if (this.dispatcher.designDirty) {
                // Save current design in old helioscope.
                // This deletes design's old simulations from the DB, but not from redux.
                await addPromiseToasts(this.dispatcher.saveDesign(), {
                    initial: `Saving ${design.description}`,
                    onCatch: () => `Error saving ${design.description}`,
                    onSuccess: () => `Successfully saved ${design.description}`,
                });

                // Delete old sims from redux, to keep them in sync w/ DB.
                this.props.deleteSimulationsLocally(design_id);
            } else if (this.dispatcher.overlaysDirty) {
                // Re-render the design render due to overlay only changes.
                this.dispatcher.overlaysDirty = false;
                this.props.triggerRender(design_id);
            }
        }

        if (this.shouldSimulateOnSave()) {
            // If there were no simulations prior to saving, automatically trigger one on Save.
            // This is to aid users with their first simulation of a design.
            this.props.triggerPrimarySimulation(project.project_id);
        }

        // update project to latest primary design
        if (project.primary_design_id !== design_id) {
            this.props.updatePrimaryDesignId(project.project_id, design_id);
        }
    };

    getAngularUrl() {
        const { designId, subpath } = this.props.route.params;

        let url = `/designer/${designId}`;

        if (subpath) {
            if (subpath.startsWith('/')) {
                url += subpath;
            } else {
                url += '/' + subpath;
            }
        }

        if (subpath && subpath.includes('?')) return url + '&forceAngular';
        return url + '?forceAngular';
    }

    getDispatcherFromDriver(driver: IFrameDriver) {
        return driver.angular.$state.$current.locals.resolve.$$promises.dispatcher;
    }

    componentWillUnmount() {
        this.props.router.canDeactivate('app.projects.project.designer', true);
    }

    componentDidUpdate(prevProps) {
        if (
            this.driver &&
            prevProps.route !== this.props.route &&
            this.props.route.name === 'app.projects.project.designer'
        ) {
            this.driver.goto(this.getAngularUrl());
        }
    }

    onRouteChange = (url) => {
        this.props.navigateTo('app.projects.project.designer', {
            ...this.props.route.params,
            subpath: this.subpathFromRoute(url),
        });
    };

    subpathFromRoute(url: string) {
        return url.split('/').slice(3).join('/');
    }

    shouldSimulateOnSave = () => {
        if (this.dispatcher == null) {
            return false;
        }
        const { simulations } = this.props;
        const { field_segments, wiring_zones } = this.dispatcher.design;
        // field_component_metadata is recomputed by the backend while saving. To dynamically update prior to save,
        // we can't rely on anything that uses field_component_metadata.
        // Instead of using Design.isComplete, which relies on field_component_metadata.nameplate, mimic how
        // nameplate is computed on the backend.
        const pendingTotalPower = field_segments.reduce((sum, fs) => sum + fs.data?.power || 0, 0);
        const isDesignComplete =
            wiring_zones != null && wiring_zones.every((wz) => wz.inverter != null) && pendingTotalPower > 0;
        return (
            simulations.length === 0 &&
            isDesignComplete &&
            // This "metadata only" check matches the computation for field_component_metadata.metadata_only.
            pendingTotalPower < METADATA_ONLY_NAMEPLATE
        );
    };

    saveAndExit = () => {
        // This will automatically trigger the design to get saved (and simulated, if shouldSimulateOnSave),
        // due to deactivateHandler.
        const projectId = this.props.design.project.project_id;
        this.props.navigateTo('app.projects.project.overview', { projectId });
    };

    render() {
        return (
            <HelioScope
                startUrl={this.getAngularUrl()}
                onRouteChange={this.onRouteChange}
                onAcquire={async (driver) => {
                    this.driver = driver;
                    this.dispatcher = await this.getDispatcherFromDriver(driver);
                    this.setState({ dispatcherReady: true });
                    this.props.router.canDeactivate('app.projects.project.designer', this.deactivateHandler);
                }}
                zIndex={2} // needs to be below Blueprint Popups
            >
                <ContextBarControls>
                    <PrimaryButton
                        intent={PrimaryIntent.SAVE}
                        icon={IconNames.FLOPPY_DISK}
                        text={'Save and Exit'}
                        onClick={this.saveAndExit}
                        disabled={!this.state.dispatcherReady}
                    />
                </ContextBarControls>
                <PlaceholderContainer title="Loading Designer" icon={IconNames.MAP} />
            </HelioScope>
        );
    }
}

const mapStateToProps = (state: IAppState, { design }: IOwnProps) => ({
    simulations: des.selectors.simulations(state, design),
});

const mapDispatchToProps = bindActions({
    deleteSimulationsLocally: projActions.deleteSimulationsLocally,
    triggerPrimarySimulation: projActions.triggerPrimarySimulation,
    updatePrimaryDesignId: (projectId, designId) => (dispatch) =>
        dispatch(proj.saver.get({ project_id: projectId }).patch({ primary_design_id: designId })),
    navigateTo: routerActions.navigateTo,
    triggerRender: (designId) => des.api.trigger_render({ design_id: designId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRoute(RouteLinkedDesigner));
