import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as rep from 'reports/models/report';
import * as teams from 'reports/models/team';
import * as usr from 'reports/models/user';

import * as auth from 'reports/modules/auth';
import { addPromiseToasts } from 'reports/modules/Toaster';

import { actions } from '../index';
import TeamOverviewForm from './TeamOverviewForm';

interface Props {
    team: teams.Team;
    user: usr.User;
}

const TeamOverview = ({ team, user }: Props) => {
    const dispatch = useDispatch();
    const authUser: usr.User = useSelector((state) => auth.selectors.getUser(state)!);
    const updateTeam = (teamForm) => dispatch(actions.updateTeam(team, teamForm, user.email));
    const updateTeamName = (teamName) => dispatch(teams.api.updateName({ name: teamName }, { team_id: team.team_id }));
    const getTeamBookmarkedReports = () => dispatch(rep.api.index({ bookmarked: true, as_user: user.email }));
    const onlyUpdateTeamName = !user.is_current && authUser.is_admin;

    const [userReports, setUserReports] = React.useState<rep.Report[]>([]);
    React.useEffect(() => {
        (async () => setUserReports(await getTeamBookmarkedReports()))();
        return () => {};
    }, [user.email]);

    const canManageTeamResources = authUser != null ? auth.permissions.canManageTeamResources(authUser, team) : false;
    const onSubmit = async (formData) => {
        const update = onlyUpdateTeamName ? () => updateTeamName(formData.name) : () => updateTeam(formData);
        await addPromiseToasts(update(), {
            initial: 'Saving team settings...',
            onSuccess: 'Successfully saved team settings.',
            onCatch: 'Error saving team settings.',
        });
    };
    return (
        <TeamOverviewForm
            team={team}
            user={user}
            authUser={authUser}
            reports={userReports}
            onSubmit={onSubmit}
            disabled={!canManageTeamResources || !user.is_current}
        />
    );
};

export default TeamOverview;
