import * as React from 'react';
import { Card, Colors, Intent } from '@blueprintjs/core';

import { AnchorButton, LinkButton } from 'reports/components/core/controls';
import { ILinkButtonTrackingOpts } from 'reports/components/core/controls/LinkButton';
import { formatBillingCurrency } from 'reports/modules/settings/billing';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export interface ICardContent {
    title: string;
    subTitle?: string;
    subTitleFormatter?: (subTitleProjectCount: number) => string;
    buttonRoute?: string;
    buttonRouteParams?: any;
    buttonText: string;
    planTypeText: string;
    subTitleProjectCount?: number;
    externalLink?: string;
    ctaTracking?: ILinkButtonTrackingOpts;
}

export interface IAddProjectsCardProps extends ICardContent {
    price?: number;
    showButton: boolean;
}

const CardItem = styled.div`
    flex: 1;
`;

const StyledCard = styled(Card)`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
`;

const CardTitle = styled.div`
    font-size: 22px;
    line-height: normal;
    font-weight: 700;
    color: #607d8b;
    margin-bottom: 8px;
`;

const CardText = styled.div`
    font-size: 16px;
`;

const Pricing = styled.div`
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
`;

const PricingSubText = styled.div`
    font-size: 14px;
`;

export const AddProjectsCard: React.FC<IAddProjectsCardProps> = ({
    subTitleProjectCount,
    buttonRoute,
    buttonRouteParams,
    buttonText,
    externalLink,
    planTypeText,
    price,
    showButton,
    subTitle,
    subTitleFormatter,
    title,
    ctaTracking,
}) => {
    let subTitleText = subTitle;

    if (subTitleFormatter && subTitleProjectCount) {
        subTitleText = subTitleFormatter(subTitleProjectCount);
    }

    const formattedPrice = price ? formatBillingCurrency(price, 2) : undefined;
    return (
        <StyledCard elevation={1}>
            <CardItem>
                <CardTitle>{title}</CardTitle>
                <CardText>{subTitleText}</CardText>
            </CardItem>
            <CardItem style={{ minHeight: '50px' }}>
                {formattedPrice && (
                    <>
                        <Pricing>{formattedPrice}</Pricing>
                        <PricingSubText>{planTypeText} per license</PricingSubText>
                    </>
                )}
            </CardItem>
            {showButton && (
                <CardItem>
                    {buttonRoute && (
                        <LinkButton
                            style={{ color: Colors.WHITE }}
                            text={buttonText}
                            routeName={buttonRoute}
                            routeParams={buttonRouteParams}
                            large
                            intent={Intent.PRIMARY}
                            tracking={ctaTracking}
                        />
                    )}
                    {externalLink && (
                        <AnchorButton
                            target="_blank"
                            href={externalLink}
                            text={buttonText}
                            large
                            intent={Intent.PRIMARY}
                        />
                    )}
                </CardItem>
            )}
        </StyledCard>
    );
};
