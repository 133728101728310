import * as React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'reports/store';
import * as cfg from 'reports/config';

import * as usr from 'reports/models/user';
import Section2 from 'reports/components/core/containers/Section2';
import UserFeaturesForm from 'reports/modules/settings/admin/components/UserFeaturesForm';
import { Intent, Tag } from '@blueprintjs/core';

interface Props {
    user: usr.User;
}

const UserFeatures = (props: Props) => {
    const config = useSelector((state: IAppState) => cfg.selectors.getConfig(state as IAppState));
    const user = useSelector((state: IAppState) => usr.selectors.byObject(state, props.user)!);

    return (
        <Section2 title="Feature Flags" contextEl={<Tag intent={Intent.DANGER}>HelioScope admin only</Tag>}>
            <UserFeaturesForm user={user} features={config!.admin_data.all_experiments} />
        </Section2>
    );
};

export { UserFeatures };
