import { Flex } from 'reports/components/core/containers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const Body = styled(Flex.ContainerV)`
    position: relative;
    height: 100%;
    width: 100%;
`;

const Main = styled(Flex.Main)`
    overflow: hidden;
`;

export default {
    Body,
    Main,
};
