import * as React from 'react';

import BasicTable from 'reports/components/core/tables/BasicTable';
import { Current, Resistance, FormattedNumber } from 'reports/components/core/numbers';
import { ModuleCharacterization, ModuleModelTypes, charApi } from 'reports/models/module';

interface IOwnProps {
    characterization: ModuleCharacterization;
}

const RawParameters: React.FC<IOwnProps> = ({ characterization }) => (
    <BasicTable tableTheme="specs" width="100%">
        <tbody>
            <tr>
                <th>Module Characterization Type</th>
                <td>{ModuleModelTypes[characterization.module_model_name]}</td>
            </tr>
            {characterization.module_model_name === 'full_diode' && <FullDiode characterization={characterization} />}
            {characterization.module_model_name === 'pvsyst' && <PVSyst characterization={characterization} />}
        </tbody>
    </BasicTable>
);

const FullDiode = ({ characterization }: { characterization: ModuleCharacterization }) => (
    <>
        {characterization.methodology && (
            <tr>
                <th>Methodology</th>
                <td>{characterization.methodology}</td>
            </tr>
        )}
        <tr>
            <th>
                Diode Ideality (<em>a</em>)
            </th>
            <td>
                <FormattedNumber value={characterization.a} precision={'full'} />
            </td>
        </tr>
        <tr>
            <th>
                Saturation Current (
                <em>
                    I<sub>0</sub>
                </em>
                )
            </th>
            <td>
                <Current value={characterization.i0} precision={'full'} />
            </td>
        </tr>
        <tr>
            <th>
                Series Resistance (
                <em>
                    R<sub>s</sub>
                </em>
                )
            </th>
            <td>
                <Resistance value={characterization.series_resistance} precision={'full'} />
            </td>
        </tr>
        <tr>
            <th>
                Parallel Resistance (
                <em>
                    R<sub>p</sub>
                </em>
                )
            </th>
            <td>
                <Resistance value={characterization.parallel_resistance} precision={'full'} />
            </td>
        </tr>
        <tr>
            <th>
                Temperature Coefficient (<em>&tau;</em>)
            </th>
            <td>
                <FormattedNumber value={characterization.temp_i0} precision={'full'} />
            </td>
        </tr>
    </>
);

const PVSyst = ({ characterization }: { characterization: ModuleCharacterization }) => {
    const { module_characterization_id, has_file, pvsyst_filename, methodology, di2_mu_tau } = characterization;
    return (
        <>
            {has_file && module_characterization_id && (
                <tr>
                    <th>PAN File</th>
                    <td>
                        <a
                            target="_self"
                            href={charApi.panFile.url({
                                module_characterization_id,
                            })}
                        >
                            {pvsyst_filename}
                        </a>
                    </td>
                </tr>
            )}
            {methodology && (
                <tr>
                    <th>Methodology</th>
                    <td>{methodology}</td>
                </tr>
            )}
            <tr>
                <th>
                    Reference Saturation Current,{' '}
                    <em>
                        I<sub>0ref</sub>
                    </em>
                </th>
                <td>
                    <Current value={characterization.i0_ref} precision={'full'} />
                </td>
            </tr>
            <tr>
                <th>
                    Reference Photocurrent,{' '}
                    <em>
                        I<sub>phref</sub>
                    </em>
                </th>
                <td>
                    <Current value={characterization.iph_ref} precision={'full'} />
                </td>
            </tr>

            <tr>
                <th>
                    Module Quality Factor,{' '}
                    <em>
                        &gamma;<sub>ref</sub>
                    </em>
                </th>
                <td>
                    <FormattedNumber value={characterization.gamma_ref} precision={'full'} />
                </td>
            </tr>
            <tr>
                <th>
                    Module Quality Factor Temp Dependence,{' '}
                    <em>
                        &mu;<sub>&gamma;</sub>
                    </em>
                </th>
                <td>
                    <FormattedNumber value={characterization.mu_gamma} precision={'full'} /> /&deg;C
                </td>
            </tr>

            <tr>
                <th>
                    Current Temperature Coefficient,{' '}
                    <em>
                        &mu;<sub>Isc</sub>
                    </em>
                </th>
                <td>
                    <FormattedNumber value={characterization.mu_isc} precision={'full'} /> mA/&deg;C
                </td>
            </tr>

            <tr>
                <th>
                    Series Resistance,{' '}
                    <em>
                        R<sub>s</sub>
                    </em>
                </th>
                <td>
                    <Resistance value={characterization.rseries} precision={'full'} />
                </td>
            </tr>
            <tr>
                <th>
                    Default Shunt Resistance,{' '}
                    <em>
                        R<sub>shunt</sub>(0)
                    </em>
                </th>
                <td>
                    <Resistance value={characterization.rshunt_0} precision={'full'} />
                </td>
            </tr>
            <tr>
                <th>
                    Reference Shunt Resistance,{' '}
                    <em>
                        R<sub>shunt</sub>(G<sub>ref</sub>)
                    </em>
                </th>
                <td>
                    <Resistance value={characterization.rshunt_gref} precision={'full'} />
                </td>
            </tr>
            <tr>
                <th>
                    Exponential Shunt Resistance Factor, <em>&beta;</em>
                </th>
                <td>
                    <Resistance value={characterization.rshunt_exp} precision={'full'} />
                </td>
            </tr>
            {di2_mu_tau !== 0 && (
                <tr>
                    <th>
                        Amorphous Recombination Loss Factor,{' '}
                        <em>
                            di<sup>2</sup>/&mu;&tau;
                        </em>
                    </th>
                    <td>
                        <FormattedNumber value={di2_mu_tau} precision={'full'} />
                    </td>
                </tr>
            )}
        </>
    );
};

export default RawParameters;
