/* tslint:disable:variable-name */
import moment from 'moment';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from './schema';

class InvoiceV1 extends BaseClass {
    external_id: string;
    total: number;
    paid: boolean;
    closed: boolean;
    created: moment.Moment;
    s3_key: string;
    last4: string;
    stripe_id: string;
    invoice_url: string;

    constructor(data) {
        super(InvoiceV1.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        created: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(InvoiceV1, 'invoiceV1', {
    idName: 'external_id',
});

const endpoint = ReduxEndpoint.fromSchema('/api/v2/legacy_invoices/', schemaObj);

const api = {
    index: endpoint.index<{ team_id: number }>(),
};

export { InvoiceV1, api };
