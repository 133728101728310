import * as React from 'react';
import { useSelector } from 'react-redux';

import * as DS from '@aurorasolar/ds';

import { selectors } from 'reports/modules/auth';
import { UserFeatures as BetaUserFeatures } from 'reports/modules/settings/admin/views/UserFeatures';

import { User } from 'reports/models/user';

import { setActionBar } from 'frontend/layout/action-bar';

const UserFeatures = () => {
    // This will be updated soon to include user impersonation and administration.
    // The user will loaded via the API at the route level or by this selector.
    const user: User = useSelector((state) => selectors.getUser(state)!);

    setActionBar(<DS.Button icon={DS.IconPlay}>Simulate Button</DS.Button>);
    return <BetaUserFeatures user={user} />;
};

export { UserFeatures };
