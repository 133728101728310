import * as React from 'react';

import { FormGroup } from '@blueprintjs/core';

import { Link } from 'react-router5';

import { DetailLine, pluralizeText } from 'reports/modules/settings/common';
import { ReferrerType } from 'reports/analytics/ReferrerTypes';

import { Subscription } from 'reports/models/subscription';

interface Props {
    showUpdateCTA: boolean;
    subscription: Subscription;
    referrer: ReferrerType;
}

const LicenseAllocation = ({ referrer, showUpdateCTA, subscription }: Props) => {
    const showChangeTeamSize = showUpdateCTA && subscription.is_active && !subscription.scheduled_changes;
    return subscription.is_contract ? (
        <FormGroup label="Seats">
            <DetailLine>
                {`${subscription.subscribers.length.toLocaleString()} of ${subscription.paid_seats.toLocaleString()} ${pluralizeText(
                    'seat',
                    subscription.paid_seats,
                    false,
                )} allocated`}
            </DetailLine>
            <DetailLine>
                Need more seats?{' '}
                <a href="mailto:customer.success@helioscope.com?subject=Custom%20Plan%20Seat%20Request" target="_blank">
                    Send us an email
                </a>
            </DetailLine>
        </FormGroup>
    ) : (
        <FormGroup label="Licenses">
            <DetailLine>
                {pluralizeText('License', subscription.paid_seats)} ({subscription.subscribers.length} of{' '}
                {pluralizeText('seat', subscription.paid_seats)} allocated)
            </DetailLine>
            <DetailLine>
                {showChangeTeamSize && (
                    <Link routeName="app.settings.team.billing" routeParams={{ referrer, dialog: 'licenses' }}>
                        Change Team Size
                    </Link>
                )}
            </DetailLine>
        </FormGroup>
    );
};

export { LicenseAllocation };
