import React, { useEffect, useState } from 'react';
import { Text, Modal, ModalBody, ModalFooter, ModalHeader, StyleProvider, Button } from '@aurorasolar/ds';
import PasswordForm from './PasswordForm';
import { useDispatch } from 'react-redux';

import * as usr from 'reports/models/user';
import { formikSetFieldErrors } from 'reports/modules/auth/components/common';
import { FormikHelpers } from 'formik';
import { HelioScopeTheme } from 'reports/styles/theme';

export interface ChangePasswordFormData {
    password: string;
    new_password: string;
    confirm_password: string;
}

const STATE_INITIAL = 'STATE_INITIAL';
const STATE_SUCCESS = 'STATE_SUCCESS';

const PasswordChangedMessage = ({ onClose }) => (
    <>
        <Text>You've successfully reset your HelioScope password</Text>
        <Button fullWidth size="md" type="submit" variant="primary" action={onClose}>
            Close window
        </Button>
    </>
);

const ChangePasswordDialog = ({ user, open, onClose }) => {
    const dispatch = useDispatch();
    const [dialogState, setDialogState] = useState(STATE_INITIAL);
    const changePassword: (form: ChangePasswordFormData) => Promise<usr.User> = (form: usr.IPasswordForm) =>
        dispatch(usr.api.save({ ...form, email: user.email }, { action: 'change_password' }));

    useEffect(() => {
        setDialogState(STATE_INITIAL);
    }, [open]);

    const onSubmit = async (formData: ChangePasswordFormData, formikHelpers: FormikHelpers<ChangePasswordFormData>) => {
        const { setSubmitting } = formikHelpers;
        try {
            await changePassword(formData);
            setDialogState(STATE_SUCCESS);
        } catch (ex) {
            formikSetFieldErrors(ex, formikHelpers);
            throw ex;
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <StyleProvider theme={HelioScopeTheme} colorMode="light">
            <Modal bg="uiBg" open={open} handleClose={onClose}>
                <ModalHeader>
                    {dialogState === STATE_INITIAL && (
                        <Text as="h2" text="h2">
                            Change password
                        </Text>
                    )}
                    {dialogState === STATE_SUCCESS && (
                        <Text as="h2" text="h2">
                            You're all set.
                        </Text>
                    )}
                </ModalHeader>
                <ModalBody padding="10px">
                    {dialogState === STATE_INITIAL && <PasswordForm onSubmit={onSubmit} />}
                    {dialogState === STATE_SUCCESS && <PasswordChangedMessage onClose={onClose} />}
                </ModalBody>
                <ModalFooter />
            </Modal>
        </StyleProvider>
    );
};

export default ChangePasswordDialog;
