import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reports/store';

import { isEqual } from 'lodash';

import { api as externalCredentialsApi, selectors, ExternalCredentials } from 'reports/models/external_credentials';
import ExternalIntegrationsCard from './ExternalIntegrationsCard';
import ExternalCredentialsDialog from './ExternalCredentialsDialog';
import { Toaster } from 'reports/modules/Toaster';
import { Intent } from '@blueprintjs/core';

import Logger from 'js-logger';

export interface IntegrationOption {
    // service is a unique key that is used server side to specify the external integration
    service: string;
    title: string;
    shortDescription: string;
    imgUrl: string;
    dialogTitle: string;
    dialogInstructions: string | React.ReactNode;
}

type IntegrationServiceName = 'nearmap'; // Add more service names as needed

export const INTEGRATIONS_LIST_OPTIONS: Record<IntegrationServiceName, IntegrationOption> = {
    nearmap: {
        service: 'nearmap',
        title: 'Nearmap Imagery',
        shortDescription: 'Use an existing Nearmap account to access high quality imagery data in your solar projects',
        imgUrl: require('reports/static/nearmap-logo.svg'),
        // props require for the dialog to render
        dialogTitle: 'Nearmap Imagery',
        dialogInstructions: (
            <p>
                Link your existing Nearmap account to access high-resolution imagery in HelioScope.{' '}
                <a href="https://help-center.helioscope.com/hc/en-us/articles/23029761257235">
                    Follow our instructions
                </a>{' '}
                for generating a Nearmap API Key and paste the API Key below.
            </p>
        ),
    },
    // Add more integration options as needed following the same structure
};

// Ordered list of integrations to be displayed on the integrations page
export const INTEGRATIONS_LIST = [INTEGRATIONS_LIST_OPTIONS.nearmap];

const ExternalIntegrationsList = () => {
    const dispatch = useDispatch();
    const credentials: ExternalCredentials[] = useSelector((state: IAppState) => selectors.all(state));
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [serviceMapping, setServiceMapping] = React.useState({});
    const [selectedService, setSelectedService] = React.useState<string | null>(null);
    const getAllExternalIntegrations = () => dispatch(externalCredentialsApi.index({}));

    const disconnectIntegration = (service) => {
        dispatch(externalCredentialsApi.disconnect({ service }));
        const serviceName = INTEGRATIONS_LIST_OPTIONS[service].title;
        Toaster.show({ intent: Intent.SUCCESS, message: `Successfully disconnected ${serviceName}` });
    };

    React.useEffect(() => {
        const fetchAllIntegrations = async () => {
            try {
                await getAllExternalIntegrations();
            } catch (error) {
                Toaster.show({ intent: Intent.DANGER, message: 'Error fetching external integratons' });
                Logger.error('Error fetching external integratons');
            }
        };
        fetchAllIntegrations();
    }, []);

    React.useEffect(() => {
        const updateServiceMapping = () => {
            const newServiceMapping = {};
            credentials.forEach((obj) => {
                if ('service' in obj) {
                    const serviceName = obj.service;
                    newServiceMapping[serviceName] = obj;
                }
            });
            if (!isEqual(newServiceMapping, serviceMapping)) {
                setServiceMapping(newServiceMapping);
            }
        };

        credentials.length && updateServiceMapping();
    }, [credentials]);

    const handleDialogOpen = (service) => {
        setSelectedService(service);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setSelectedService(null);
        setIsDialogOpen(false);
    };

    const checkIsConnected = (serviceName) => {
        // we currently consider a service connected if it contains a truthy api_key
        // this may change as we add new services.
        if (serviceName in serviceMapping) {
            return !!serviceMapping[serviceName].api_key;
        }
        return false;
    };

    return (
        <>
            {INTEGRATIONS_LIST.map((integration, idx) => {
                const isConnected = checkIsConnected(integration.service);

                return (
                    <ExternalIntegrationsCard
                        key={idx}
                        connected={isConnected}
                        onDisconnect={() => disconnectIntegration(integration.service)}
                        onConnect={() => handleDialogOpen(integration.service)}
                        onCancel={handleDialogClose}
                        options={integration}
                    />
                );
            })}
            {selectedService !== null && (
                <ExternalCredentialsDialog
                    handleClose={handleDialogClose}
                    options={INTEGRATIONS_LIST_OPTIONS[selectedService]}
                    isOpen={isDialogOpen}
                />
            )}
        </>
    );
};

export default ExternalIntegrationsList;
