import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRoute } from 'react-router5';
import { actions as routerActions } from 'redux-router5';

import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';
import { IWithRouteProps } from 'reports/utils/router';

import { CreateButton } from 'reports/components/core/controls';
import UpsellBanner from 'reports/components/UpsellBanner';
import { LibraryMain } from 'reports/components/library/main/components';

import * as auth from 'reports/modules/auth';
import { promptModalBoolean } from 'reports/components/dialog';

import { actions as reportActions } from 'reports/modules/report';
import ReportsList from 'reports/modules/report/components/ReportsList';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';

interface IOwnProps {
    project?: proj.Project;
    selectedReport?: rep.Report;
    openReport: (report: rep.Report, editing: boolean) => void;
    preview?: React.ReactNode;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps & IWithRouteProps;

class ReportLibrary extends React.PureComponent<IProps> {
    render() {
        const { user, preview, route, loadItems, refreshItem } = this.props;

        return (
            <>
                <UpsellBanner />
                <LibraryMain
                    resourceIdName="report_id"
                    loadItems={loadItems}
                    refreshItem={refreshItem}
                    views={[
                        {
                            view: <ReportsList openReport={this.openReport} />,
                            icon: IconNames.LIST,
                            id: 'report-list',
                        },
                    ]}
                    id="reports-views"
                    quickFilters={[
                        {
                            name: 'bookmarked',
                            icon: IconNames.STAR,
                            text: 'Favorited',
                        },
                        {
                            name: 'team_id',
                            icon: IconNames.PEOPLE,
                            text: `Only Show My Team`,
                            value: user.team.team_id,
                        },
                    ]}
                    contextBarControls={<CreateButton text="New Report" onClick={() => this.createSimpleReport()} />}
                    preview={preview}
                    selectedItemId={route.params.reportId}
                />
            </>
        );
    }

    openReport = async (report: rep.Report, editing: boolean = false) => {
        const { project, openReport, navigateTo } = this.props;
        if (project == null) {
            // For users w/ no projects, show dialog saying "Project must be created first".
            const shouldCreateProj = await promptModalBoolean({
                title: 'Project Required to View Report',
                prompt:
                    "You don't have any projects to preview reports with. Before viewing this report, " +
                    'you must create a project via the "New Project" button on the "All Projects" page. ' +
                    'Would you like to go there now?',
                cancellable: false,
                noLabel: 'Cancel',
                yesLabel: 'Go to "All Projects" page',
            });

            if (shouldCreateProj) {
                navigateTo('app.projects');
            }
            return;
        }

        openReport(report, editing, project.project_id);
    };

    createSimpleReport = async () => {
        const { user } = this.props;

        const time = moment().format('YYYY-MM-DD hh:mm:ss');
        const name = `${user.full_name}'s Report (${time})`;

        const newReport = await this.props.createReport(name);
        return this.openReport(newReport, true);
    };
}

const mapStateToProps = (state) => ({
    refreshItem: (item) => rep.selectors.byObject(state, item, { creator: true, team: true }) || item,
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    createReport: (name) => rep.api.create({ name }),
    loadItems: rep.api.index,
    loadProjects: proj.api.index,
    navigateTo: routerActions.navigateTo,
    openReport: reportActions.open,
}));

export default connect(mapStateToProps, mapDispatchToProps)(withRoute(ReportLibrary));
