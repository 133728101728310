import * as React from 'react';
import * as mod from 'reports/models/module';

import { Classes, Dialog, Divider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';

import { Button as SecondaryButton, CreateButton } from 'reports/components/core/controls';
import { Form, IFormUpdateCallback, NestedFields } from 'reports/components/forms';
import { FormFixedItemsSelect, FormTextAreaInput, FormTextInput } from 'reports/components/forms/inputs/experimental';
import { User } from 'reports/models/user';
import { ModulePreviewContents } from 'reports/modules/module/components/ModulePreview';
import {
    IModuleUploadItem,
    isNonAdminWithPublicModule,
    USER_DEFINED_MODULE_ID,
} from 'reports/modules/module/module_upload';
import { percentage } from 'reports/utils/formatters';
import ModuleUploadHelperText from './ModuleUploadHelperText';

interface IOwnProps {
    user: User;
    previewItem: IModuleUploadItem;
    isOpen: boolean;
    onClose: () => void;
    uploadItem: (IModuleUploadItem) => void;
    onUpdate?: IFormUpdateCallback<IModuleUploadItem, void>;
}

const ModuleUploadPreviewDialog: React.FC<IOwnProps> = ({
    user,
    previewItem,
    isOpen,
    onClose,
    uploadItem,
    onUpdate,
}) => {
    const previewMatchedChar = previewItem.matchedChar;
    if (previewMatchedChar === null) {
        throw new Error(`Preview Item's matched characterization is null`);
    }

    const { modules } = previewMatchedChar.metadata;
    let similarModules;
    let perfectMatchExists = false;
    if (modules.length) {
        similarModules = modules.map((m) => ({
            key: m.module_id,
            name: `${m.manufacturer}, ${m.name} (${percentage(m._match_quality)})`,
        }));
        perfectMatchExists = modules[0]._match_quality === 1;
    }

    if (previewItem.newModuleName) {
        similarModules.unshift({
            key: USER_DEFINED_MODULE_ID,
            name: `${previewMatchedChar.module.manufacturer}, ${previewItem.newModuleName}`,
        });
    }

    // Setting the form's base value.
    const formItemBaseValue: IModuleUploadItem = { ...previewItem };
    formItemBaseValue.matchedChar = new mod.MatchedCharacterization(previewMatchedChar);
    return (
        <Dialog title="PAN Characterization Upload" isOpen={isOpen} onClose={onClose} style={{ width: 900 }}>
            <div className={Classes.DIALOG_BODY}>
                <Form
                    baseValue={formItemBaseValue}
                    onSubmit={(item: IModuleUploadItem) => {
                        // Close the preview dialog upon attempting to upload because
                        // upload status will be shown in the upload dialog.
                        onClose();
                        uploadItem(item);
                    }}
                    onUpdate={onUpdate}
                >
                    {({ submitForm, formData }: { submitForm: any; formData: IModuleUploadItem }) => {
                        const { selectedModule } = formData;
                        let previewModule = { ...previewMatchedChar.module };
                        if (selectedModule) {
                            if (selectedModule.module_id) {
                                const matchedMod = modules.find((m) => m.module_id === selectedModule.module_id);
                                if (matchedMod) {
                                    previewModule = matchedMod;
                                }
                            } else {
                                previewModule.name = selectedModule.module_name;
                            }
                        }
                        const isPublicNonAdmin = isNonAdminWithPublicModule(user, selectedModule);

                        return (
                            <>
                                {modules.length > 0 && (
                                    // TODO: Migrate to FormBasicSelect
                                    <FormFixedItemsSelect
                                        inline
                                        bold
                                        width={300}
                                        label="Module"
                                        nilItemName={'- Select a Module -'}
                                        helperText={
                                            <ModuleUploadHelperText
                                                perfectMatchExists={perfectMatchExists}
                                                isPublicNonAdmin={isPublicNonAdmin}
                                            />
                                        }
                                        path="selectedModule"
                                        items={similarModules}
                                        toRawValue={(key) => {
                                            if (key === undefined) {
                                                // Nothing has been selected.
                                                return undefined;
                                            }
                                            if (key === USER_DEFINED_MODULE_ID) {
                                                // User defined new module selected.
                                                return previewItem.selectedModule;
                                            }
                                            // Existing module selected.
                                            return modules.find((item) => item.module_id === key);
                                        }}
                                        toItemsValue={(item) => {
                                            if (item === undefined) {
                                                return undefined;
                                            }
                                            return item.module_id ? item.module_id : USER_DEFINED_MODULE_ID;
                                        }}
                                    />
                                )}
                                <NestedFields path="matchedChar">
                                    <FormTextInput inline fill bold autoFocus label="Name" path="name" />
                                    <FormTextAreaInput inline fill bold label="Description" path="description" />
                                    <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                                        <SecondaryButton text="Cancel" onClick={onClose} />
                                        <CreateButton
                                            text="Upload"
                                            icon={IconNames.UPLOAD}
                                            onClick={submitForm}
                                            disabled={!formData.selectedModule || isPublicNonAdmin}
                                        />
                                    </div>
                                </NestedFields>
                                <Divider style={{ margin: '10 0' }} />
                                <ModulePreviewContents
                                    module={previewModule}
                                    characterization={new mod.MatchedCharacterization(formData.matchedChar)}
                                />
                            </>
                        );
                    }}
                </Form>
            </div>
        </Dialog>
    );
};

export default ModuleUploadPreviewDialog;
