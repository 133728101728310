/*
 * Suggested library search bar component hierarchy:
 *    <Container>
 *        <SearchBarWithFilters popoverProps={{ content: <AdvancedFilters/> }} {...otherSearchProps}>
 *            <FilterField/>
 *            other filter fields...
 *        <QuickFilterButtons>
 *            <Button1/>
 *            <Button2/>
 *            etc...
 */
import * as React from 'react';
import styled from 'styled-components';

import {
    Button,
    ButtonGroup,
    Classes,
    Colors,
    IInputGroupProps,
    IPopoverProps,
    Intent,
    Popover,
    Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';
import { PrimaryButton, Button as SecondaryButton } from 'reports/components/core/controls';
import FormField, { IFormField } from 'reports/components/core/forms/FormField';
import { SearchBox } from 'reports/components/core/search';

const WIDTH = 400;

export const ExtraFilters = styled.div`
    margin-left: 10px;
    margin-top: 5px;
`;

const Container = (props) => <Flex.Container alignV={Flex.AlignV.CENTER}>{props.children}</Flex.Container>;

export interface IAdvancedFiltersProps {
    onApply: (e: React.SyntheticEvent) => void;
    onClear: () => void;
    children?: React.ReactNode;
}

export const AdvancedFilters = ({ onApply, onClear, ...props }: IAdvancedFiltersProps) => (
    <div style={{ width: WIDTH, padding: 8, fontSize: 12 }}>
        <Flex.Container align={Flex.Align.RIGHT} style={{ marginBottom: 8, fontSize: 12, color: Colors.GRAY2 }}>
            Advanced Filters
        </Flex.Container>
        <div>{/* Filters */ props.children}</div>
        <Flex.Container align={Flex.Align.RIGHT} style={{ marginTop: 8 }}>
            <SecondaryButton text="Clear Filters" className={Classes.POPOVER_DISMISS} onClick={onClear} />
            <PrimaryButton text="Apply" icon={IconNames.FILTER} onClick={onApply} />
        </Flex.Container>
    </div>
);

export const FilterField = (props: IFormField) => <FormField labelWidth={100} {...props} />;

interface IFilterPopover extends IPopoverProps {
    active?: boolean;
}

const FilterPopover: React.SFC<IFilterPopover> = ({ content, active, disabled, modifiers, ...popoverProps }) => (
    <Popover
        content={content}
        position={Position.BOTTOM_RIGHT}
        disabled={disabled}
        modifiers={{
            arrow: { enabled: false },
            ...modifiers,
        }}
        {...popoverProps}
    >
        <Button
            icon={IconNames.FILTER}
            rightIcon={IconNames.CARET_DOWN}
            disabled={disabled}
            intent={active ? Intent.PRIMARY : undefined}
            active={active}
            minimal={true}
        />
    </Popover>
);

export interface ISearchBarWithFilters extends Omit<IInputGroupProps, 'onChange'> {
    applySearch: (val: string) => any;
    onChange: (query: string) => void;
    advancedFilters?: JSX.Element;
    filtersActive?: boolean; // indicator for whether search currently has filters active
    popoverProps?: IPopoverProps;
    style?: React.CSSProperties;
}

export const SearchBarWithFilters: React.SFC<ISearchBarWithFilters> = ({
    applySearch,
    onChange,
    advancedFilters,
    filtersActive,
    popoverProps,
    style,
    ...inputProps
}) => (
    <SearchBox
        onTextChange={onChange}
        applySearch={applySearch}
        placeholder="Search..."
        leftIcon={IconNames.SEARCH}
        rightElement={
            advancedFilters && <FilterPopover active={filtersActive} content={advancedFilters} {...popoverProps} />
        }
        style={{ width: WIDTH, ...style }}
        {...inputProps}
    />
);

export const QuickFilterButtons: React.SFC<{ children?: React.ReactNode }> = (props) => (
    <Flex.Container style={{ marginLeft: 6 }}>
        <ButtonGroup>{/* Quick Filter Buttons */ props.children}</ButtonGroup>
    </Flex.Container>
);

export default {
    Container,

    ExtraFilters,
    AdvancedFilters,
    FilterField,
    SearchBarWithFilters,
    QuickFilterButtons,
};
