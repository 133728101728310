import React from 'react';
import { ForgotPasswordFormData } from '..';
import { Text, Button, Form, Field, TextInput, ButtonGroup } from '@aurorasolar/ds';
import { Formik, FormikHelpers } from 'formik';
import { goToLogin, goToSignup } from './common';

export interface IForgotPasswordFormProps {
    onSubmit: (formData: ForgotPasswordFormData, formikHelpers: FormikHelpers<ForgotPasswordFormData>) => any;
    loading: boolean;
}

export const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = ({ onSubmit, loading }) => {
    return (
        <>
            <Text text="body14">
                Enter the email address associated with your HelioScope account to receive a password reset link.
            </Text>
            <Formik
                initialValues={{ email: '' }}
                onSubmit={(values, formikHelpers: FormikHelpers<ForgotPasswordFormData>) => {
                    onSubmit(values, formikHelpers);
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Field
                            component={TextInput}
                            fontWeight="normal"
                            label="Email address"
                            name="email"
                            placeholder="example@email.com"
                            required
                            type="email"
                        />
                        <Button fullWidth size="md" type="submit" variant="primary" disabled={loading || isSubmitting}>
                            Send password reset link
                        </Button>
                    </Form>
                )}
            </Formik>
            <ButtonGroup justifyContent="space-between">
                <Button size="md" variant="text" action={goToLogin}>
                    Back to login
                </Button>
                <Button size="md" variant="text" action={goToSignup}>
                    Sign up
                </Button>
            </ButtonGroup>
        </>
    );
};

export default ForgotPasswordForm;
