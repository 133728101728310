import { get, groupBy } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { createRouteNodeSelector } from 'redux-router5';

import { Classes, Collapse, Icon, Intent, Position } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';

import { IAppState } from 'reports/types';
import * as auth from 'reports/modules/auth';
import { IParams } from 'reports/utils/router';
import { bindActions } from 'reports/utils/redux';

import { Link, Tooltip } from 'reports/components/core/controls';
import { Ellipsis } from 'reports/components/helpers/common';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';

import { selectors as projSelectors, isProjectRoute } from 'reports/modules/project';

import { HSLightTheme } from 'reports/styles/colors';
import * as styles from 'reports/styles/styled-components';
import UpsellDialogLauncher from './dialog/components/UpsellDialogLauncher';
import { UPSELL_DIALOG_CONTENT } from 'reports/utils/UpsellDialogConstants';

const styled = styles.styled;

export const SIDEBAR_ICON_SIZE = 19;

const TextDisabled = styled.div`
    color: ${HSLightTheme.sidebarFontDisabled};
    cursor: not-allowed;
    svg {
        color: ${HSLightTheme.sidebarFontDisabled};
    }
`;

// This is a separate element rather than styling because that seems to play better with
// Collapse's internal calculations
const CollapseSpacer = styled.div`
    width: 100%;
    height: 15px;
`;

const Tabs = styled.div`
    padding: 0px;
    background-color: ${HSLightTheme.sidebarBg};

    .selected,
    .selected:hover {
        color: ${HSLightTheme.sidebarFontActive};
        cursor: default;

        &.projectConfig {
            background-color: ${HSLightTheme.sidebarSections.projectConfig.activeBg};
        }

        &.libraries {
            background-color: ${HSLightTheme.sidebarSections.libraries.activeBg};
        }
    }

    .indent {
        padding-left: 21px;
    }
`;

const CaretRight = styled(Icon)<{ open?: boolean }>`
    margin-left: auto;
    color: ${HSLightTheme.sidebarActionIcon};
    transform: ${(props) => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: transform 0.2s ease-out;
`;

const Tab = styled.div`
    display: flex;
    height: 36px;
    padding: 8px 13px;
    margin: 0px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    user-select: none;

    /* transparent bottom border eliminates 2px shift when border is made visible; unused top border is for vertical symmetry */
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);

    transition-delay: 0s;

    & > .${Classes.ICON}:first-child svg {
        margin: 0px 8px;
    }

    &.heading {
        font-weight: 600;
        color: ${HSLightTheme.sidebarTitleFont};
        cursor: pointer;
        padding: 8px 0px;
        margin: 0px 13px;

        &:hover {
            opacity: 0.8;
        }
    }

    &.centered {
        padding: 8px 13px;
        margin: 0px;
    }

    &.open {
        border-bottom-color: ${HSLightTheme.sidebarTitleFont};

        &.projectConfig {
            color: ${HSLightTheme.sidebarSections.projectConfig.header};
            border-bottom-color: ${HSLightTheme.sidebarSections.projectConfig.header};
        }

        &.libraries {
            color: ${HSLightTheme.sidebarSections.libraries.header};
            border-bottom-color: ${HSLightTheme.sidebarSections.libraries.header};
        }
    }
`;

const StyledIcon = styled(Icon)`
    color: ${HSLightTheme.sidebarSubsectionTitleIcon};

    &.projectConfig {
        color: ${HSLightTheme.sidebarSections.projectConfig.header};
    }

    &.libraries {
        color: ${HSLightTheme.sidebarSections.libraries.header};
    }
`;

interface TabHeadingProps {
    icon: IconName;
    collapsed: boolean;
    isOpen: boolean;
    projectConfig?: boolean;
    libraries?: boolean;
    onClick: () => void;
    text: string;
}

const TabHeading: React.SFC<TabHeadingProps> = (props) => {
    const { icon, collapsed, isOpen, projectConfig, libraries, onClick, text } = props;
    return (
        <Tab
            title={text}
            onClick={onClick}
            className={classNames({
                projectConfig,
                libraries,
                heading: true,
                centered: collapsed,
                open: isOpen,
            })}
        >
            <StyledIcon icon={icon} iconSize={SIDEBAR_ICON_SIZE} className={classNames({ projectConfig, libraries })} />
            {!collapsed && (
                <>
                    <Ellipsis>{text}</Ellipsis>
                    <CaretRight open={isOpen} icon={IconNames.CARET_RIGHT} />
                </>
            )}
        </Tab>
    );
};

const StyledLink = styled(Link)`
    /* Need to specify .bp3-dark class to override .bp3-dark's styling of anchor tags. */
    &:link,
    &:active,
    &:visited,
    .bp3-dark &:hover:not(.selected) {
        color: ${HSLightTheme.sidebarFont};
    }

    /* Styling for SidebarTabs inside StyledLinks. */
    &:hover > div:not(.selected) {
        background-color: ${HSLightTheme.sidebarBgHover};
    }
`;

const ClickableSidebarItem = styled.div`
    color: ${HSLightTheme.sidebarFont};

    /* Styling for SidebarTabs inside StyledLinks. */
    &:hover > div:not(.selected) {
        background-color: ${HSLightTheme.sidebarBgHover};
    }
`;

const UpgradeIcon = styled(Icon).attrs<{ icon?: string; intent?: Intent }>({
    icon: IconNames.OFFLINE,
    intent: Intent.PRIMARY,
})`
    margin-left: 8px;
`;

interface ISidebarTabProps {
    icon: IconName;
    text?: string;
    selected?: boolean;
    projectConfig?: boolean;
    libraries?: boolean;
    indent?: boolean;
    collapsed: boolean;
    upgradeTag?: boolean;
    showTagWithToolTip?: boolean;
    onClick?: () => void;
}

const SidebarTab: React.FC<ISidebarTabProps> = ({
    icon,
    text = '',
    selected = false,
    projectConfig = false,
    libraries = false,
    indent = false,
    upgradeTag = false,
    showTagWithToolTip = false,
    onClick = undefined,
    collapsed,
}) => {
    const showUpgradeTag = showTagWithToolTip || upgradeTag;
    const toolTipText = 'Pro plan feature';

    return (
        <Tab
            title={text}
            className={classNames({
                selected,
                projectConfig,
                libraries,
                indent,
                centered: collapsed,
            })}
            onClick={onClick}
        >
            <Icon icon={icon} iconSize={SIDEBAR_ICON_SIZE} />
            {!collapsed && <Ellipsis>{text}</Ellipsis>}
            {!collapsed && showUpgradeTag && (
                <UpgrageTagIconComponent showToolTip={showTagWithToolTip} toolTipText={toolTipText} />
            )}
        </Tab>
    );
};

interface IUpgradeTagProps {
    showToolTip: boolean;
    toolTipText: String;
}

const UpgrageTagIconComponent: React.FC<IUpgradeTagProps> = ({ showToolTip, toolTipText }) => (
    <>
        {showToolTip ? (
            <Tooltip content={<div style={{ maxWidth: '200px' }}>{toolTipText}</div>} position={Position.TOP}>
                <UpgradeIcon />
            </Tooltip>
        ) : (
            <UpgradeIcon />
        )}
    </>
);

interface IOwnProps {
    collapsed: boolean;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    projectConfigOpen: boolean;
    reportsOpen: boolean;
    librariesOpen: boolean;
}

class SideBarTabs extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            projectConfigOpen: false,
            reportsOpen: false,
            librariesOpen: false,
        };
    }

    async componentDidMount() {
        const { loadBookmarkedReports, loadBasicReports, user } = this.props;

        const canViewFinancials = user.hasFinancialsAccess();
        const requests = [loadBasicReports()];

        if (canViewFinancials) {
            requests.push(loadBookmarkedReports());
        }

        await Promise.all(requests);
    }

    componentDidUpdate(prevProps) {
        const { route } = this.props;

        if (route && route.name !== (prevProps.route && prevProps.route.name)) {
            this.setState({
                projectConfigOpen: this.projectConfigSelected(),
                reportsOpen: this.projectConfigSelected(),
                librariesOpen: this.librariesSelected(),
            });
        }
    }

    render() {
        const { reports, project, route, collapsed, user } = this.props;
        const canViewFin = user.hasFinancialsAccess();
        const isTrialBasicUser = user.role.will_lose_financials_on_trial_to_basic_conversion;
        const routeParams = route != null ? route.params : {};

        const ConditionalLink = (props) => {
            const { routeName = null, routeParams: newRouteParams = null, children, disabled = false } = props;

            if (disabled) {
                return <TextDisabled>{children}</TextDisabled>;
            } else if (routeName) {
                // only pass project ID if navigating to another project subpage
                const projectId = isProjectRoute(routeName) && project ? project.project_id : undefined;

                const mergedParams = {
                    ...{ projectId },
                    ...newRouteParams,
                };

                return (
                    <StyledLink routeName={routeName} routeParams={mergedParams}>
                        {children}
                    </StyledLink>
                );
            }

            return <ClickableSidebarItem>{children}</ClickableSidebarItem>;
        };

        const matchReport = (params) => {
            if (params.reportId != null && routeParams.reportId != null) {
                // reportIds are numbers if they come from Report state, and are strings if they are parsed from
                // the router. Convert to string to compare.
                return params.reportId.toString() === routeParams.reportId.toString();
            }

            return params.slug === routeParams.slug;
        };

        const projectConfigToggle = () => {
            if (this.state.projectConfigOpen) {
                this.setState({ ...this.state, projectConfigOpen: false });
            } else {
                this.setState({
                    projectConfigOpen: true,
                    reportsOpen: true,
                    librariesOpen: false,
                });
            }
        };
        const reportsToggle = () => {
            this.setState({
                ...this.state,
                reportsOpen: !this.state.reportsOpen,
            });
        };
        const librariesToggle = () => {
            if (this.state.librariesOpen) {
                this.setState({ ...this.state, librariesOpen: false });
            } else {
                this.setState({
                    projectConfigOpen: false,
                    reportsOpen: false,
                    librariesOpen: true,
                });
            }
        };

        const renderReports = () => {
            const sortedReports = reports.sort((a, b) => a.name.localeCompare(b.name));
            const slugBatches = groupBy(reports, 'slug');
            const reportRoutes = sortedReports.map((report) => {
                const addReportId = slugBatches[report.slug].length > 1;
                const params: IParams = { slug: report.slug };

                if (addReportId) {
                    params.reportId = report.report_id;
                }

                return { params, report };
            });

            return (
                <Collapse isOpen={this.state.reportsOpen}>
                    {reportRoutes.map(({ params, report }, idx) => (
                        <ConditionalLink
                            key={idx}
                            routeName={'app.projects.project.report.view'}
                            routeParams={params}
                            disabled={!project}
                        >
                            <SidebarTab
                                projectConfig
                                indent={!collapsed}
                                icon={IconNames.DOCUMENT}
                                text={report.name}
                                selected={this.matchTabs('app.projects.project.report') && matchReport(params)}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>
                    ))}
                </Collapse>
            );
        };

        const renderFinConfig = () => {
            const hasConfig = project && project.primary_project_financial_template_id != null;
            const name = hasConfig
                ? 'app.projects.project.financial-configurations.financial-configuration'
                : 'app.projects.project.financial-configurations';
            const params = hasConfig
                ? {
                      finConfigId: project!.primary_project_financial_template_id,
                  }
                : null;

            return (
                <UpsellDialogLauncher
                    content={UPSELL_DIALOG_CONTENT.FINANCIAL}
                    referrer="project_financial_sidebar"
                    disabled={canViewFin || !project}
                >
                    {({ openDialog }) => (
                        <ConditionalLink
                            routeName={canViewFin ? name : null}
                            routeParams={canViewFin ? params : null}
                            disabled={!project}
                        >
                            <SidebarTab
                                projectConfig
                                upgradeTag={!canViewFin}
                                showTagWithToolTip={isTrialBasicUser}
                                icon={IconNames.VERTICAL_BAR_CHART_ASC}
                                text="Financial"
                                selected={this.matchTabs(
                                    'app.projects.project.financial-configurations.financial-configuration',
                                )}
                                collapsed={collapsed}
                                onClick={() => openDialog()}
                            />
                        </ConditionalLink>
                    )}
                </UpsellDialogLauncher>
            );
        };

        const renderFinancialLibraries = () => {
            return (
                <>
                    <UpsellDialogLauncher
                        content={UPSELL_DIALOG_CONTENT.FINANCIAL}
                        referrer="financial_models_library_sidebar"
                        disabled={canViewFin}
                    >
                        {({ openDialog }) => (
                            <ConditionalLink routeName={canViewFin ? 'app.financial-templates' : null}>
                                <SidebarTab
                                    libraries
                                    icon={IconNames.DOLLAR}
                                    text="Financial Models"
                                    selected={this.matchTabs('app.financial-templates')}
                                    collapsed={collapsed}
                                    onClick={() => openDialog()}
                                    upgradeTag={!canViewFin}
                                    showTagWithToolTip={isTrialBasicUser}
                                />
                            </ConditionalLink>
                        )}
                    </UpsellDialogLauncher>
                    <UpsellDialogLauncher
                        content={UPSELL_DIALOG_CONTENT.FINANCIAL}
                        referrer="utility_rates_library_sidebar"
                        disabled={canViewFin}
                    >
                        {({ openDialog }) => (
                            <ConditionalLink routeName={canViewFin ? 'app.utility-rates' : null}>
                                <SidebarTab
                                    libraries
                                    icon={IconNames.DASHBOARD}
                                    text="Utility Rates"
                                    selected={this.matchTabs('app.utility-rates')}
                                    collapsed={collapsed}
                                    onClick={() => openDialog()}
                                    upgradeTag={!canViewFin}
                                    showTagWithToolTip={isTrialBasicUser}
                                />
                            </ConditionalLink>
                        )}
                    </UpsellDialogLauncher>
                    <UpsellDialogLauncher
                        content={UPSELL_DIALOG_CONTENT.FINANCIAL}
                        referrer="incentives_library_sidebar"
                        disabled={canViewFin}
                    >
                        {({ openDialog }) => (
                            <ConditionalLink routeName={canViewFin ? 'app.incentives' : null}>
                                <SidebarTab
                                    libraries
                                    icon={IconNames.BANK_ACCOUNT}
                                    text="Incentives"
                                    selected={this.matchTabs('app.incentives')}
                                    collapsed={collapsed}
                                    onClick={() => openDialog()}
                                    upgradeTag={!canViewFin}
                                    showTagWithToolTip={isTrialBasicUser}
                                />
                            </ConditionalLink>
                        )}
                    </UpsellDialogLauncher>
                </>
            );
        };

        const renderConditionConfig = () => {
            const hasConfig = project && project.primary_scenario_id != null;
            const routeName = hasConfig
                ? 'app.projects.project.conditions.condition'
                : 'app.projects.project.conditions';
            const params = hasConfig ? { scenarioId: project!.primary_scenario_id } : null;
            const disabled = !project;

            return (
                <ConditionalLink routeName={routeName} routeParams={params} disabled={disabled}>
                    <SidebarTab
                        projectConfig
                        icon={IconNames.CLOUD}
                        text="Conditions"
                        selected={this.matchTabs('app.projects.project.conditions.condition')}
                        collapsed={collapsed}
                    />
                </ConditionalLink>
            );
        };

        const renderReportTemplates = () => {
            const name = 'app.reports';

            return (
                <UpsellDialogLauncher
                    content={UPSELL_DIALOG_CONTENT.REPORTS_AND_PROPOSALS}
                    referrer="report_templates_library_sidebar"
                    disabled={canViewFin}
                >
                    {({ openDialog }) => (
                        <ConditionalLink routeName={canViewFin ? name : null}>
                            <SidebarTab
                                upgradeTag={!canViewFin}
                                showTagWithToolTip={isTrialBasicUser}
                                libraries
                                icon={IconNames.DOCUMENT}
                                text="Report Templates"
                                selected={this.matchTabs(name)}
                                collapsed={collapsed}
                                onClick={() => openDialog()}
                            />
                        </ConditionalLink>
                    )}
                </UpsellDialogLauncher>
            );
        };

        return (
            <Tabs className={classNames('navigation', 'grid-content')}>
                <div>
                    <StyledLink routeName="app.projects">
                        <SidebarTab
                            projectConfig
                            icon={IconNames.GLOBE}
                            text="All Projects"
                            selected={this.matchTabs('app.projects')}
                            collapsed={false}
                        />
                    </StyledLink>
                    <TabHeading
                        projectConfig
                        icon={IconNames.MAP_MARKER}
                        collapsed={collapsed}
                        onClick={projectConfigToggle}
                        isOpen={this.state.projectConfigOpen}
                        text="Project Configuration"
                    />
                    <Collapse isOpen={this.state.projectConfigOpen} keepChildrenMounted={true}>
                        <ConditionalLink routeName="app.projects.project.overview" disabled={!project}>
                            <SidebarTab
                                projectConfig
                                icon={IconNames.CONTROL}
                                text="Overview"
                                selected={this.matchTabs('app.projects.project.overview')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>

                        <ConditionalLink
                            routeName="app.projects.project.designer"
                            routeParams={project ? { designId: project.primary_design_id } : null}
                            disabled={!project || project.primary_design_id == null}
                        >
                            <SidebarTab
                                projectConfig
                                icon={IconNames.WRENCH}
                                text="Designer"
                                selected={this.matchTabs('app.projects.project.designer')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>

                        {renderFinConfig()}

                        <ConditionalLink routeName="app.projects.project.simulation" disabled={!project}>
                            <SidebarTab
                                projectConfig
                                icon={IconNames.CALCULATOR}
                                text="Simulation"
                                selected={this.matchTabs('app.projects.project.simulation')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>

                        {renderConditionConfig()}

                        <ConditionalLink routeName="app.projects.project.sharing" disabled={!project}>
                            <SidebarTab
                                projectConfig
                                icon={IconNames.PEOPLE}
                                text="Sharing"
                                selected={this.matchTabs('app.projects.project.sharing')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>

                        <TabHeading
                            icon={IconNames.BOOK}
                            collapsed={collapsed}
                            onClick={reportsToggle}
                            isOpen={this.state.reportsOpen}
                            text="Reports"
                        />

                        {renderReports()}
                        <CollapseSpacer />
                    </Collapse>

                    <TabHeading
                        libraries
                        icon={IconNames.PROJECTS}
                        collapsed={collapsed}
                        onClick={librariesToggle}
                        isOpen={this.state.librariesOpen}
                        text="Libraries"
                    />

                    <Collapse isOpen={this.state.librariesOpen}>
                        <ConditionalLink routeName="app.profiles">
                            <SidebarTab
                                libraries
                                icon={IconNames.BOOK}
                                text="Profiles"
                                selected={this.matchTabs('app.profiles')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>
                        <ConditionalLink routeName="app.power-devices">
                            <SidebarTab
                                libraries
                                icon={IconNames.LIGHTNING}
                                text="Inverters & Optimizers"
                                selected={this.matchTabs('app.power-devices')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>
                        <ConditionalLink routeName="app.modules">
                            <SidebarTab
                                libraries
                                icon={IconNames.GRID_VIEW}
                                text="Modules"
                                selected={this.matchTabs('app.modules')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>
                        <ConditionalLink routeName="app.weather_sources">
                            <SidebarTab
                                libraries
                                icon={IconNames.FLASH}
                                text="Weather Sources"
                                selected={this.matchTabs('app.weather_sources')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>
                        <ConditionalLink routeName="app.wires">
                            <SidebarTab
                                libraries
                                icon={IconNames.ONE_TO_ONE}
                                text="Wires"
                                selected={this.matchTabs('app.wires')}
                                collapsed={collapsed}
                            />
                        </ConditionalLink>
                        {renderReportTemplates()}
                        {renderFinancialLibraries()}
                        <CollapseSpacer />
                    </Collapse>
                </div>
            </Tabs>
        );
    }

    matchTabs = (id) => {
        const { route } = this.props;
        const routemap = {
            'app.projects.project.conditions': 'app.projects.project.conditions.condition',
            'app.projects.project.conditions.condition.edit': 'app.projects.project.conditions.condition',
            'app.projects.project.financial-configurations':
                'app.projects.project.financial-configurations.financial-configuration',
            'app.projects.project.report.view': 'app.projects.project.report',
            'app.projects.project.report.configure': 'app.projects.project.report',
            'app.reports.report.preview': 'app.reports',
            'app.reports.report.edit': 'app.reports',
            'app.financial-templates.financial-template.preview': 'app.financial-templates',
            'app.financial-templates.financial-template.edit': 'app.financial-templates',
            'app.utility-rates.utility-rate': 'app.utility-rates',
            'app.incentives.incentive': 'app.incentives',
            'app.power-devices.power-device.preview': 'app.power-devices',
            'app.modules.module.preview': 'app.modules',
        };
        return route ? routemap[route.name] === id || route.name === id : false;
    };

    reportsSelected = () => this.matchTabs('app.projects.project.report');

    projectConfigSelected = () =>
        this.reportsSelected() ||
        this.matchTabs('app.projects.project') ||
        this.matchTabs('app.projects.project.overview') ||
        this.matchTabs('app.projects.project.designer') ||
        this.matchTabs('app.projects.project.financial-configurations.financial-configuration') ||
        this.matchTabs('app.projects.project.simulation') ||
        this.matchTabs('app.projects.project.conditions.condition') ||
        this.matchTabs('app.projects.project.sharing');

    librariesSelected = () =>
        this.matchTabs('app.reports') ||
        this.matchTabs('app.profiles') ||
        this.matchTabs('app.financial-templates') ||
        this.matchTabs('app.utility-rates') ||
        this.matchTabs('app.incentives') ||
        this.matchTabs('app.power-devices') ||
        this.matchTabs('app.modules') ||
        this.matchTabs('app.weather_sources') ||
        this.matchTabs('app.wires');
}

const mapStateToProps = (state: IAppState) => {
    const routeSelector = createRouteNodeSelector('');
    const route = routeSelector(state).route;

    const projectId = get(route, 'params.projectId', projSelectors.getLastViewedProjectId(state));
    const project = proj.selectors.byId(state, projectId);

    return {
        route,
        project,
        reports: rep.selectors.all(state),
        user: auth.selectors.getUser(state)!,
    };
};

const mapDispatchToProps = bindActions({
    loadBookmarkedReports: () => rep.api.index({ bookmarked: true }),
    loadBasicReports: () => rep.api.index({ basic_report: true }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBarTabs);
