import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { IAppState } from 'reports/types';
import { humanizeTimestamp } from 'reports/utils/formatters';
import { bindActions } from 'reports/utils/redux';

import * as pd from 'reports/models/power_device';
import * as auth from 'reports/modules/auth';

import { FavoriteStar } from 'reports/components/core/controls';
import { Power, Voltage } from 'reports/components/core/numbers';
import { CommonColumns } from 'reports/components/library/helpers';
import { ColWidth, SortType } from 'reports/components/core/tables/DataTable';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';
import { addPromiseToastsToggle } from 'reports/components/toasts';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IStateProps & IDispatchProps;

class PowerDevicesList extends React.PureComponent<IProps> {
    render() {
        const { user, toggleFavorite, openPreview } = this.props;
        return (
            <ResourceTable<pd.PowerDevice>
                columns={[
                    {
                        colWidth: ColWidth.SMALL,
                        headerText: 'Favorite',
                        renderCell: (device) => (
                            <FavoriteStar
                                empty={!device.favorite}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addPromiseToastsToggle(
                                        'favorite',
                                        toggleFavorite(device),
                                        'power device',
                                        device.name,
                                        device.favorite,
                                    );
                                }}
                            />
                        ),
                    },
                    CommonColumns.name(),
                    {
                        sort: {
                            name: 'device_type_name',
                            type: SortType.ALPHA,
                        },
                        headerText: 'Type',
                        renderCell: (device) => <>{pd.PowerDeviceTypes[device.device_type_name]}</>,
                    },
                    {
                        sort: { name: 'manufacturer', type: SortType.ALPHA },
                        headerText: 'Manufacturer',
                        renderCell: (device) => <>{device.manufacturer}</>,
                    },
                    {
                        sort: { name: 'max_power' },
                        headerText: 'Max Power',
                        renderCell: (device) => <Power value={device.max_power} />,
                    },
                    {
                        sort: { name: 'min_voltage' },
                        headerText: 'Min Voltage',
                        renderCell: (device) =>
                            device.min_voltage != null ? <Voltage value={device.min_voltage} /> : <>-</>,
                    },
                    {
                        sort: { name: 'max_voltage' },
                        headerText: 'Max Voltage',
                        renderCell: (device) =>
                            device.max_voltage != null ? <Voltage value={device.max_voltage} /> : <>-</>,
                    },
                    {
                        sort: { name: 'last_update' },
                        headerText: 'Last Modified',
                        renderCell: (device) => <>{device.last_update ? humanizeTimestamp(device.last_update) : '-'}</>,
                    },
                    CommonColumns.team(),
                    CommonColumns.publicCol(user),
                ]}
                onRowClick={(device) => openPreview(device)}
            />
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    toggleFavorite: ({ power_device_id, favorite }) => pd.api.save({ power_device_id, favorite: !favorite }),
    openPreview: ({ power_device_id, defaultCharacterizationId }) =>
        routerActions.navigateTo('app.power-devices.power-device.preview', {
            powerDeviceId: power_device_id,
            characterizationId: defaultCharacterizationId(),
        }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(PowerDevicesList);
