import * as React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import { Page } from 'frontend/layout/page-layout';
import { Buttons } from 'frontend/settings/buttons';
import { UserFeatures } from 'frontend/settings/user-features';

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <Page />,
            children: [
                {
                    path: 'projects',
                    element: <div>Projects</div>,
                },
                {
                    path: 'libraries',
                    element: <div>Libraries</div>,
                },
                {
                    path: 'settings',
                    element: <Outlet />,
                    children: [
                        {
                            path: 'hs-admin/team-features',
                            element: <Buttons />,
                        },
                        {
                            path: 'hs-admin/user-features',
                            element: <UserFeatures />,
                        },
                    ],
                },
            ],
        },
    ],
    {
        basename: '/app',
    },
);

export { router };
