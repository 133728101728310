/* tslint:disable:variable-name */
import _ from 'lodash';
import moment from 'moment';

import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';

import * as auth from 'reports/modules/auth';
import * as utility from 'reports/models/utility_rate';

import UtilityRatesList from 'reports/modules/utility_rate/components/UtilityRatesList';

import { getRateParam } from 'reports/modules/financials/utils';
import { IAPIQueryOpts } from 'reports/models/types';
import UpsellBanner from 'reports/components/UpsellBanner';

interface IDispatchProps {
    createUtilityRate: (data: any) => Promise<any>;
    loadItems: (opts: IAPIQueryOpts) => Promise<utility.UtilityRate[]>;
    navigateTo: (name: string, params: any) => any;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IStateProps & IDispatchProps;

class UtilityRateLibrary extends React.Component<IProps> {
    render() {
        return (
            <>
                <UpsellBanner />
                <LibraryMain
                    resourceIdName="utility_rate_id"
                    loadItems={this.props.loadItems}
                    refreshItem={this.props.refreshItem}
                    views={[
                        {
                            view: <UtilityRatesList />,
                            icon: IconNames.LIST,
                            id: 'rate-list',
                        },
                    ]}
                    id="rates-views"
                    quickFilters={[
                        {
                            name: 'bookmarked',
                            icon: IconNames.STAR,
                            text: 'Favorited',
                        },
                        {
                            name: 'team_id',
                            icon: IconNames.PEOPLE,
                            text: `Only Show My Team`,
                            value: this.props.user.team.team_id,
                        },
                    ]}
                    contextBarControls={<CreateButton text="New Utility Rate" onClick={() => this.addUtilityRate()} />}
                />
            </>
        );
    }

    addUtilityRate() {
        const randid = Math.floor(performance.now() * 99999 + Math.random() * 99999);
        const energyParam = getRateParam('energy_rates');
        const demandParam = getRateParam('demand_rates');

        this.props
            .createUtilityRate({
                apply_date: moment({ year: 2020, month: 0, date: 1 }),
                description: `new rate ${randid.toString(16)}`,
                data: {
                    energy_rates: _.merge({}, energyParam!.default),
                    demand_rates: _.merge({}, demandParam!.default),
                },
            })
            .then((res) => this.editUtilityRate(res.utility_rate_id));
    }

    editUtilityRate(id) {
        this.props.navigateTo('app.utility-rates.utility-rate', { rateId: id });
    }
}

const mapStateToProps = (state) => ({
    refreshItem: (item) =>
        utility.selectors.byObject(state, item, {
            creator: true,
            team: true,
        }) || item,
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    createUtilityRate: (data) => utility.api.create(data),
    loadItems: utility.api.index,
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
}));

export default connect(mapStateToProps, mapDispatchToProps)(UtilityRateLibrary);
