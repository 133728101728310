import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import * as React from 'react';
import styled from 'styled-components';

import * as analytics from 'reports/analytics';
import { Flex } from 'reports/components/core/containers';
import * as ws from 'reports/models/weather_source';
import * as auth from 'reports/modules/auth';
import WeatherSourceMap from 'reports/modules/weather_source/views/WeatherSourceMap';
import {
    FiltersPanel,
    GROUND_WEATHER_SOURCE_TYPES,
} from 'reports/modules/weather_source/views/WeatherLibraryFiltersPanel';
import Colors from 'reports/styles/colors';
import { bindActions } from 'reports/utils/redux';
import { ContextBarControls } from 'reports/components/ContextBar';
import WeatherSourceUploadLauncher from './WeatherSourceUploadLauncher';

const MapContainer = styled.div`
    position: relative;
    width: 100%;
    height: 730px;
    border: 1px solid ${Colors.BORDER_TABLE_LIGHT};
`;

const PageContainer = styled(Flex.Container)`
    margin: 20px;
`;

const SpinnerContainer = styled(Flex.ContainerV)`
    height: 100%;
    justify-content: center;
`;

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

const DefaultGroundFilters = Object.keys(GROUND_WEATHER_SOURCE_TYPES);

const WeatherLibrary: React.FC<IDispatchProps & IStateProps> = ({ getSources, user }) => {
    const [sources, setSources] = React.useState<ws.WeatherSource[] | null>(null);
    const [satelliteFilter, setSatelliteFilter] = React.useState<string | undefined>(undefined);
    const [groundFilters, setGroundFilters] = React.useState<string[]>(DefaultGroundFilters);
    const [ownTeamOnly, setOwnTeamOnly] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setSources(await getSources());
        })();
    }, []);

    const toggleGroundFilter = (srcType) => {
        setSatelliteFilter(undefined);
        setGroundFilters(
            groundFilters.includes(srcType)
                ? groundFilters.filter((st) => st !== srcType)
                : [...groundFilters, srcType],
        );
    };

    const toggleSatellite = (srcType) => {
        const isSelected = srcType === satelliteFilter;
        setSatelliteFilter(isSelected ? undefined : srcType);
        setGroundFilters(isSelected ? DefaultGroundFilters : []);
    };

    const filteredSources = sources
        ?.filter((x) => groundFilters.includes(x.source_type!))
        .filter((x) => !ownTeamOnly || x.team_id === user.team_id);

    const addWeatherSources = (newSources) => {
        setSources((sources) => (sources ?? []).concat(newSources));
    };

    return (
        <>
            <ContextBarControls>
                <WeatherSourceUploadLauncher addWeatherSources={addWeatherSources} />
            </ContextBarControls>
            <PageContainer>
                <FiltersPanel
                    groundFilters={groundFilters}
                    satelliteFilter={satelliteFilter}
                    ownTeamOnly={ownTeamOnly}
                    toggleGroundFilter={toggleGroundFilter}
                    toggleSatelliteFilter={toggleSatellite}
                    toggleOwnTeamOnly={() => setOwnTeamOnly(!ownTeamOnly)}
                    user={user}
                    sources={sources}
                />
                <MapContainer>
                    <WeatherSourceMap
                        mapConfig={{
                            initialCenter: { lat: 0, lng: 0 },
                            initialZoom: 1,
                        }}
                        weatherSources={filteredSources ?? []}
                        satelliteSourceToOutline={satelliteFilter}
                        enableClustering={true}
                        disabled={!sources}
                        infoWindowTrigger={'click'}
                        infoWindowDetailed
                        onSelectSource={(sourceId) => {
                            analytics.track('weather.inspect', {
                                weather_source_id: sourceId,
                            });
                        }}
                    />
                    {!sources && (
                        <SpinnerContainer>
                            <Spinner />
                        </SpinnerContainer>
                    )}
                </MapContainer>
            </PageContainer>
        </>
    );
};

const mapDispatchToProps = bindActions(() => ({
    getSources: () =>
        ws.api.index({
            limit: 10000,
            load_datasets: true,
        }),
}));

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state)!,
});

export default connect(mapStateToProps, mapDispatchToProps)(WeatherLibrary);
