import * as React from 'react';

import * as DS from '@aurorasolar/ds';

import { setActionBar } from 'frontend/layout/action-bar';
import { x } from '@xstyled/styled-components';

// This is a temporary component to show the different button variants.
const Buttons = () => {
    setActionBar(<DS.Button icon={DS.IconPlay}>Simulate Button</DS.Button>);
    return (
        <x.div display="flex" flexDirection="column" rowGap={2}>
            <DS.Button icon={DS.IconPlay} variant="primary">
                Primary Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="primary" disabled>
                Primary Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="secondary">
                Secondary Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="secondary" disabled>
                Secondary Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="tertiary">
                Tertiary Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="tertiary" disabled>
                Tertiary Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="cta">
                Cta Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="cta" disabled>
                Cta Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="destructive">
                Destructive Button
            </DS.Button>
            <DS.Button icon={DS.IconPlay} variant="destructive" disabled>
                Destructive Button
            </DS.Button>
        </x.div>
    );
};

export { Buttons };
