import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';
import * as repCfg from 'reports/models/report/ReportConfiguration';
import * as auth from 'reports/modules/auth';

import { IAppState } from 'reports/store';
import { bindActions } from 'reports/utils/redux';

import DocumentEditor from '../components/DocumentEditor';
import UpsellBanner from 'reports/components/UpsellBanner';
import ProjectReportViewerControls from './ProjectReportViewerControls';
import UpdateReportConfigControls from '../components/UpdateReportConfigControls';
import UpgradeToProReportControls from '../components/UpgradeToProReportControls';

interface IOwnProps {
    report: rep.Report;
    project: proj.Project;
    printableReady: Promise<any>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

const ProjectReportViewer = (props: IProps) => {
    const { report, project, printableReady, latestVersion, reportCfg, user } = props;
    const { openConfigEditor, createReportConfig } = props;
    const canViewFinancials = user.hasFinancialsAccess();
    const createAndOpenConfigEditor = async () => {
        let cfg = reportCfg;
        if (cfg == null) {
            cfg = await createReportConfig({
                report_version_id: latestVersion.report_version_id,
                project_id: project.project_id,
                configuration_data: { widgets: {} },
            });
        }
        return openConfigEditor();
    };

    let reportControls: React.ReactNode = null;
    if (canViewFinancials) {
        if (reportCfg) {
            reportControls = <UpdateReportConfigControls reportConfig={reportCfg} />;
        }
    } else if (!report.basic_report) {
        reportControls = <UpgradeToProReportControls />;
    }

    return (
        <>
            {!report.basic_report && <UpsellBanner />}
            <ProjectReportViewerControls
                report={report}
                project={project}
                reportConfig={reportCfg}
                reportVersion={latestVersion}
                canViewFinancials={canViewFinancials}
                configureBtnOnClick={createAndOpenConfigEditor}
            />
            <DocumentEditor
                mode="view"
                report={report}
                document={reportCfg ? reportCfg.makeDocument() : latestVersion.document}
                project={project}
                printableReady={printableReady}
                controls={reportControls}
            />
        </>
    );
};

const mapStateToProps = (state: IAppState, { report, project }: IOwnProps) => ({
    user: auth.selectors.getUser(state)!,
    latestVersion: rep.versionSelectors.latestVersion(state, report)!,
    reportCfg: repCfg.selectors.configForProject(state, report, project.project_id),
});

const mapDispatchToProps = bindActions(({ report, project }: IOwnProps) => ({
    createReportConfig: repCfg.api.create,
    openConfigEditor: () =>
        routerActions.navigateTo('app.projects.project.report.configure', {
            reportId: report.report_id,
            slug: report.slug,
            projectId: project.project_id,
        }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProjectReportViewer);
