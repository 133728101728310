import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { bindActions } from 'reports/utils/redux';

import * as mod from 'reports/models/module';
import { IAppState } from 'reports/store';
import CharacterizationViewControls from 'reports/components/library/helpers/CharacterizationViewControls';

interface IOwnProps {
    module: mod.Module;
    selectedChar: mod.ModuleCharacterization;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

const ModuleCharViewControls: React.FC<IProps> = (props) => {
    const { selectedChar, module, characterizations, updateModule, navigateTo } = props;

    return (
        <CharacterizationViewControls
            characterizations={characterizations}
            selectedChar={selectedChar}
            setSelectedChar={(char) =>
                navigateTo('app.modules.module.preview', {
                    moduleId: module.module_id,
                    characterizationId: char.module_characterization_id,
                })
            }
            isDefaultChar={(char) => char.module_characterization_id === module.user_default_characterization_id}
            makeDefaultChar={({ module_id, module_characterization_id }) =>
                updateModule({
                    module_id,
                    user_default_characterization_id: module_characterization_id,
                })
            }
            charId={(char) => char.module_characterization_id}
        />
    );
};

const mapStateToProps = (state: IAppState, { module }: IOwnProps) => ({
    characterizations: mod.selectors.allCharacterizations(state, module),
});

const mapDispatchToProps = bindActions({
    navigateTo: routerActions.navigateTo,
    updateModule: mod.api.save,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleCharViewControls);
