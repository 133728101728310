import { useContext, createContext } from 'react';

import { ReferrerType } from 'reports/analytics/ReferrerTypes';
import { Price } from 'reports/models/stripe/price';

type Context = {
    dialog?: string;
    interval?: string;
    message?: string;
    product?: string;
    price?: Price;
    referrer?: ReferrerType;
};

const BillingContext = createContext<Context>({});

const useBillingContext = () => useContext(BillingContext);

export { Context, BillingContext, useBillingContext };
