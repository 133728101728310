// Segment client wrapper
import { get } from 'lodash';

import * as events from 'reports/analytics/eventSchemas';

import * as usr from 'reports/models/user';
import { helioscopeConfig } from 'reports/angular-bridge';

export function getSegmentClient() {
    if (window['analytics']) {
        return window['analytics'];
    }
    return null;
}

export function track(event: string, properties: events.IEvent) {
    const client = getSegmentClient();
    if (client) {
        client.track(event, properties);
    }
}

export function identify(user: usr.User, traits?: object) {
    const client = getSegmentClient();
    if (client && user) {
        client.identify(String(user.user_id), traits != null ? traits : getUserBaseTraits(user));

        if (get(user, 'team_id', null) !== null) {
            client.group(String(get(user, 'team_id')));
        }
    }
}

export function getUserBaseTraits(user: usr.User) {
    return {
        first_name: get(user, 'first_name', null),
        last_name: get(user, 'last_name', null),
        // NOTE: Changing user_id to id because Segment recommends using their reserved `id` field for this
        // check this link for more info: https://segment.com/docs/connections/spec/identify/
        id: get(user, 'user_id', null),
        email: get(user, 'email', null),
        team: get(user, 'team_id', null),
        team_admin: get(user, 'team_admin'),
        helioscope_admin: get(user, 'is_admin'),
        subscription_status: user.team.is_on_custom_plan ? 'active' : get(user, 'subscription.status'),
        subscription_product: user.team.is_on_custom_plan ? 'custom' : get(user, 'subscription.product', null),
        subscription_version: get(user, 'subscription.version', null),
        subscription_length: user.team.is_on_custom_plan ? 'year' : get(user, 'subscription.plan_type', null),
        active: user.isCurrent(),
        consumption_enforced: get(user, 'team.should_enforce_consumption'),
        // Amplitude only tracks if using semantic versioning format major.minor[.patch]
        version: helioscopeConfig != null ? helioscopeConfig.version + '.0' : null,
        created: get(user, 'created', null),
        fraud: get(user, 'flagged', null),
    };
}

export interface ITrackFields {
    event: string;
    properties?: events.IEvent;
}

export interface IIdentifyFields {
    userId: string;
    traits?: object;
}

export const schemas = {
    track: ['event', 'properties'],
    identify: ['userId', 'traits'],
};

export enum EventType {
    TRACK = 'track',
    IDENTIFY = 'identify',
}
