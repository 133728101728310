import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { bindActions } from 'reports/utils/redux';
import * as analytics from 'reports/analytics';

import { fromNow } from 'reports/utils/formatters';
import { PreviewDrawer } from 'reports/components/library/main/components';
import { LibraryPreview } from 'reports/components/core/layout';
import { EditButton, CopyButton, FavoriteStar } from 'reports/components/core/controls';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import * as auth from 'reports/modules/auth';
import * as pro from 'reports/models/profile';
import { Section2, ProfilesParameter } from './common';
import MechanicalPreviewContents from 'reports/modules/profile/components/MechanicalPreviewContents';
import ElectricalPreviewContents from 'reports/modules/profile/components/ElectricalPreviewContents';
import ConditionsPreviewContents from 'reports/modules/profile/components/ConditionsPreviewContents';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { createUniqueDescription } from 'reports/utils/helpers';
import ProjectProfileContents from 'reports/modules/profile/components/ProjectPreviewContents';
import FinancialPreviewContents from 'reports/modules/profile/components/FinancialPreviewContents';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ContentContainer = styled.div`
    // Add 100px of bottom padding so that the Chat doesn't block content
    padding: 8px 12px 100px;
`;

interface IOwnProps {
    profile: pro.Profile;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

type IProps = IOwnProps & IDispatchProps & IStateProps;

interface IState {
    drawerOpen: boolean;
}

const ProfileOverviewContents: React.FC<IOwnProps> = ({ profile }) => (
    <Section2 title="Overview">
        <ProfilesParameter label="Name">{profile.name}</ProfilesParameter>
        {profile.creator && <ProfilesParameter label="Owner">{profile.creator.full_name}</ProfilesParameter>}
        {profile.description && <ProfilesParameter label="Description">{profile.description}</ProfilesParameter>}
    </Section2>
);

class ProfilePreview extends React.PureComponent<IProps, IState> {
    state: IState = {
        drawerOpen: true,
    };

    async duplicateProfile() {
        await addPromiseToasts(
            (async () => {
                const { createProfile, getProfiles, navigateTo, profile } = this.props;

                const matchingProfiles = await getProfiles({ q: profile.name });
                const newProfile = await createProfile({
                    ...profile,
                    name: createUniqueDescription(
                        profile.name,
                        matchingProfiles.map((p) => p.name),
                    ),
                    public: false,
                });

                navigateTo('app.profiles.profile.edit', {
                    profileId: newProfile.profile_id,
                });
            })(),
            {
                initial: 'Duplicating profile...',
                onSuccess: 'Successfully duplicated profile.',
                onCatch: 'Error duplicating profile.',
            },
        );
    }

    render() {
        const { setBookmarked, profile, navigateTo, navigateToEditor, user } = this.props;
        const lastModified = profile.last_modified || profile.created;
        let editButton;

        if (user.team_id === profile.team_id) {
            editButton = (
                <EditButton
                    onClick={() => {
                        analytics.track('profile.edit', {
                            profile_id: profile.profile_id,
                            profile_type: profile.type,
                        });
                        navigateToEditor(profile.profile_id);
                    }}
                />
            );
        }

        return (
            <PreviewDrawer
                isOpen={this.state.drawerOpen}
                onClose={() => this.setState({ drawerOpen: false })}
                onClosed={() => navigateTo('app.profiles')}
                title={
                    <LibraryPreview.Header
                        title={profile.name}
                        subtitle={lastModified && `Last modified ${fromNow(lastModified)}`}
                        favorite={
                            <FavoriteStar
                                empty={!profile.bookmarked()}
                                onClick={() => {
                                    addPromiseToastsToggle(
                                        'favorite',
                                        setBookmarked(profile, !profile.bookmarked()),
                                        'financial model',
                                        profile.name,
                                        profile.bookmarked(),
                                    );
                                }}
                            />
                        }
                    />
                }
                editButton={editButton}
                duplicateButton={<CopyButton text="Duplicate" onClick={() => this.duplicateProfile()} />}
            >
                <ContentContainer>
                    <ProfileOverviewContents profile={profile} />
                    {profile instanceof pro.MechanicalProfile && <MechanicalPreviewContents profile={profile} />}
                    {profile instanceof pro.ElectricalProfile && <ElectricalPreviewContents profile={profile} />}
                    {profile instanceof pro.ConditionsProfile && <ConditionsPreviewContents profile={profile} />}
                    {profile instanceof pro.ProjectProfile && <ProjectProfileContents profile={profile} />}
                    {profile instanceof pro.FinancialProfile && <FinancialPreviewContents profile={profile} />}
                </ContentContainer>
            </PreviewDrawer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    createProfile: pro.api.create,
    getProfiles: pro.api.index,
    navigateTo: routerActions.navigateTo,
    navigateToEditor: (profileId) => routerActions.navigateTo('app.profiles.profile.edit', { profileId }),
    setBookmarked: (profile, bookmarked) => {
        return pro.api.patchTeamSettings({
            bookmarked,
            profile_id: profile.profile_id,
        });
    },
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePreview);
