import { range, reduce } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import { Callout, Icon, Intent, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { IAppState } from 'reports/types';

import { Flex } from 'reports/components/core/containers';
import { Link } from 'reports/components/core/controls';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';
import BasicTable from 'reports/components/core/tables/BasicTable';
import { FormattedNumber } from 'reports/components/core/numbers';
import { Warning } from 'reports/components/helpers/errors';

import * as projFinTemp from 'reports/models/project_financial_template';
import { selectors, hasOutput, FIN_STATUSES, IFinancialOutput } from 'reports/modules/financials/state';
import { DebugOutput, OutputCategory, CATEGORY_LIST } from 'reports/modules/financials/model/debug';

import Colors from 'reports/styles/colors';
import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const Cell = styled.td<{ align?: 'left' | 'center' | 'right' }>`
    && {
        text-align: ${({ align }) => align || 'left'};
    }
`;

const HeaderCell = styled.th`
    && {
        text-align: center;
        vertical-align: middle;
    }
    &.category {
        background: ${Colors.BG_LIGHT_GRAY};
    }
`;

const StatusContainer = styled(Flex.Container)`
    flex: 1;
    font-style: italic;
`;

const SubContentBox = styled(Flex.ContainerV).attrs({
    className: 'sub-content-box-1',
})`
    min-height: 54px;
`;

const MonthRow = styled.tr`
    && {
        background: rgba(191, 204, 214, 0.45);
    }
    td {
        cursor: default !important;
    }
`;

interface IOwnProps {
    config: projFinTemp.ProjectFinancialTemplate;
}

interface IState {
    filters: Filters;
    refRows: number[];
    isYearMonthsVisible: boolean[];
}

type Filters = { [k in OutputCategory]?: boolean };
type IStateProps = ReturnType<typeof mapStateToProps>;

class TimeSeriesOutputTable extends React.PureComponent<IOwnProps & IStateProps, IState> {
    state: IState = {
        filters: reduce(
            OutputCategory,
            (filters, category: OutputCategory) => {
                filters[category] = true;
                return filters;
            },
            {},
        ),
        refRows: [],
        isYearMonthsVisible: [],
    };

    componentDidMount() {
        const { configOutput } = this.props;
        if (hasOutput(configOutput)) {
            this.setRefRows(configOutput);
            this.setIsYearMonthsVisible(configOutput);
        }
    }

    componentDidUpdate(prevProps) {
        const { configOutput } = this.props;
        if (
            hasOutput(configOutput) &&
            (configOutput !== prevProps.configOutput || this.state.refRows.length !== configOutput.systemLifeYears + 1)
        ) {
            this.setRefRows(configOutput);
            this.setIsYearMonthsVisible(configOutput);
        }
    }

    setRefRows = (configOutput: IFinancialOutput) => {
        this.setState({ refRows: range(configOutput.systemLifeYears + 1) });
    }; // incl. initial

    setIsYearMonthsVisible = (configOutput: IFinancialOutput) => {
        this.setState({ isYearMonthsVisible: Array.from({ length: configOutput.systemLifeYears + 1 }, () => false) });
    };

    render() {
        const { config, configOutput, debugOutput } = this.props;
        const { filters, refRows, isYearMonthsVisible } = this.state;

        const modelLoading = !hasOutput(configOutput) && configOutput.status === FIN_STATUSES.refreshing;
        const modelRunning =
            !hasOutput(configOutput) && (configOutput.status === FIN_STATUSES.computing || modelLoading);
        const modelError =
            !hasOutput(configOutput) &&
            (configOutput.status === FIN_STATUSES.calculationError ||
                configOutput.status === FIN_STATUSES.permissionsError);
        const hasCustomStepNames = debugOutput && debugOutput.some((item) => item.output.userLabel);

        const status = (
            <StatusContainer align={Flex.Align.CENTER} alignV={Flex.AlignV.CENTER}>
                {modelRunning ? (
                    <>
                        <Spinner size={Spinner.SIZE_SMALL} />
                        <div style={{ paddingLeft: 6 }}>
                            {`${modelLoading ? 'Loading' : 'Calculating'} financial outputs...`}
                        </div>
                    </>
                ) : (
                    <Warning msg="Error with this financial model or configuration" />
                )}
            </StatusContainer>
        );

        const systemStartMonth = hasOutput(configOutput) ? configOutput.systemStartMonth : null;
        const systemStartYear = hasOutput(configOutput) ? configOutput.systemStartYear : null;

        return (
            <SubContentBox>
                {modelRunning || modelError
                    ? status
                    : debugOutput != null &&
                      debugOutput.length > 0 && (
                          <>
                              <Flex.Container align={Flex.Align.RIGHT}>
                                  {systemStartMonth && systemStartYear && (
                                      <Callout icon={IconNames.INFO_SIGN} style={{ marginRight: 10 }}>
                                          Click the row of a year to show its monthly values. This system is configured
                                          to start in{' '}
                                          {moment({ year: systemStartYear, month: systemStartMonth }).format(
                                              'MMMM YYYY',
                                          )}{' '}
                                          which corresponds to month row 1/1. To change the start month of your project,
                                          you can change the <b>install time</b> in this project's{' '}
                                          <Link
                                              routeName="app.financial-templates.financial-template.preview"
                                              routeParams={{ finTemplateId: config.financial_template_id }}
                                          >
                                              <a>financial model</a>
                                          </Link>{' '}
                                          (
                                          <a
                                              href="https://help-center.helioscope.com/hc/en-us/articles/8547381886227-Step-by-Step-Guide-Creating-a-Financial-Model"
                                              target="_blank"
                                          >
                                              help article
                                          </a>
                                          ).
                                      </Callout>
                                  )}
                                  <StaticSelect
                                      buttonText="Filter By Category"
                                      items={CATEGORY_LIST}
                                      itemRenderer={(item, { handleClick, modifiers }) => (
                                          <DropdownMenuItem
                                              key={item}
                                              title={item}
                                              rightButton={
                                                  filters[item] && !modifiers.disabled ? (
                                                      <Icon icon={IconNames.TICK} intent={Intent.PRIMARY} />
                                                  ) : undefined
                                              }
                                              onClick={handleClick}
                                              {...modifiers}
                                          />
                                      )}
                                      itemDisabled={this.categoryEmpty}
                                      onItemSelect={(item) =>
                                          this.setState({
                                              filters: {
                                                  ...filters,
                                                  [item]: !filters[item],
                                              },
                                          })
                                      }
                                  />
                              </Flex.Container>
                              <Flex.Main style={{ overflowX: 'auto', paddingTop: 12 }}>
                                  <BasicTable tableTheme="dense">
                                      <thead>
                                          <tr>
                                              <HeaderCell key="category-year" />
                                              {debugOutput.map((output, tIdx) =>
                                                  this.renderCategoryHeader(output, tIdx),
                                              )}
                                          </tr>
                                          <tr>
                                              <HeaderCell key="column-year">{!hasCustomStepNames && 'Year'}</HeaderCell>
                                              {debugOutput.map((output, tIdx) => this.renderColHeader(output, tIdx))}
                                          </tr>
                                          {hasCustomStepNames && (
                                              <tr>
                                                  <HeaderCell key="year">Year</HeaderCell>
                                                  {debugOutput.map(
                                                      ({ category, output }: DebugOutput, tIdx: number) => {
                                                          return (
                                                              this.showCategory(category) && (
                                                                  <HeaderCell key={`step-${tIdx}`}>
                                                                      {output.userLabel}
                                                                  </HeaderCell>
                                                              )
                                                          );
                                                      },
                                                  )}
                                              </tr>
                                          )}
                                      </thead>

                                      <tbody>
                                          {refRows.map((_, rIdx) => (
                                              <>
                                                  <tr
                                                      key={`row-${rIdx}`}
                                                      onClick={() => {
                                                          const arr = isYearMonthsVisible.slice();
                                                          arr[rIdx] = !arr[rIdx];
                                                          this.setState({ isYearMonthsVisible: arr });
                                                      }}
                                                  >
                                                      <Cell key={`cell-year-${rIdx}`} align="center">
                                                          {rIdx}
                                                      </Cell>
                                                      {debugOutput.map((output, tIdx) =>
                                                          this.renderCell(output, tIdx, rIdx),
                                                      )}
                                                  </tr>
                                                  {rIdx !== 0 &&
                                                      isYearMonthsVisible[rIdx] &&
                                                      this.renderMonthRows(rIdx, debugOutput)}
                                              </>
                                          ))}
                                      </tbody>
                                  </BasicTable>
                              </Flex.Main>
                          </>
                      )}
            </SubContentBox>
        );
    }

    renderCategoryHeader = ({ category }: DebugOutput, tIdx: number) => {
        return (
            this.showCategory(category) && (
                <HeaderCell key={`category-${tIdx}`} className="category">
                    {category}
                </HeaderCell>
            )
        );
    };

    renderColHeader = ({ category, output }: DebugOutput, tIdx: number) => {
        return this.showCategory(category) && <HeaderCell key={`col-${tIdx}`}>{output.title}</HeaderCell>;
    };

    renderCell = ({ category, output }: DebugOutput, tIdx: number, rIdx: number) => {
        return (
            this.showCategory(category) && (
                <Cell key={`cell-${tIdx}-${rIdx}`} align="right">
                    <FormattedNumber value={output.values[rIdx]} unit={output.unit} />
                </Cell>
            )
        );
    };

    renderMonthlyCell = ({ category, output }: DebugOutput, colIdx: number, monthIdx: number) =>
        this.showCategory(category) && (
            <Cell key={`cell-${colIdx}-${monthIdx}`} align="right">
                <FormattedNumber value={output.valuesMonthly[monthIdx]} unit={output.unit} />
            </Cell>
        );

    renderMonthRows = (yearIdx: number, debugOutput: DebugOutput[]) => {
        const rows: React.ReactElement[] = [];
        for (let month = 1; month <= 12; month += 1) {
            const monthIdx = (yearIdx - 1) * 12 + month;
            rows.push(
                <MonthRow>
                    <Cell key={`cell-month-${yearIdx}-${monthIdx}`} align="center">
                        {yearIdx}/{month}
                    </Cell>
                    {debugOutput.map((output, colIdx) => this.renderMonthlyCell(output, colIdx, monthIdx))}
                </MonthRow>,
            );
        }
        return rows;
    };

    categoryEmpty = (category: OutputCategory) => {
        const { debugOutput } = this.props;
        return !(debugOutput && debugOutput.find((i) => i.category === category));
    };

    showCategory = (category: OutputCategory) => this.state.filters[category];
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
    const { finConfigOutput, finDebugOutput } = selectors;
    return {
        debugOutput: finDebugOutput(state, ownProps),
        configOutput: finConfigOutput(state, ownProps),
    };
};

export default connect(mapStateToProps)(TimeSeriesOutputTable);
