import { BBox } from 'geojson';
import GoogleMapReact from 'google-map-react';
import { isEqual } from 'lodash';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';

import * as analytics from 'reports/analytics';
import * as config from 'reports/config';
import * as ws from 'reports/models/weather_source';
import { ProspectorBounds } from 'reports/static/map_bounds/prospector';
import { PsmBounds } from 'reports/static/map_bounds/psm';
import { IAppState } from 'reports/types';
import { GCoord, GLatLngLiteral } from 'reports/utils/maps/geocode';

import ClusterGoogleMapReact from './ClusterGoogleMapReact';
import WeatherSourceInfo from './WeatherSourceInfo';
import WeatherSourceMarker from './WeatherSourceMarker';

import * as styles from 'reports/styles/styled-components';
import { getFakeSatelliteSource } from '../utils';
const styled = styles.styled;

// Hack to not show the info window (tooltip) close button, since it's hoverable
const GoogleMapContainer = styled.div<{ showCloseButton: boolean; padCloseButton?: boolean }>`
    .gm-style-iw {
        /* !important is necessary to override element styles defined by google maps */
        padding: 12px !important;
        > button {
            ${(props) => (!props.showCloseButton ? 'display: none !important;' : '')}
            ${(props) =>
                props.padCloseButton
                    ? `
                margin-right: 8px !important;
                margin-top: 8px !important;
            `
                    : ''}
        }
        > .gm-style-iw-d {
            overflow: auto !important;
        }
    }
    .gm-style iframe + div {
        border: none !important;
    }
`;

const PROJECT_LOCATION_MARKER_ID = 'project_location';
const FLAG_ICON = require('reports/static/flag.svg');
const GROUND_SOURCE_INFO_WINDOW_OFFSET = -17;
const SATELLITE_SOURCE_INFO_WINDOW_OFFSET = -24;
export const toGLatLng = (loc) => ({ lat: loc.latitude, lng: loc.longitude });
export enum SatelliteSourceType {
    Prospector = 'prospector',
    Psm = 'psm',
}

interface IMapConfig {
    initialCenter: GLatLngLiteral;
    initialZoom: number;
}

interface IMapProps {
    disabled?: boolean;
    mapConfig?: IMapConfig;
    projectLocation?: GCoord;
    weatherSources: ws.WeatherSource[];
    onSelectSource?: (sourceId: number) => void;
    onMouseOverSource?: (sourceId?: number) => void;
    selectedSourceId?: number;
    enableClustering?: boolean;
    satelliteSourceToOutline?: SatelliteSourceType;
    infoWindowTrigger: 'hover' | 'click';
    infoWindowDetailed?: boolean;
}

interface IState {
    mapConfig: IMapConfig;
    ready: boolean;
    bounds?: BBox;
    zoom: number;
    infoWindowSourceId?: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

class WeatherSourceMap extends React.PureComponent<IMapProps & IStateProps, IState> {
    googleMaps;
    mapRef;
    infoWindow;
    markers = {};

    constructor(props) {
        super(props);

        this.state = {
            mapConfig: {
                ...props.mapConfig,
            },
            ready: false,
            bounds: undefined,
            zoom: props.mapConfig.initialZoom,
        };
    }

    getMapOptions = (gMaps) => {
        return {
            controlSize: 24,
            mapTypeId: gMaps.MapTypeId.ROADMAP,

            mapTypeControl: true,
            // remove terrain checkbox
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
            },
            disableDoubleClickZoom: false,
            fullscreenControl: false,
            rotateControl: false,
            streetViewControl: false,
            clickableIcons: false,
        };
    };

    componentDidUpdate(prevProps) {
        const { selectedSourceId, weatherSources, satelliteSourceToOutline } = this.props;

        if (prevProps.satelliteSourceToOutline !== satelliteSourceToOutline) {
            this.closeInfoWindow();
            this.clearFlagMarker();
        }

        if (
            !isEqual(prevProps.weatherSources, weatherSources) ||
            prevProps.satelliteSourceToOutline !== satelliteSourceToOutline
        ) {
            this.clearMarkers();
            this.renderMarkers();
        }

        if (prevProps.selectedSourceId !== selectedSourceId) {
            this.renderMarkers();
        }
    }

    render() {
        const { initialCenter, initialZoom } = this.state.mapConfig;

        const mapStyle: React.CSSProperties = {
            ...(this.props.disabled ? { pointerEvents: 'none' } : {}),
            transition: 'opacity 0.3s ease-in-out',
            opacity: this.state.ready ? (this.props.disabled ? 0.5 : 1) : 0,
        };

        const GoogleMapReactProps = {
            bootstrapURLKeys: { key: this.props.googleMapsKey },
            options: this.getMapOptions,
            defaultCenter: initialCenter,
            defaultZoom: initialZoom,
            center: initialCenter,
            yesIWantToUseGoogleMapApiInternals: true,
            onGoogleApiLoaded: ({ map, maps }) => this._onMapLoaded(map, maps),
            style: mapStyle as any,
            onChange: ({ zoom, bounds }) => {
                this.setState({
                    zoom,
                    bounds: [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat],
                });
            },
        };

        return (
            <GoogleMapContainer
                showCloseButton={this.props.infoWindowTrigger === 'click' || !!this.props.satelliteSourceToOutline}
                padCloseButton={this.props.infoWindowDetailed}
            >
                {this.props.enableClustering ? (
                    <ClusterGoogleMapReact
                        {...GoogleMapReactProps}
                        weatherSources={this.props.weatherSources}
                        selectedSourceId={this.props.selectedSourceId}
                        zoom={this.state.zoom}
                        bounds={this.state.bounds}
                        onSelectSource={this.onSelectSource}
                        onMouseOutSource={this.onMouseOutSource}
                        onMouseOverSource={this.onMouseOverSource}
                        satelliteRegion={this.props.satelliteSourceToOutline}
                        closeInfoWindow={this.closeInfoWindow}
                    />
                ) : (
                    <GoogleMapReact {...GoogleMapReactProps}>
                        {!this.props.satelliteSourceToOutline &&
                            this.props.weatherSources.map((src) => (
                                <WeatherSourceMarker
                                    key={src.weather_source_id}
                                    lat={toGLatLng(src.location)['lat']}
                                    lng={toGLatLng(src.location)['lng']}
                                    isSourceSelected={this.isSourceSelected(src)}
                                    onClick={() => this.onSelectSource}
                                    onMouseOver={() => this.onMouseOverSource(src, toGLatLng(src.location))}
                                    onMouseOut={this.onMouseOutSource}
                                    closeInfoWindow={this.closeInfoWindow}
                                    srcId={src.weather_source_id}
                                />
                            ))}
                    </GoogleMapReact>
                )}
            </GoogleMapContainer>
        );
    }

    _onMapLoaded = (map, gMaps) => {
        this.googleMaps = gMaps;
        this.mapRef = map;
        this.infoWindow = new gMaps.InfoWindow({
            disableAutoPan: true,
            pixelOffset: new gMaps.Size(0, GROUND_SOURCE_INFO_WINDOW_OFFSET),
        });

        map.setTilt(0);
        this.renderMarkers();
        this.setState({ ready: true });
    };

    onMouseOverSource = (source, position) => {
        this.props.onMouseOverSource && this.props.onMouseOverSource(source.weather_source_id);

        if (this.props.infoWindowTrigger === 'hover') {
            this.openInfoWindow(source, position, GROUND_SOURCE_INFO_WINDOW_OFFSET);
        }
    };

    onSelectSource = (source, position) => {
        this.props.onSelectSource && this.props.onSelectSource(source.weather_source_id);

        if (this.props.infoWindowTrigger === 'click') {
            this.openInfoWindow(source, position, GROUND_SOURCE_INFO_WINDOW_OFFSET);
        }
    };

    onMouseOutSource = () => {
        this.props.onMouseOverSource && this.props.onMouseOverSource();
        if (!this.infoWindow || this.props.infoWindowTrigger !== 'hover') {
            return;
        }
        this.closeInfoWindow();
    };

    isSourceSelected(source: ws.WeatherSource) {
        return source.weather_source_id === this.props.selectedSourceId;
    }

    renderMarkers() {
        if (!this.mapRef || !this.googleMaps) {
            return;
        }

        this.renderProjectMarker();
        this.renderSatelliteBorders();
    }

    renderProjectMarker() {
        if (!this.props.projectLocation) {
            return;
        }

        const marker = this.getOrCreateMarker(PROJECT_LOCATION_MARKER_ID);

        marker.setOptions({
            map: this.mapRef,
            position: this.props.projectLocation,
            zIndex: 0,
        });
    }

    renderSatelliteBorders() {
        if (!this.props.satelliteSourceToOutline) return;
        const boundsSet =
            this.props.satelliteSourceToOutline === SatelliteSourceType.Prospector ? ProspectorBounds : PsmBounds;
        // #TODO Don't use hardcoded colors, pending product design
        const boundsColor =
            this.props.satelliteSourceToOutline === SatelliteSourceType.Prospector ? '#FF0000' : '#0000FF';

        boundsSet.forEach((bounds, i) => {
            const markerKey = `${this.props.satelliteSourceToOutline}_${i}`;
            if (!this.markers[markerKey]) {
                this.markers[markerKey] = new this.googleMaps.Polygon();
            }
            this.markers[markerKey].setOptions({
                paths: bounds,
                strokeColor: boundsColor,
                strokeOpacity: 0.75,
                strokeWeight: 3,
                fillColor: boundsColor,
                fillOpacity: 0.1,
                clickable: true,
            });
            this.markers[markerKey].addListener('click', (e) => {
                if (!this.props.satelliteSourceToOutline) return;

                const fakeSource = getFakeSatelliteSource(this.props.satelliteSourceToOutline, {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                });
                const position = toGLatLng(fakeSource.location);
                this.openInfoWindow(fakeSource, position, SATELLITE_SOURCE_INFO_WINDOW_OFFSET);

                const flag = new this.googleMaps.Marker();
                flag.setOptions({
                    position,
                    icon: FLAG_ICON,
                    map: this.mapRef,
                    zIndex: 0,
                });
                this.markers['satellite_flag'] = flag;
            });
            this.markers[markerKey].setMap(this.mapRef);
        });
    }

    markerExists(markerId: string) {
        return !!this.markers[markerId];
    }

    getOrCreateMarker(markerId: string) {
        if (!this.markers[markerId]) {
            this.markers[markerId] = new this.googleMaps.Marker();
        }

        return this.markers[markerId];
    }

    clearMarkers() {
        Object.values(this.markers).forEach((marker: any) => marker.setMap(null));
        this.markers = {};
    }

    clearFlagMarker() {
        if (!this.markers['satellite_flag']) return;
        this.markers['satellite_flag'].setMap(null);
    }

    openInfoWindow(source: ws.WeatherSource, position, offset: number) {
        this.clearFlagMarker();

        const detailed = this.props.infoWindowDetailed;

        // googleMaps.infowindow does not accept virtual DOM nodes (ie. React.ReactNode) as content, so we
        // have to convert our react node to a real DOM node
        const staticElement = !detailed ? source.name : renderToStaticMarkup(<WeatherSourceInfo source={source} />);

        this.infoWindow.setOptions({
            content: staticElement,
            // offsets prevent the info window from directly overlapping on top of the source's marker
            pixelOffset: new this.googleMaps.Size(0, offset),
        });

        if (detailed) {
            this.infoWindow.addListener('closeclick', () => {
                this.clearFlagMarker();
            });
        }

        // since googleMaps.infowindow only accepts static content, we inject onclick js after the infowindow has loaded
        this.infoWindow.addListener('domready', () => {
            const downloadButton = document.getElementById('weather-download');
            if (!downloadButton) return;
            downloadButton.onclick = () => {
                analytics.track('weather.download', {
                    weather_source_id: source.weather_source_id,
                });
            };
        });

        const anchor = new this.googleMaps.MVCObject();
        anchor.setOptions({
            position,
        });

        this.infoWindow.open(this.mapRef, anchor);
        this.setState({
            infoWindowSourceId: source.weather_source_id,
        });
    }

    closeInfoWindow = (sourceId?: number) => {
        if (sourceId && sourceId !== this.state.infoWindowSourceId) return;
        if (!this.infoWindow) return;
        this.infoWindow.close(this.mapRef);
    };
}

const mapStateToProps = (state: IAppState) => ({
    googleMapsKey: config.selectors.getConfig(state)?.google_maps_api_key || '',
});

export default connect(mapStateToProps)(WeatherSourceMap);
