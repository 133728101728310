import * as React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { HelperText } from 'reports/modules/settings/common';
import Section2 from 'reports/components/core/containers/Section2';
import { fallback } from 'reports/utils/helpers';
import DataTable, { ColWidth } from 'reports/components/core/tables/DataTable';
import ScrollContainer from 'reports/components/core/tables/ScrollContainer';

import * as inv from 'reports/models/stripe/invoice';
import * as invV1 from 'reports/models/invoice_v1';
import * as tm from 'reports/models/team';
import * as usr from 'reports/models/user';
import * as sub from 'reports/models/subscription';

import { PusherChannel } from 'reports/modules/project/listeners';
import { getBillingUrl } from 'reports/utils/url';

import { Intent, Tag } from '@blueprintjs/core';

import { formatBillingCurrency } from '../';

type Props = {
    subscription?: sub.Subscription;
    team: tm.Team;
    user: usr.User;
    subChannel?: PusherChannel;
    manageBilling: boolean;
};

const statusIntent = (status) => {
    switch (status) {
        case 'draft':
            return Intent.NONE;
        case 'open':
            return Intent.PRIMARY;
        case 'paid':
            return Intent.SUCCESS;
        case 'canceled':
        case 'uncollectible':
            return Intent.DANGER;
        default:
            return Intent.NONE;
    }
};

const STRIPE_INVOICE_WINDOW_STYLING = 'width=485,height=730';

const TABLE_ROW_HEIGHT = 43;
const TABLE_MAX_HEIGHT = 20 * TABLE_ROW_HEIGHT - TABLE_ROW_HEIGHT / 2;

const OlderInvoicesText = styled.div`
    margin-top: 10px;
`;

interface InvoiceRow {
    number: string;
    created: moment.Moment;
    amount: number;
    paymentMethod: string;
    status: inv.InvoiceStatus;
    invoiceURL: string;
    migratedInvoice: boolean;
}

const InvoiceList = React.memo(
    ({ manageBilling, subscription, subChannel, user }: Pick<Props, Exclude<keyof Props, 'team'>>) => {
        if (!manageBilling) {
            return null;
        }

        const dispatch = useDispatch();
        const [invoiceRowsPromise, setInvoiceRowsPromise] = React.useState<Promise<InvoiceRow[]>>(Promise.resolve([]));

        const loadV2Invoices = (teamId, subscription) =>
            dispatch(inv.api.index({ team_id: teamId, subscription_external_id: subscription.external_id }));

        const loadV1Invoices = (teamId) => dispatch(invV1.api.index({ team_id: teamId }));

        const loadInvoices = async (subscription) => {
            const v1Invoices = await loadV1Invoices(user.team_id);
            const v2Invoices = await loadV2Invoices(user.team_id, subscription);

            const v2InvoiceRows: InvoiceRow[] = v2Invoices.map((invoice) => {
                return {
                    number: invoice.number,
                    created: invoice.created,
                    amount: invoice.total,
                    paymentMethod: invoice.charge?.payment_method_details?.card?.last4,
                    status: invoice.status,
                    invoiceURL: invoice.hosted_invoice_url,
                    migratedInvoice: false,
                };
            });
            const v1InvoiceRows: InvoiceRow[] = v1Invoices.map((invoice) => {
                return {
                    number: invoice.external_id,
                    created: invoice.created,
                    amount: invoice.total,
                    paymentMethod: invoice.last4,
                    status: invoice.closed && !invoice.paid ? 'canceled' : 'paid',
                    invoiceURL: invoice.invoice_url,
                    migratedInvoice: true,
                };
            });
            return v2InvoiceRows.concat(v1InvoiceRows);
        };

        React.useEffect(() => {
            (() => subscription && setInvoiceRowsPromise(loadInvoices(subscription)))();
            return () => {};
        }, [subscription?.status]);
        // Be careful not to change subscription?.status to subscription.
        // useEffect will loop endlessly.

        React.useEffect(() => {
            if (subChannel) {
                const setInvoiceRowsCallback = () => {
                    setInvoiceRowsPromise(loadInvoices(subscription));
                };
                subChannel.watch('invoice.paid', setInvoiceRowsCallback);
                subChannel.watch('invoice.created', setInvoiceRowsCallback);
            }
            return () => {};
        }, [subChannel]);

        return !subscription ? (
            <Section2 title="Billing History">
                <HelperText large>There are no invoices on your account right now</HelperText>
            </Section2>
        ) : (
            <Section2 title="Billing History">
                <>
                    <ScrollContainer height={TABLE_MAX_HEIGHT} fillHeight={false}>
                        <DataTable
                            items={invoiceRowsPromise}
                            width="100%"
                            centered={true}
                            hasScrollContainer={true}
                            sticky={true}
                            columns={[
                                {
                                    headerText: 'Invoice Number',
                                    colWidth: ColWidth.MEDIUM,
                                    renderCell: (invoice) => <>{invoice.number}</>,
                                },
                                {
                                    headerText: 'Date Created',
                                    colWidth: ColWidth.MEDIUM,
                                    renderCell: (invoice) => <>{invoice.created.format('ll')}</>,
                                },
                                {
                                    headerText: 'Amount',
                                    colWidth: ColWidth.SMALL,
                                    renderCell: (invoice) => <>{formatBillingCurrency(invoice.amount)}</>,
                                },
                                {
                                    headerText: 'Payment Method',
                                    colWidth: ColWidth.MEDIUM,
                                    renderCell: (invoice) => <>{fallback(invoice.paymentMethod, '-')}</>,
                                },
                                {
                                    headerText: 'Status',
                                    colWidth: ColWidth.MEDIUM,
                                    renderCell: (invoice) => (
                                        <Tag intent={statusIntent(invoice.status)} minimal={true}>
                                            {inv.InvoiceStatuses[invoice.status]}
                                        </Tag>
                                    ),
                                },
                                {
                                    headerText: '',
                                    colWidth: ColWidth.MEDIUM,
                                    renderCell: (invoice) => {
                                        if (invoice.invoiceURL) {
                                            return invoice.migratedInvoice ? (
                                                <a href={invoice.invoiceURL} target="_blank">
                                                    View Invoice
                                                </a>
                                            ) : (
                                                <a
                                                    onClick={async () => {
                                                        window.open(
                                                            invoice.invoiceURL,
                                                            'popup',
                                                            STRIPE_INVOICE_WINDOW_STYLING,
                                                        );
                                                    }}
                                                >
                                                    {invoice.status === 'open' ? 'Pay Invoice' : 'View Invoice'}
                                                </a>
                                            );
                                        }
                                        return <>Invoice not available</>;
                                    },
                                },
                            ]}
                        />
                    </ScrollContainer>
                    {user.has_inactive_v1_subscription && (
                        <OlderInvoicesText>
                            Older invoices can be found in HelioScope Classic,{' '}
                            <a href={getBillingUrl()}>click here to switch</a>
                        </OlderInvoicesText>
                    )}
                </>
            </Section2>
        );
    },
);

export default InvoiceList;
