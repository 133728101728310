import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { actions as routerActions } from 'redux-router5';

import { selectors as authSelectors } from 'reports/modules/auth';
import * as cfg from 'reports/config';
import { IAppState } from 'reports/types';

import { Button, Intent, Tag } from '@blueprintjs/core';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import { Flex } from 'reports/components/core/containers';
import Section2 from 'reports/components/core/containers/Section2';

import { ScheduledChanges, Subscription } from 'reports/models/subscription';
import { Team, api as teamApi } from 'reports/models/team';
import { User, api as userApi } from 'reports/models/user';

import { canManageBilling, canManageTeamResources } from 'reports/modules/auth/permissions';

import { styled } from 'reports/styles/styled-components';

import InviteMemberDialog from './InviteMemberDialog';

import TeamMemberList from './TeamMemberList';

import { promptRestartTrial } from './promptRestartTrial';
import InviteMemberDialogOld from 'reports/modules/settings/team/components/InviteMemberDialogOld';

interface Props {
    team: Team;
    subscription: Subscription;
    user: User;
}

const Section = styled(Section2)`
    margin-top: 30px;
    padding-bottom: 10;
`;

const Debug = ({ restartTrial }) => (
    <Section title="Trial Management" contextEl={<Tag intent={Intent.DANGER}>Internal testing only</Tag>}>
        <Button onClick={restartTrial}>Restart Trial</Button>
    </Section>
);

const InviteButton = styled(Button)`
    margin-bottom: 10px;
    float: right;
`;

const TeamManagement = React.memo(({ subscription, team, user }: Props) => {
    const [inviteDialogIsOpen, setInviteDialogIsOpen] = React.useState<boolean>(false);

    const authUser = useSelector((state) => authSelectors.getUser(state)!);
    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));

    const dispatch = useDispatch();

    const restartTrial = (user: User) => dispatch(userApi.restartTrial({ email: user.email }));
    const reloadTeam = (team: Team) => dispatch(teamApi.get({ team_id: team.team_id }));
    const goToBilling = () =>
        dispatch(
            routerActions.navigateTo('app.settings.team.billing', {
                dialog: 'licenses',
                message: 'You need to purchase a license before you can invite new team members to a seat.',
                referrer: ReferrerTypes.manage_team_page,
            }),
        );

    const seatsFilled = subscription && subscription.paid_seats <= subscription.used_seats;

    const canInviteMember =
        subscription &&
        subscription.isV2 &&
        subscription.is_active &&
        canManageBilling(authUser) &&
        (subscription.scheduled_changes === ScheduledChanges.cancel_at_period_end ? !seatsFilled : true);

    const hasPaymentFailure = subscription?.has_failed_payment;

    const onInviteClick = () => {
        if (seatsFilled) {
            return goToBilling();
        }
        return setInviteDialogIsOpen(true);
    };

    return (
        <>
            {user.hasFeature('set_password_flows') ? (
                <InviteMemberDialog
                    handleSubmit={() => undefined}
                    handleClose={() => setInviteDialogIsOpen(false)}
                    isOpen={inviteDialogIsOpen}
                    subscription={subscription}
                    team={team}
                />
            ) : (
                <InviteMemberDialogOld
                    handleSubmit={() => undefined}
                    handleClose={() => setInviteDialogIsOpen(false)}
                    isOpen={inviteDialogIsOpen}
                    subscription={subscription}
                    team={team}
                />
            )}
            <Flex.ContainerV>
                <Section2 title="Manage Team">
                    <InviteButton
                        disabled={
                            !canInviteMember ||
                            (hasPaymentFailure && seatsFilled) ||
                            (subscription.is_contract && seatsFilled)
                        }
                        onClick={onInviteClick}
                        intent={Intent.PRIMARY}
                        text="Invite Team Member"
                    />
                    <TeamMemberList
                        disabled={!canManageTeamResources(authUser, team)}
                        subscription={subscription}
                        team={team}
                    />
                    {config?.debug && (
                        <Debug restartTrial={() => promptRestartTrial(user, team, restartTrial, reloadTeam)} />
                    )}
                </Section2>
            </Flex.ContainerV>
        </>
    );
});

export default TeamManagement;
