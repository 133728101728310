import * as React from 'react';

import { RouteComponent } from 'reports/routing';

import ProfileLibrary from 'reports/modules/profile/components/ProfileLibrary';
import ProfilePreview from 'reports/modules/profile/components/ProfilePreview';
import ProfileEditor from 'reports/modules/profile/components/ProfileEditor';

const Library: React.FC = () => {
    const editRoute = 'app.profiles.profile.edit';
    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.profiles" exact={false} nameNot={editRoute}>
                    <ProfileLibrary
                        preview={
                            <RouteComponent name="app.profiles.profile.preview">
                                {({ profile }) => <ProfilePreview profile={profile} />}
                            </RouteComponent>
                        }
                    />
                </RouteComponent>
                <RouteComponent name={editRoute}>{({ profile }) => <ProfileEditor profile={profile} />}</RouteComponent>
            </div>
        </div>
    );
};

export default Library;
