import * as React from 'react';
import { State as RouteState } from 'router5';
import { actions as routerActions } from 'redux-router5';
import { connect } from 'react-redux';

import { bindActions } from 'reports/utils/redux';
import { AddProjectsDialog, IntervalPrices } from './AddProjectsDialog';
import * as price from 'reports/models/stripe/price';
import * as cfg from 'reports/config';
import { IAppState } from 'reports/types';
import * as auth from 'reports/modules/auth';

interface IOwnProps {
    route: RouteState;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IAddProjectDialogContainerProps = IOwnProps & IDispatchProps & IStateProps;

const AddProjectsDialogContainer = ({
    route,
    user,
    navigateTo,
    config,
    getPrices,
}: IAddProjectDialogContainerProps) => {
    const [isOpen, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [subscriptionIntervalPrices, setSubscriptionIntervalPrices] = React.useState<IntervalPrices>({});

    const subscription = user.subscription;
    const product = subscription ? config?.product_metadata[subscription?.product_key] : undefined;

    const loadPrices = React.useCallback(async () => {
        const prices = await getPrices({
            product_id: product?.v2_stripe_id,
        });

        const intervalPrices = prices.reduce<IntervalPrices>((acc, price) => {
            acc[price.recurring.interval] = price.unit_amount;
            return acc;
        }, {});

        setSubscriptionIntervalPrices(intervalPrices);
        setIsLoading(false);
    }, [subscription]);

    React.useEffect(() => {
        if (route && route?.params.dialog === 'add_projects') {
            setIsLoading(true);
            setOpen(true);
            loadPrices();
        } else {
            setOpen(false);
        }
    }, [route?.params.dialog]);

    const handleClose = React.useCallback(() => {
        const currentRouteName = route.name;
        const currentRouteParamsWithoutDialog = {
            ...route.params,
        };

        if (currentRouteParamsWithoutDialog.dialog) {
            delete currentRouteParamsWithoutDialog.dialog;
        }

        setOpen(false);
        navigateTo(currentRouteName, currentRouteParamsWithoutDialog);
    }, [route]);

    const licenseCount = subscription ? subscription.paid_seats : 0;
    const isAnnualSubscription = subscription?.plan_type === 'year';

    return (
        <AddProjectsDialog
            intervalPrices={subscriptionIntervalPrices}
            isOpen={isOpen}
            isAdmin={user.team_admin}
            isAnnualSubscription={isAnnualSubscription}
            isLoading={isLoading}
            licenseCount={licenseCount}
            product={product}
            onClose={handleClose}
        />
    );
};

const mapStateToProps = (state: IAppState) => ({
    config: cfg.selectors.getConfig(state),
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
    getPrices: price.api.index,
}));

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectsDialogContainer);
