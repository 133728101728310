import * as React from 'react';

import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { RouteComponent } from 'reports/routing';

import { RedirectHelperContainer } from 'reports/components/helpers/common';
import Toaster from 'reports/modules/Toaster';

import FinancialModelPreview from 'reports/modules/financials/components/financial_template/views/FinModelPreview';

import {
    FinancialTemplateEditor,
    FinancialTemplateLibrary,
} from 'reports/modules/financials/components/financial_template';

const NonexistentModelRedirectToMain: React.SFC = () => (
    <RedirectHelperContainer
        name="app.financial-templates"
        params={{}}
        callback={() => {
            Toaster.show({
                icon: IconNames.WARNING_SIGN,
                intent: Intent.WARNING,
                message: <div>Financial model does not exist</div>,
                timeout: 2500,
            });
        }}
    />
);

const Library: React.SFC = () => {
    const editRoute = 'app.financial-templates.financial-template.edit';
    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.financial-templates" exact={false} nameNot={editRoute}>
                    <FinancialTemplateLibrary
                        preview={
                            <RouteComponent name="app.financial-templates.financial-template.preview">
                                {({ template: model }) =>
                                    model ? <FinancialModelPreview model={model} /> : <NonexistentModelRedirectToMain />
                                }
                            </RouteComponent>
                        }
                    />
                </RouteComponent>
                <RouteComponent name={editRoute}>
                    {({ template }) =>
                        template ? <FinancialTemplateEditor template={template} /> : <NonexistentModelRedirectToMain />
                    }
                </RouteComponent>
            </div>
        </div>
    );
};

export default Library;
