import type { IAppState, IAppStore, IParams } from 'reports/types';
import type { IRouteConfig } from 'reports/utils/router';
import * as auth from 'reports/modules/auth';

export type IAppRoute<P extends IParams = any> = IRouteConfig<P, IAppState>;
export type IRouteBase<P extends IParams = any> = Omit<IAppRoute<P>, 'path' | 'name' | 'children'>;

export const unauthorizedRedirectHandler = (_router, dependencies: { store: IAppStore }) => (toState, _fromState) => {
    const { store } = dependencies;
    const user = auth.selectors.getUser(store.getState());
    // send the user to un-auth'd safe "app" route which currently forwards to app.projects so we have
    // clean state once they login.  Currently many of the paths have little or no protections against
    // a null user so things fail silently un-auth'd but fail visibly once auth'd without cleaning up the state
    if (!user) {
        return Promise.reject({
            redirect: {
                name: 'app',
                params: { postAuthDestinationName: toState.name, postAuthDestinationParams: toState.params },
            },
        });
    }

    return Promise.resolve(true);
};

export function makeRoute(
    name: string,
    path: string,
    options: IRouteBase = {},
    children?: IAppRoute<any>[],
    shouldRedirectUnauthorized: boolean = true,
): IAppRoute {
    const route: IAppRoute = { name, path, ...options };
    if (children) {
        route.children = children;
    }

    if (shouldRedirectUnauthorized && route.canActivate === undefined) {
        route.canActivate = unauthorizedRedirectHandler;
    }

    return route;
}
