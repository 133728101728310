import * as React from 'react';

import * as usr from 'reports/models/user';

import Section2 from 'reports/components/core/containers/Section2';

import UserPreferencesForm from 'reports/modules/settings/user/components/UserPreferencesForm';

interface Props {
    user: usr.User;
}

const UserPreferences = ({ user }: Props) => (
    <Section2 title="Preferences">
        <UserPreferencesForm user={user} />
    </Section2>
);

export default UserPreferences;
