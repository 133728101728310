import * as React from 'react';

import { Section2 } from 'reports/components/core/containers';

import ExternalIntegrationsList from './ExternalIntegrationsList';

const TeamIntegrations = () => (
    <Section2 title="Integrations">
        <ExternalIntegrationsList />
    </Section2>
);

export default TeamIntegrations;
