import * as React from 'react';

import { FormInput } from 'reports/components/forms';
import { Param } from 'reports/modules/financials/components/pipelineCommon';

import { ParamConfigType, IParamProps } from 'reports/modules/financials/params';
import INC_PARAMETERS from '../parameters';
import * as inc from 'reports/models/incentive';

interface IParamInputProps {
    path: string;
    param: ParamConfigType;
    disabled?: boolean;
}

const RemappedParam: React.SFC<IParamProps<any> & { onChange: () => void }> = ({ onChange, ...rest }) => (
    <Param updateFn={onChange} {...rest} />
);

const ParamInput: React.SFC<IParamInputProps> = ({ path, param, disabled }) => (
    <FormInput
        path={path}
        label={param.description}
        inputComponent={RemappedParam}
        inputProps={{
            parameter: param,
        }}
        disabled={disabled}
        inline={false}
    />
);

interface IFormFieldProps {
    disabled?: boolean;
}

const FixedIncentiveFields: React.SFC<IFormFieldProps> = ({ disabled }) => (
    <>
        <ParamInput path={'amount'} param={INC_PARAMETERS.fixed.amount} disabled={disabled} />
        <ParamInput path={'year'} param={INC_PARAMETERS.fixed.year} disabled={disabled} />
    </>
);

const SystemPriceIncentiveFields: React.SFC<IFormFieldProps> = ({ disabled }) => (
    <>
        <ParamInput path={'amount'} param={INC_PARAMETERS.system_price.amount} disabled={disabled} />
        <ParamInput
            path={'payment_schedule'}
            param={INC_PARAMETERS.system_price.payment_schedule}
            disabled={disabled}
        />
        <ParamInput path={'reduced_basis'} param={INC_PARAMETERS.system_price.reduced_basis} disabled={disabled} />
    </>
);

const NameplateIncentiveFields: React.SFC<IFormFieldProps> = ({ disabled }) => (
    <>
        <ParamInput path={'amount'} param={INC_PARAMETERS.nameplate.amount} disabled={disabled} />
        <ParamInput path={'payment_schedule'} param={INC_PARAMETERS.nameplate.payment_schedule} disabled={disabled} />
    </>
);

const ProductionIncentiveFields: React.SFC<IFormFieldProps> = ({ disabled }) => (
    <>
        <ParamInput path={'amount'} param={INC_PARAMETERS.production.amount} disabled={disabled} />
        <ParamInput path={'payout_years'} param={INC_PARAMETERS.production.payout_years} disabled={disabled} />
    </>
);

export const IncentiveConfigFields: React.SFC<{
    type: inc.IncentiveType;
    disabled: boolean;
}> = ({ type, disabled }) => {
    switch (type) {
        case 'fixed':
            return <FixedIncentiveFields disabled={disabled} />;
        case 'system_price':
            return <SystemPriceIncentiveFields disabled={disabled} />;
        case 'nameplate':
            return <NameplateIncentiveFields disabled={disabled} />;
        case 'production':
            return <ProductionIncentiveFields disabled={disabled} />;
    }

    throw new Error(`No incentive form for incentive type: ${type}`);
};

export default IncentiveConfigFields;
