/* tslint:disable:no-unused-variable */
import { every, isFunction, isString, isArray, some } from 'lodash';
import * as React from 'react';
import Logger from 'js-logger';

import { setStatePromise } from 'reports/utils/async_helpers';
import { mapValuesDeep, setIn } from 'reports/utils/helpers';

import { IDirtyFields, IFieldErrors, FormProvider, IFormContext } from './types';
import { IExceptionHandler, IFormAndFieldErrors, baseExceptionHandler } from './errors';

export interface IFormUpdateCallback<FormType = any, ReturnType = any> {
    (formContext: IFormContext<FormType, ReturnType>): any;
}

interface IOwnProps<FormType extends object = any, ReturnType = FormType> {
    // passing in true will prevent the form from resetting state after submission
    keepStateOnSubmit?: boolean;
    // should return an object if successful, and raise if it fails
    onSubmit: (values: FormType) => Promise<ReturnType> | ReturnType;
    onValidate?: (values: Partial<FormType>) => Promise<IFormAndFieldErrors<FormType> | undefined>;
    baseValue?: Partial<FormType>; // default/initial values for the object
    exceptionHandler?: IExceptionHandler<FormType>;
    formProps?: Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit'>; // optional props for the form element

    onInit?: IFormUpdateCallback<FormType, ReturnType>;
    onUpdate?: IFormUpdateCallback<FormType, ReturnType>;
}

type IFormProps<FormType extends object, ReturnType = FormType> = IOwnProps<FormType, ReturnType>;

interface IState<FormType> {
    changes: Partial<FormType>;
    fieldErrors: IFieldErrors<FormType>;
    formErrors: string[];
    dirtyFields: IDirtyFields<FormType>;
    submitting: boolean;
}

const logger = Logger.get('forms');

export class Form<FormType extends object = any, ReturnType = FormType> extends React.PureComponent<
    IFormProps<FormType, ReturnType>,
    IState<FormType>
> {
    state: IState<FormType> = {
        changes: {},
        fieldErrors: {},
        formErrors: [],
        dirtyFields: {},
        submitting: false,
    };

    providerValue: IFormContext<FormType, ReturnType>;

    componentDidMount() {
        this.props.onInit && this.props.onInit(this.providerValue);
    }

    componentDidUpdate() {
        this.props.onUpdate && this.props.onUpdate(this.providerValue);
    }

    render() {
        const { formProps } = this.props;
        this.providerValue = this.getProviderValue();

        return (
            <FormProvider value={this.providerValue}>
                <form onSubmit={this.handleSubmit} {...formProps}>
                    {this.props.children
                        ? isFunction(this.props.children)
                            ? this.props.children(this.providerValue)
                            : this.props.children
                        : null}
                </form>
            </FormProvider>
        );
    }

    getProviderValue = (state = this.state) => {
        const { dirtyFields, fieldErrors, formErrors, submitting } = state;

        return {
            submitting,
            dirtyFields,
            fieldErrors,
            formErrors,

            valid: this.isValid(),
            dirty: this.isDirty(),

            formData: this.formData(),
            submitForm: this.submitForm,
            clearForm: this.clearForm,
            updateValue: this.updateValue,
            updateValues: this.updateValues,
        };
    };

    formData = () => ({ ...this.props.baseValue, ...this.state.changes } as FormType);

    isDirty = () => some(mapValuesDeep(this.state.dirtyFields, (val) => val === true));

    isValid = () => {
        return (
            // TODO: will need to change how we handle form level errors for errors that
            // should or should not be able to be cleared
            this.state.formErrors &&
            this.state.formErrors.length === 0 &&
            every(mapValuesDeep(this.state.fieldErrors, (val) => val == null || val.length === 0))
        );
    };

    updateValue = (path: string, value: any, makeDirty: boolean | string | string[] = true) => {
        this.setState((prevState) => {
            let dirtyFields;
            if (makeDirty === true) {
                dirtyFields = setIn(path, true, prevState.dirtyFields);
            } else if (isString(makeDirty)) {
                dirtyFields = setIn(makeDirty, true, prevState.dirtyFields) as any;
            } else if (isArray(makeDirty)) {
                dirtyFields = prevState.dirtyFields;
                makeDirty.forEach((propertyPath) => {
                    dirtyFields = setIn(propertyPath, true, dirtyFields);
                });
            } else {
                dirtyFields = prevState.dirtyFields;
            }

            const changes = setIn(path, value, prevState.changes) as FormType;

            const fieldErrors = makeDirty
                ? {
                      ...prevState.fieldErrors,
                      [path]: undefined,
                  }
                : prevState.fieldErrors;

            return {
                changes,
                fieldErrors,
                dirtyFields,
                formErrors: [], // TODO: not precisely the right behavior, but gives people an escape valve
            };
        });
    };

    // This could be implemented in the future if the need arises, but currently we only support
    // updateValues within NestedFields, since that's where it's currently needed.
    updateValues = (partialObject: object, markDirty?: boolean) => {
        this.setState((prevState) => {
            let dirtyFields;
            let fieldErrors;

            dirtyFields = prevState.dirtyFields;
            fieldErrors = { ...prevState.fieldErrors };
            const changes = {};
            for (const path in partialObject) {
                dirtyFields = setIn(path, true, dirtyFields);
                if (markDirty) {
                    fieldErrors = {
                        ...fieldErrors,
                        [path]: undefined,
                    };
                }
                changes[path] = partialObject[path];
            }

            return {
                changes,
                fieldErrors,
                dirtyFields,
                formErrors: [], // TODO: not precisely the right behavior, but gives people an escape valve
            };
        });
    };

    handleSubmit = (evt?: React.SyntheticEvent) => {
        if (evt != null) {
            evt.preventDefault();
        }

        this.submitForm();
    };

    clearForm = async () => {
        await setStatePromise(this, {
            changes: {},
            fieldErrors: {},
            formErrors: [],
            dirtyFields: {},
        });
    };

    submitForm = async () => {
        const { onValidate } = this.props;
        const clearFormChanges: boolean = !this.props.keepStateOnSubmit;

        this.setState({ submitting: true });

        // This allows us to do custom validation at both the form and field level
        // instead of having to use a combination of an exception from onSubmit
        // and the exceptionHandler
        if (onValidate) {
            const validationErrors = await onValidate(this.state.changes);
            if (validationErrors) {
                this.setState({ submitting: false, ...validationErrors });
                return Promise.reject(validationErrors);
            }
        }

        if (!this.isValid()) {
            logger.warn('Form is invalid', this.state.fieldErrors);
            this.setState({ submitting: false });
            return Promise.reject(this.state.fieldErrors);
        }

        try {
            const rtn = await this.props.onSubmit(this.formData());
            clearFormChanges && (await this.clearForm());
            return rtn;
        } catch (exc) {
            logger.warn('Error submitting form', exc);
            if (exc === 'Cancelled' || exc === 'cancelled') {
                // If form submit cancelled from promptModalBoolean, let user try again.
                // The same applies for stripe specific form exits resulting in exc == 'cancelled'.
                this.setState({ submitting: false });
            } else {
                const { exceptionHandler = baseExceptionHandler } = this.props;
                const { formErrors, fieldErrors } = exceptionHandler(exc);
                this.setState({ formErrors, fieldErrors });
            }

            return Promise.reject(this.state.fieldErrors);
        } finally {
            this.setState({ submitting: false });
        }
    };
}

export default Form;
