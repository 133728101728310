import * as React from 'react';
import { connect } from 'react-redux';

import { Form, IFormUpdateCallback } from 'reports/components/forms';
import { FormTextInput } from 'reports/components/forms/inputs/experimental';
import { FormErrorCallout } from 'reports/components/helpers/errors';
import * as mod from 'reports/models/module';
import { INewModuleForm } from 'reports/modules/module/module_upload';
import { bindActions } from 'reports/utils/redux';

interface INewModuleProps {
    moduleName: string;
    moduleManufacturer: string;
    moduleTeamId: number;
    similarModules: mod.MatchedModule[];
    onUpdate: IFormUpdateCallback<INewModuleForm, INewModuleForm>;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = INewModuleProps & IDispatchProps;

class NewModuleForm extends React.Component<IProps> {
    render() {
        let perfectMatch;
        const { similarModules } = this.props;
        if (similarModules.length) {
            perfectMatch = similarModules[0]._match_quality === 1 ? similarModules[0] : null;
        }
        return (
            <Form
                onSubmit={this.onSubmit}
                onUpdate={this.props.onUpdate}
                exceptionHandler={this.handleRequestException}
                baseValue={{
                    newModuleName: perfectMatch ? '' : this.props.moduleName,
                }}
            >
                {({ formErrors }) => {
                    return (
                        <>
                            {formErrors.length > 0 && <FormErrorCallout errorMsg={formErrors[0]} />}
                            <p>
                                Create a new module based on the uploaded characterization.&nbsp;
                                {perfectMatch && <b>{`Module ${perfectMatch.name} already exists. `}</b>}
                                Please enter a new name for the module.
                            </p>
                            <FormTextInput
                                inline
                                bold
                                fill
                                label="New Module Name"
                                placeholder="Module name"
                                path="newModuleName"
                                type="text"
                            />
                        </>
                    );
                }}
            </Form>
        );
    }

    onSubmit = async (formData: INewModuleForm) => {
        // Check if the supplied name exists in module library already.
        const { exists } = await this.props.moduleMatchExists({
            module_name: formData.newModuleName,
            module_manufacturer: this.props.moduleManufacturer,
            module_team_id: this.props.moduleTeamId,
        });
        if (exists) {
            throw new Error(
                `A module with the name ${formData.newModuleName} already exists, please enter a different name.`,
            );
        }
        if (!formData.newModuleName) {
            throw new Error('Module name required - please enter a valid name.');
        }
        return formData;
    };

    handleRequestException = (exc) => {
        return {
            fieldErrors: {},
            formErrors: [exc.message],
        };
    };
}

const mapDispatchToProps = bindActions(() => ({
    moduleMatchExists: mod.api.moduleMatchExists,
}));

export default connect(null, mapDispatchToProps)(NewModuleForm);
