import { chain } from 'lodash';
import * as React from 'react';

import { promptModalBoolean } from 'reports/components/dialog';
import * as teams from 'reports/models/team';
import * as usr from 'reports/models/user';
import { TeamWarning } from 'reports/modules/settings/team/components/TeamWarning';
import { addPromiseToasts } from 'reports/modules/Toaster';

const willUserSubCancel = (user: usr.User, team: teams.Team) => team.is_on_custom_plan && user.subscription_external_id;

export const promptMergeTeams = async ({ mergeTeam, dstTeam, srcTeam, forceAllowMultiSub }) => {
    const usersToMerge = srcTeam.users;
    const externalIdsToCancel = usersToMerge
        .filter((user) => willUserSubCancel(user, dstTeam))
        .map((user) => user.subscription_external_id);
    const uniqueExternalIdsToCancel = [...new Set(externalIdsToCancel)];
    const getSubExtId = (user) => user.subscription_external_id;
    const uniqueExistingExternalIds = chain(dstTeam.users).map(getSubExtId).filter().uniq().value();
    const uniqueExternalIdsToMerge = chain(usersToMerge).map(getSubExtId).filter().uniq().value();
    const srcTeamName = srcTeam.name;
    const dstTeamName = dstTeam.name;
    const shouldSave = await promptModalBoolean({
        title: `Merge teams`,
        prompt: (
            <>
                Are you sure you want to merge team <b>{srcTeamName}</b> into team <b>{dstTeamName}</b>?
                <TeamWarning inline={true} warningStyle={{ marginTop: '6px', height: '20px' }}>
                    <ul>
                        <li>All users will be transferred</li>
                        <li>All team assets will be transferred</li>
                        <li>All current team settings for {srcTeamName} will be lost</li>
                        {uniqueExternalIdsToCancel.length > 0 && (
                            <li>
                                <p>
                                    Since {dstTeamName} is on a custom plan, all subscriptions with the following
                                    external IDs on {srcTeamName} will be canceled:
                                </p>
                                <p>{uniqueExternalIdsToCancel.join('\n')}</p>
                            </li>
                        )}
                        {uniqueExternalIdsToMerge.length > 0 && uniqueExistingExternalIds.length > 0 && (
                            <>
                                <li>
                                    <p>The following subscription(s) already exist on team {dstTeamName}:</p>
                                    <p>{uniqueExistingExternalIds.join('\n')}</p>
                                </li>
                                <li>
                                    <p>The following subscription(s) will be transferred to team {dstTeamName}:</p>
                                    <p>{uniqueExternalIdsToMerge.join('\n')}</p>
                                </li>
                            </>
                        )}
                    </ul>
                </TeamWarning>
            </>
        ),
        cancellable: true,
        yesLabel: 'Merge Team',
    });

    if (shouldSave) {
        await addPromiseToasts(mergeTeam(dstTeam, srcTeam, forceAllowMultiSub), {
            initial: 'Merging team...',
            onSuccess: 'Successfully merged team.',
            onCatch: 'Error merging team.',
        });
    }
};
