import * as React from 'react';
import { connect } from 'react-redux';

import { IAppState } from 'reports/store';

import { Intent } from '@blueprintjs/core';

import * as auth from 'reports/modules/auth';
import { Context } from 'reports/components/core/layout';
import { User } from 'reports/models/user';

import { isNull } from 'lodash';

import { CheckoutFlowSignUpBtn } from 'reports/modules/auth/components/common';
import Callout from './core/controls/Callout';

const BANNER_HEIGHT = 64;

interface IOwnProps {
    user: User;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;
type TimeLeft = string | undefined | null;

const TrialLimitsBanner: React.FC<IProps> = ({ user }) => {
    let timeLeft: TimeLeft;

    if (!user.isExpired()) {
        timeLeft = user.current_period_end?.fromNow().slice(3);
    } else {
        timeLeft = null;
    }

    const showBanner = user.status === 'trial' || user.status === 'expired_trial';

    const bannerIntent = !isNull(timeLeft) ? Intent.PRIMARY : Intent.WARNING;

    return (
        <>
            {showBanner && (
                <Context.NavBar style={{ height: BANNER_HEIGHT }}>
                    <Callout
                        icon="info-sign"
                        intent={bannerIntent}
                        rightElement={<CheckoutFlowSignUpBtn btnIntent={bannerIntent} />}
                        title={
                            !isNull(timeLeft)
                                ? 'Welcome to your HelioScope Trial!'
                                : 'Your HelioScope Trial has Expired!'
                        }
                    >
                        {!isNull(timeLeft) ? (
                            <>
                                You have <strong>{timeLeft}</strong> to try all HelioScope paid features. Upgrade
                                anytime for as low as $159/month
                            </>
                        ) : (
                            <>
                                Your account has expired, to continue using HelioScope, register now for a full account.
                                Please{' '}
                                <a href="mailto:sales@helioscope.com" target="_blank">
                                    contact us
                                </a>{' '}
                                if you have any questions.
                            </>
                        )}
                    </Callout>
                </Context.NavBar>
            )}
        </>
    );
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

export default connect(mapStateToProps)(TrialLimitsBanner);
