/* tslint:disable:variable-name */
import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import { CommonColumns } from 'reports/components/library/helpers';
import { FavoriteStar } from 'reports/components/core/controls';
import { ColWidth, SortType } from 'reports/components/core/tables/DataTable';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';

import * as utility from 'reports/models/utility_rate';

import * as auth from 'reports/modules/auth';
import { addPromiseToastsToggle } from 'reports/components/toasts';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

const UtilityRatesList: React.SFC<IDispatchProps & IStateProps> = ({ openEditor, setBookmarked, user }) => (
    <ResourceTable<utility.UtilityRate>
        columns={[
            {
                colWidth: ColWidth.SMALL,
                headerText: 'Favorite',
                renderCell: (rate) => (
                    <FavoriteStar
                        empty={!rate.bookmarked()}
                        onClick={(e) => {
                            e.stopPropagation();
                            addPromiseToastsToggle(
                                'favorite',
                                setBookmarked(rate, !rate.bookmarked()),
                                'utility rate',
                                rate.description,
                                rate.bookmarked(),
                            );
                        }}
                    />
                ),
            },
            {
                sort: { name: 'description', type: SortType.ALPHA },
                colWidth: ColWidth.LARGE,
                headerText: 'Utility Rate Name',
                renderCell: (rate) => <div style={{ width: '100%', textAlign: 'left' }}>{rate.description}</div>,
            },
            CommonColumns.lastModified(),
            CommonColumns.created(),
            CommonColumns.creator(),
            CommonColumns.team(),
            CommonColumns.publicCol(user),
        ]}
        onRowClick={(rate) => openEditor(rate)}
    />
);

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    openEditor: ({ utility_rate_id }) => {
        return routerActions.navigateTo('app.utility-rates.utility-rate', {
            rateId: utility_rate_id,
        });
    },
    setBookmarked: (rate, bookmarked) => {
        return utility.api.patchTeamSettings({
            bookmarked,
            utility_rate_id: rate.utility_rate_id,
        });
    },
}));

export default connect(mapStateToProps, mapDispatchToProps)(UtilityRatesList);
