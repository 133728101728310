import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';
import { IAppState } from 'reports/types';
import { Link } from 'react-router5';
import { sortBy, head } from 'lodash';

import { Colors, Icon, ProgressBar, Radio, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as analytics from 'reports/analytics';

import * as auth from 'reports/modules/auth';
import { actions as projActions } from 'reports/modules/project';
import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';
import * as des from 'reports/models/design';
import * as sim from 'reports/models/simulation';

import Section2 from 'reports/components/core/containers/Section2';
import { Flex } from 'reports/components/core/containers';
import BasicTable from 'reports/components/core/tables/BasicTable';
import { Button, DeleteButton } from 'reports/components/core/controls';
import * as styles from 'reports/styles/styled-components';
import ButtonGroupSelect from 'reports/components/forms/inputs/experimental/ButtonGroupSelect';
import { perfRatio } from 'reports/utils/metrics';

import { humanizeWatts, humanizeEnergy, stringifyNumberSimple, percentage } from 'reports/utils/formatters';

import ProjectAssetPage from './ProjectAssetPage';
import { addPromiseToasts, Toaster } from 'reports/modules/Toaster';
import { User } from 'reports/models/user';

interface IOwnProps {
    project: proj.Project;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface ITableProps extends IProps {
    metric: string;
}

interface IRowProps extends ITableProps {
    design: des.Design;
}

interface ICellProps extends IRowProps {
    scenario: scen.Scenario;
}

interface IMetricSelectProps {
    metric: string;
    setMetric: (metric: string) => void;
}

const styled = styles.styled;
const StyledCell = styled.td<{
    header?: boolean;
    primary?: boolean;
    primaryBoth?: boolean;
    alignLeft?: boolean;
}>`
    ${({ header }) => header && `background-color: ${Colors.LIGHT_GRAY5};`}
    ${({ primary }) => primary && `background-color: ${Colors.LIGHT_GRAY4};`}
    ${({ primaryBoth }) => primaryBoth && `background-color: ${Colors.LIGHT_GRAY2};`}
    ${({ alignLeft }) => alignLeft && `text-align: left !important;`}
`;
const StyledRadio = styled(Radio)`
    margin: 0 0 0 25px !important;
    padding: 0 !important;
`;
const StyledSpinner = styled(Spinner)`
    margin-right: 10px;
`;

const DeletedText = styled.span`
    color: ${Colors.RED2};
`;

const METRICS = {
    system_yield: 'Specific Yield',
    system_performance_ratio: 'Performance Ratio',
    annual_production: 'Grid Power',
};

const SimulationTableCell: React.FunctionComponent<ICellProps & { user: User }> = (props) => {
    const { getSimulation, design, scenario, triggerSimulation, deleteSimulation, metric, user } = props;
    const simulation = getSimulation(design, scenario);

    let content: React.ReactNode;
    switch (simulation?.status) {
        case 'completed':
            switch (metric) {
                case 'system_yield':
                    content = (
                        <>
                            {stringifyNumberSimple(
                                simulation.metadata.grid_power / design.field_component_metadata.nameplate,
                                1,
                            )}
                        </>
                    );
                    break;
                case 'system_performance_ratio':
                    content = <>{percentage(perfRatio(simulation.design, simulation)!)}</>;
                    break;
                case 'annual_production':
                    content = (
                        <>
                            {humanizeEnergy(simulation.metadata.grid_power, {
                                precision: 1,
                            })}
                        </>
                    );
                    break;
                default:
                    content = <>Invalid display</>;
            }
            break;
        case 'error':
            content = <>Errors</>;
            break;
        case 'processing':
            content = (
                <ProgressBar
                    value={simulation.metadata.complete_slices / simulation.metadata.total_slices}
                    intent={Intent.PRIMARY}
                />
            );
            break;
        case 'initialized':
            content = (
                <>
                    <StyledSpinner size={SpinnerSize.SMALL} />
                    Initializing
                </>
            );
            break;
        case 'pending':
            content = (
                <>
                    <Icon icon={IconNames.STOPWATCH} style={{ marginRight: 10 }} />
                    Queued
                </>
            );
            break;
        default:
            return (
                <Button
                    icon={IconNames.PLAY}
                    style={{ marginLeft: 0 }}
                    onClick={async () => {
                        await triggerSimulation(design, scenario);

                        analytics.track('simulation.triggered', {
                            project_id: design.project_id,
                            design_id: design.design_id,
                            nameplate: design.nameplate,
                            team_id: user.team_id,
                        });
                    }}
                    loadingWhileAwaiting
                >
                    Simulate
                </Button>
            );
    }

    const deleteButton = (
        <DeleteButton
            small
            minimal
            intent={Intent.DANGER}
            onClick={async () => await deleteSimulation(simulation)}
            loadingWhileAwaiting
        />
    );

    return (
        <Flex.Container align={Flex.Align.CENTER} alignV={Flex.AlignV.CENTER}>
            {content}
            {deleteButton}
        </Flex.Container>
    );
};

const SimulationTableRow: React.FunctionComponent<IRowProps> = (props) => {
    const { project, scenarios, design, user, undoDeleteDesign } = props;
    const primaryDesign = design.design_id === project.primary_design_id;

    if (design.to_delete) {
        return (
            <StyledCell colSpan={scenarios.length} primary={primaryDesign}>
                <a
                    onClick={async () => {
                        await addPromiseToasts(undoDeleteDesign(design), {
                            initial: `Restoring design ${design.description}`,
                            onSuccess: 'Design restored!',
                            onCatch: `Error restoring design ${design.description}`,
                        });
                    }}
                >
                    Undo Delete
                </a>
            </StyledCell>
        );
    }

    if ((design.field_component_metadata?.nameplate || 0) <= 0) {
        return (
            <StyledCell colSpan={scenarios.length} primary={primaryDesign}>
                To generate a report,{' '}
                <Link
                    routeName="app.projects.project.designer"
                    routeParams={{
                        projectId: project.project_id,
                        designId: design.design_id,
                    }}
                >
                    complete this design in the designer
                </Link>
                .
            </StyledCell>
        );
    }

    // Generally, if the design is over 7.5Mw we will only store metadata and not simulate it.
    // However, we allow a per user setting to simulate up to 15Mw
    if (design.field_component_metadata.metadata_only) {
        return (
            <StyledCell colSpan={scenarios.length} primary={primaryDesign}>
                Simulations for designs over {user.hasFeature('enable_15MW_simulations') ? '15' : '7.5'}MWp are
                unsupported.
                <br />
                To run a simulation,{' '}
                <Link
                    routeName="app.projects.project.designer"
                    routeParams={{
                        projectId: project.project_id,
                        designId: design.design_id,
                    }}
                >
                    adjust this design in the designer
                </Link>
                .
            </StyledCell>
        );
    }

    if (scenarios.length === 0) {
        return (
            <StyledCell primary={primaryDesign}>
                To generate a report,{' '}
                <Link routeName="app.projects.project.conditions" routeParams={{ projectId: project.project_id }}>
                    create a condition set
                </Link>
                .
            </StyledCell>
        );
    }

    return (
        <>
            {sortBy(scenarios, 'description').map((scenario) => (
                <StyledCell
                    key={scenario.scenario_id}
                    primary={primaryDesign || scenario.scenario_id === project.primary_scenario_id}
                    primaryBoth={primaryDesign && scenario.scenario_id === project.primary_scenario_id}
                >
                    <SimulationTableCell key={scenario.scenario_id} scenario={scenario} {...props} user={user} />
                </StyledCell>
            ))}
        </>
    );
};

const SimulationTable: React.FunctionComponent<ITableProps> = (props) => {
    const { project, scenarios, designs, deleteDesign, setPrimaryDesign, setPrimaryScenario } = props;
    const canDeleteDesign = designs.length > 1;
    return (
        <BasicTable centered width="100%" tableTheme="specs">
            <thead>
                <tr>
                    <th rowSpan={2}>Designs</th>
                    <th colSpan={scenarios.length} style={{ textAlign: 'center' }}>
                        Condition Sets
                    </th>
                </tr>
                <tr>
                    {sortBy(scenarios, 'description').map((scenario) => (
                        <th
                            key={scenario.scenario_id}
                            style={
                                scenario.scenario_id === project.primary_scenario_id
                                    ? { backgroundColor: Colors.LIGHT_GRAY4 }
                                    : { backgroundColor: Colors.LIGHT_GRAY5 }
                            }
                        >
                            <StyledRadio
                                inline
                                checked={scenario.scenario_id === project.primary_scenario_id}
                                onChange={() => setPrimaryScenario(scenario)}
                            />
                            <Link
                                routeName="app.projects.project.conditions.condition"
                                routeParams={{
                                    projectId: project.project_id,
                                    scenarioId: scenario.scenario_id,
                                }}
                            >
                                {scenario.description}
                            </Link>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {sortBy(designs, 'description').map((design) => {
                    const primaryDesign = design.design_id === project.primary_design_id;
                    return (
                        <tr key={design.design_id}>
                            <StyledCell primary={primaryDesign} header alignLeft>
                                <StyledRadio
                                    inline
                                    checked={primaryDesign}
                                    onChange={() => setPrimaryDesign(design)}
                                    disabled={design.to_delete}
                                />
                                {design.to_delete ? (
                                    <DeletedText>
                                        {design.description}
                                        {' (deleted)'}
                                    </DeletedText>
                                ) : (
                                    <>
                                        <Link
                                            routeName="app.projects.project.designer"
                                            routeParams={{
                                                projectId: project.project_id,
                                                designId: design.design_id,
                                            }}
                                        >
                                            {design.description}
                                        </Link>{' '}
                                        (
                                        {humanizeWatts(design.nameplate, {
                                            precision: 1,
                                        })}
                                        )
                                        <DeleteButton
                                            small
                                            minimal
                                            intent={Intent.DANGER}
                                            onClick={async () => {
                                                if (primaryDesign) {
                                                    Toaster.show({
                                                        message:
                                                            'Please select another design as the primary design before attempting to delete this design.',
                                                        intent: Intent.DANGER,
                                                    });
                                                } else {
                                                    await addPromiseToasts(deleteDesign(design), {
                                                        initial: `Deleting design ${design.description}`,
                                                        onSuccess:
                                                            'Flagged this design for deletion. Design will be deleted permanently in 5 minutes.',
                                                        onCatch: `Error deleting design ${design.description}`,
                                                    });
                                                }
                                            }}
                                            disabled={!canDeleteDesign}
                                            loadingWhileAwaiting
                                        />
                                    </>
                                )}
                            </StyledCell>
                            <SimulationTableRow design={design} {...props} />
                        </tr>
                    );
                })}
            </tbody>
        </BasicTable>
    );
};

const MetricSelect: React.FunctionComponent<IMetricSelectProps> = ({ metric, setMetric }) => (
    <ButtonGroupSelect
        items={Object.keys(METRICS)}
        selectedItem={metric}
        onSelect={setMetric}
        itemRenderer={(item) => ({ key: item, text: METRICS[item] })}
        flex={false}
    />
);

const SimulationPage: React.FunctionComponent<IProps> = (props) => {
    const { loadDesigns, loadScenarios, loadSimulations } = props;
    const [metric, setMetric] = React.useState('system_yield');
    React.useEffect(() => {
        loadDesigns();
    }, []);
    React.useEffect(() => {
        loadScenarios();
    }, []);
    React.useEffect(() => {
        loadSimulations();
    }, []);

    return (
        <ProjectAssetPage>
            <Section2
                title="Simulations"
                subtitle={`For each combination of Design and Condition Set,
                           HelioScope can simulate the array's energy yield to generate a Report.`}
            >
                <MetricSelect metric={metric} setMetric={setMetric} />
                <hr />
                <SimulationTable metric={metric} {...props} />
            </Section2>
        </ProjectAssetPage>
    );
};

const mapStateToProps = (state: IAppState, props: IOwnProps) => ({
    user: auth.selectors.getUser(state)!,
    scenarios: scen.selectors.all(state, {
        filter: (obj) => obj.project_id === props.project.project_id,
    }),
    designs: proj.selectors.designs(state, props.project),
    getSimulation: (design: des.Design, scenario: scen.Scenario) =>
        head(
            sim.selectors.all(state, {
                filter: ({ design_id, scenario_id }) =>
                    design_id === design.design_id && scenario_id === scenario.scenario_id,
            }),
        ),
});

const mapDispatchToProps = bindActions(({ project }) => ({
    loadDesigns: () => des.api.index({ project_id: project.project_id }),
    loadScenarios: () => scen.api.index({ project_id: project.project_id }),
    loadSimulations: () => sim.api.index({ project_id: project.project_id }),
    triggerSimulation: (design: des.Design, scenario: scen.Scenario) =>
        sim.api.create({
            design_id: design.design_id,
            scenario_id: scenario.scenario_id,
        }),
    deleteDesign: (design: des.Design) => {
        const delDesign = new des.Design({ ...design });
        delDesign.to_delete = true;
        return des.api.save(delDesign);
    },
    undoDeleteDesign: (design: des.Design) => {
        const restoreDesign = new des.Design({ ...design });
        restoreDesign.to_delete = false;
        return des.api.save(restoreDesign);
    },
    deleteSimulation: (simulation: sim.Simulation) => sim.api.delete({ simulation_id: simulation.simulation_id }),
    setPrimaryDesign: (design: des.Design) => projActions.setPrimaryDesign(project, design),
    setPrimaryScenario: (scenario: scen.Scenario) => projActions.setPrimaryScenario(project, scenario),
}));

export default connect(mapStateToProps, mapDispatchToProps)(SimulationPage);
