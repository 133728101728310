import * as React from 'react';
import { connect } from 'react-redux';

import { IAppState } from 'reports/store';

import { Intent } from '@blueprintjs/core';

import * as auth from 'reports/modules/auth';
import Callout from './core/controls/Callout';

interface IOwnProps {}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;

const UpsellBanner: React.FunctionComponent<IProps> = ({ user }) => {
    // TODO: update to work as expected, update dates in copy for legacy users
    const shouldTrialUserSeeUpsellBanner = user.role.will_lose_financials_on_trial_to_basic_conversion;

    return (
        <>
            {shouldTrialUserSeeUpsellBanner && (
                <Callout intent={Intent.WARNING}>
                    <span>This feature is available in HelioScope Pro and Custom subscriptions.</span>
                </Callout>
            )}
        </>
    );
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

export default connect(mapStateToProps)(UpsellBanner);
