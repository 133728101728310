import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { bindActions } from 'reports/utils/redux';

import * as pd from 'reports/models/power_device';
import { IAppState } from 'reports/store';
import CharacterizationViewControls from 'reports/components/library/helpers/CharacterizationViewControls';

interface IOwnProps {
    device: pd.PowerDevice;
    selectedChar: pd.PowerDeviceCharacterization;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

const DeviceCharViewControls: React.FC<IProps> = (props) => {
    const { selectedChar, device, characterizations, updatePowerDevice, navigateTo } = props;
    return (
        <CharacterizationViewControls
            characterizations={characterizations}
            selectedChar={selectedChar}
            setSelectedChar={(char) =>
                navigateTo('app.power-devices.power-device.preview', {
                    powerDeviceId: device.power_device_id,
                    characterizationId: char.power_device_characterization_id,
                })
            }
            isDefaultChar={(char) => char.power_device_characterization_id === device.user_default_characterization_id}
            makeDefaultChar={({ power_device_id, power_device_characterization_id }) =>
                updatePowerDevice({
                    power_device_id,
                    user_default_characterization_id: power_device_characterization_id,
                })
            }
            charId={(char) => char.power_device_characterization_id}
        />
    );
};

const mapStateToProps = (state: IAppState, { device }: IOwnProps) => ({
    characterizations: pd.selectors.allCharacterizations(state, device),
});

const mapDispatchToProps = bindActions({
    navigateTo: routerActions.navigateTo,
    updatePowerDevice: pd.api.save,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceCharViewControls);
