import * as React from 'react';

import { useRoute } from 'react-router5';

import ContractView from 'reports/modules/settings/admin/views/ContractView';
import SelfServeView from 'reports/modules/settings/admin/views/SelfServeView';
import TrialView from 'reports/modules/settings/admin/views/TrialView';

import { Subscription } from 'reports/models/subscription';
import { SubscriptionType, Team } from 'reports/models/team';
import { User } from 'reports/models/user';

interface Props {
    subscription?: Subscription;
    team: Team;
    user: User;
}

const SubscriptionView = ({ subscription, team, user }: Props) => {
    const { route } = useRoute();
    const isContract = subscription?.is_contract || !!route.params.createContract;

    const isSelfServe =
        !!subscription && subscription.is_self_serve && team.subscription_type !== SubscriptionType.v1_non_1_to_1;

    const isTrial = team.subscription_type === 'none';

    if (isContract) {
        return <ContractView createContract={!!route.params.createContract} subscription={subscription} user={user} />;
    }

    if (!!subscription && isSelfServe) {
        return <SelfServeView subscription={subscription} team={team} user={user} />;
    }

    if (isTrial) {
        return <TrialView team={team} user={user} />;
    }

    return <div>Not supported!</div>;
};

export default SubscriptionView;
