import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Intent } from '@blueprintjs/core';

import { Toaster } from 'reports/modules/Toaster';

import { api as subscriptionApi, Subscription } from 'reports/models/subscription';
import { api as teamApi, Team } from 'reports/models/team';

import {
    Button,
    Field,
    Form,
    Grid,
    GridItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    StyleProvider,
    Text,
    TextInput,
} from '@aurorasolar/ds';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { formikSetFieldErrors } from 'reports/modules/auth/components/common';
import { HelioScopeLegacyTheme } from 'reports/styles/themeLegacy';

interface Props {
    handleSubmit: () => void;
    handleClose: () => void;
    isOpen: boolean;
    subscription: Subscription;
    team: Team;
}

interface FormData {
    email: string;
    firstName: string;
    lastName: string;
}

const InviteMemberDialog = ({ handleClose, isOpen, subscription, team }: Props) => {
    const dispatch = useDispatch();
    const refreshSubscription = ({ external_id }) => dispatch(subscriptionApi.refreshCache({ external_id }));
    const reloadTeam = (team: Team) => dispatch(teamApi.get({ team_id: team.team_id }));
    const inviteMember = (formData: FormData, externalId: string) =>
        dispatch(
            subscriptionApi.inviteMember(
                {
                    email: formData.email,
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                },
                { external_id: externalId },
            ),
        );

    const onSubmit = async (formData: FormData, formikHelpers: FormikHelpers<FormData>) => {
        const { setSubmitting } = formikHelpers;

        try {
            await inviteMember(formData, subscription.external_id);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                formikSetFieldErrors(error, formikHelpers);
            } else {
                Toaster.show({ message: 'Unknown error', intent: Intent.DANGER });
            }
            setSubmitting(false);
            throw error;
        }

        await refreshSubscription({ external_id: subscription.external_id });
        await reloadTeam(team);

        Toaster.show({
            intent: Intent.SUCCESS,
            message: `An invite has been sent to ${formData.email}`,
            timeout: 5000,
        });

        setSubmitting(false);
    };

    const formikRef = React.useRef<FormikProps<FormikValues>>(null);

    const closeModal = () => {
        handleClose();
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };

    const validate = async ({ email, firstName, lastName }: FormData) => {
        return {
            ...(email === '' && { email: ['Email is required.'] }),
            ...(firstName === '' && { firstName: ['First Name is required.'] }),
            ...(lastName === '' && { lastName: ['Last Name is required.'] }),
        };
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{ email: '', firstName: '', lastName: '' }}
            validate={validate}
            onSubmit={async (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
                await onSubmit(values, formikHelpers);
                closeModal();
            }}
        >
            {({ isSubmitting }) => {
                return (
                    <StyleProvider theme={HelioScopeLegacyTheme} colorMode="light">
                        <Modal open={isOpen} handleClose={closeModal}>
                            <Form>
                                <ModalHeader pb="unset">
                                    <Text as="h2" text="h2">
                                        Invite someone to join your team
                                    </Text>
                                </ModalHeader>
                                <ModalBody text="body14">
                                    <Text text="body14">
                                        Invited team members will receive an email with your email address, guiding them
                                        through account setup, including a link to create a password. Links in this
                                        email are valid for 72 hours.
                                    </Text>

                                    <Grid>
                                        <GridItem
                                            size={{
                                                _: 'half',
                                            }}
                                        >
                                            <Field
                                                component={TextInput}
                                                fontWeight="normal"
                                                size="md"
                                                label="First name"
                                                name="firstName"
                                                placeholder="First name"
                                            />
                                        </GridItem>
                                        <GridItem
                                            size={{
                                                _: 'half',
                                            }}
                                        >
                                            <Field
                                                component={TextInput}
                                                fontWeight="normal"
                                                size="md"
                                                label="Last name"
                                                name="lastName"
                                                placeholder="Last name"
                                            />
                                        </GridItem>
                                        <GridItem
                                            size={{
                                                _: 'full',
                                            }}
                                        >
                                            <Field
                                                component={TextInput}
                                                fontWeight="normal"
                                                size="md"
                                                label="Email address"
                                                name="email"
                                                placeholder="Email address"
                                                type="email"
                                            />
                                        </GridItem>
                                    </Grid>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        Invite team member
                                    </Button>
                                    <Button onClick={closeModal} variant="secondary">
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Form>
                        </Modal>
                    </StyleProvider>
                );
            }}
        </Formik>
    );
};

export default InviteMemberDialog;
