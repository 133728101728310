import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reports/store';

import { Button, NonIdealState } from '@blueprintjs/core';
import { CreateButton } from 'reports/components/core/controls';
import { IconNames } from '@blueprintjs/icons';

import * as teams from 'reports/models/team';
import { api, Theme } from 'reports/models/theme';

import FixedItemsSelect from 'reports/components/forms/inputs/experimental/FixedItemsSelect';
import { Flex, Section2 } from 'reports/components/core/containers';

import { selectors as themeSelectors } from 'reports/modules/themes';
import ThemeEditor from 'reports/modules/themes/ThemeEditor';

const EMPTY_THEME = {
    name: '',
    primary_color: undefined,
    secondary_color: undefined,
    palette: { colors: [] },
} as any;

interface Props {
    team: teams.Team;
}

const TeamThemes = ({ team }: Props) => {
    const dispatch = useDispatch();

    const theme: Theme | null | undefined = useSelector((state: IAppState) => themeSelectors.getTeamTheme(state, team));
    const [currentTheme, setCurrentTheme] = React.useState<Theme | undefined>(theme || undefined);

    const [themes, setThemes] = React.useState<Theme[]>([]);
    const [isNewTheme, setIsNewTheme] = React.useState<boolean>(false);

    const getTeamThemes = () => dispatch(api.index({ team_id: team.team_id }));
    const setTeamTheme = (theme) =>
        dispatch(teams.saver.get(team).patch({ theme_id: theme ? theme.theme_id : null }, true));

    React.useEffect(() => {
        (async () => await loadThemes())();
        return () => {};
    }, []);

    const createEmptyTheme = () => {
        if (isNewTheme) {
            return;
        }

        // IThemeForm won't have a theme_id/team_id, but everything should still work
        const emptyTheme = {
            name: '',
            ...EMPTY_THEME,
        } as any;
        setThemes([...themes, emptyTheme]);
        setCurrentTheme(emptyTheme);
        setIsNewTheme(true);
    };

    const cancelNewTheme = () => {
        const themesWithoutNew = themes.filter((theme) => theme.theme_id !== undefined);
        setThemes(themesWithoutNew);
        setCurrentTheme(theme || themes[0]);
        setIsNewTheme(false);
    };

    const loadThemes = async () => {
        const teamThemes = await getTeamThemes();
        setThemes(teamThemes.length ? teamThemes : []);
    };

    const updateCurrentTheme = (newThemeId: number) =>
        setCurrentTheme(themes.find((teamTheme) => teamTheme.theme_id === newThemeId));

    const onEditorSave = (updatedTheme: Theme, isNewTheme: boolean) => {
        if (isNewTheme && themes.length === 1) {
            setCurrentTheme(updatedTheme);
            setIsNewTheme(false);
            setTeamTheme(updatedTheme);
        } else {
            setCurrentTheme(updatedTheme);
            setIsNewTheme(false);
        }
        loadThemes();
    };

    return (
        <Section2
            title="Themes"
            contextEl={
                <Flex.Container>
                    {!isNewTheme && themes.length > 0 && (
                        <>
                            <FixedItemsSelect
                                items={themes.map((theme) => ({
                                    key: theme.theme_id,
                                    name: theme.name,
                                }))}
                                value={currentTheme ? currentTheme.theme_id : themes[0].theme_id}
                                disabled={false}
                                onChange={updateCurrentTheme}
                                width={200}
                            />
                            <Button
                                icon={IconNames.PIN}
                                text="Make Default"
                                onClick={() => setTeamTheme(currentTheme)}
                                disabled={theme?.theme_id === currentTheme?.theme_id}
                                style={{ marginLeft: 8 }}
                            />
                        </>
                    )}
                    <CreateButton text="New Theme" onClick={createEmptyTheme} disabled={isNewTheme} />
                </Flex.Container>
            }
        >
            {themes.length > 0 ? (
                <ThemeEditor
                    theme={currentTheme || themes[0]}
                    onSave={onEditorSave}
                    cancelNewTheme={cancelNewTheme}
                    isNewTheme={isNewTheme}
                />
            ) : (
                <NonIdealState
                    icon="clean"
                    title="Create team themes here"
                    description="Use the 'New Theme' button to build themes for your team."
                />
            )}
        </Section2>
    );
};

export default TeamThemes;
