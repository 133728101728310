import * as React from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { Classes, Icon, Menu, MenuItem, MenuDivider, Popover, Position } from '@blueprintjs/core';

import * as cfg from 'reports/config';
import { IAppState } from 'reports/types';
import * as auth from 'reports/modules/auth';
import { bindActions } from 'reports/utils/redux';
import { Link } from 'reports/components/core/controls';

import * as styles from 'reports/styles/styled-components';

const styled = styles.styled;

interface IUserPopover {
    className?: string;
    collapsed?: boolean;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IUserPopover & ReturnType<typeof mapStateToProps> & IDispatchProps;

const UserMenu = styled(Menu)`
    max-width: 230px;
    left: 10px;
`;

class UserPopover extends React.PureComponent<IProps> {
    render() {
        const { user, logout, collapsed } = this.props;

        const content =
            user != null ? (
                <UserMenu>
                    <MenuItem icon="user" text={`${user.first_name} ${user.last_name}`} disabled={true} />
                    <MenuItem icon="inbox" text={user.email} disabled={true} />
                    {user.company != null ? <MenuItem icon="office" text={user.company} disabled={true} /> : null}
                    {user.current_period_end != null ? (
                        <MenuItem
                            icon="calendar"
                            text={`Expires ${user.current_period_end.calendar()}`}
                            disabled={true}
                        />
                    ) : null}
                    <MenuDivider />
                    <Link routeName="app.settings.user">
                        <MenuItem icon="cog" text="Account Settings" />
                    </Link>
                    <MenuItem icon="log-out" text="Logout" onClick={logout} />
                </UserMenu>
            ) : (
                <div />
            );

        return (
            <>
                {' '}
                {}
                <Popover
                    content={content}
                    position={Position.TOP}
                    boundary="window"
                    targetTagName="div"
                    wrapperTagName="div"
                >
                    <div
                        className={classNames('avatar', 'shrink', 'grid-content', Classes.TEXT_OVERFLOW_ELLIPSIS)}
                        style={collapsed ? { paddingLeft: '12px' } : undefined}
                    >
                        <Icon icon="person" style={{ margin: '2px 8px' }} />
                        {collapsed ? null : user != null ? user.fullName() : 'Please Login'}
                    </div>
                </Popover>
            </>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    config: cfg.selectors.getConfig(state)!,
    user: auth.selectors.getUser(state),
});

const mapDispatchToProps = bindActions({
    logout: () => auth.api.logout(undefined),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPopover);
