import * as ft from 'reports/models/financial_template';
import * as inc from 'reports/models/incentive';
import * as mod from 'reports/models/module';
import * as pd from 'reports/models/power_device';
import * as tm from 'reports/models/team';
import * as ur from 'reports/models/utility_rate';
import * as usr from 'reports/models/user';

/*
 * User roles
 */
export function helioscopeAdmin(user: usr.User) {
    return user.is_admin;
}

export function canManageTeamResources(user: usr.User, team: tm.Team) {
    return (user.role.can_manage_team_resources && user.team.team_id === team.team_id) || helioscopeAdmin(user);
}

/*
 * Access rules
 */
export function canAccessAdvFinancialFeatures(user: usr.User) {
    return helioscopeAdmin(user);
}

/**
 * Checks whether a user is allowed to modify a resource (such as a report or incentive)
 * that can be editable by the entire team.
 */
export function canModifySharedTeamResource(user: usr.User, resource: { team_id: number }) {
    return user.team.team_id === resource.team_id;
}

export function canManageBilling(user: usr.User) {
    return user.role.can_manage_billing || helioscopeAdmin(user);
}

export function canViewSettings(user: usr.User) {
    return user.role.can_view_settings;
}

export function canMakeResourcePublic(user: usr.User, resource: { team_id: number; public: boolean }) {
    if (resource instanceof ur.UtilityRate) {
        return helioscopeAdmin(user);
    }

    if (resource instanceof inc.Incentive) {
        return helioscopeAdmin(user);
    }

    if (resource instanceof ft.FinancialTemplate) {
        return helioscopeAdmin(user);
    }

    if (resource instanceof pd.PowerDevice) {
        return helioscopeAdmin(user);
    }

    if (resource instanceof mod.Module) {
        return helioscopeAdmin(user);
    }

    return helioscopeAdmin(user);
}

export class UnauthorizedError extends Error {}
