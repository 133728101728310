import { pick } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { CreateButton, LinkButton, PrimaryIntent } from 'reports/components/core/controls';
import { promptModalForm } from 'reports/components/dialog';
import * as mod from 'reports/models/module';
import { selectors as userSelectors } from 'reports/modules/auth';
import {
    IModuleUploadItem,
    IModuleUploadItemsMap,
    INewModuleForm,
    ISelectedModule,
    isNonAdminWithPublicModule,
} from 'reports/modules/module/module_upload';
import AppToaster from 'reports/modules/Toaster';
import { bindActions } from 'reports/utils/redux';
import { BYTES_PER_MEGABYTE } from 'reports/utils/units';
import BatchUploadDialog from '../../../files/batch_upload/BatchUploadDialog';
import { DetailsCell } from '../../../files/batch_upload/BatchUploadTable';
import ModuleUploadModuleCell from './ModuleUploadModuleCell';
import ModuleUploadPreviewDialog from './ModuleUploadPreviewDialog';
import NewModuleForm from './NewModuleForm';

const MAX_PAN_FILE_SIZE_BYTES = 10 * BYTES_PER_MEGABYTE;

const Description: React.FC = () => (
    <div>
        <p>
            Select <strong>.PAN</strong> files to add to HelioScope.
        </p>

        <p>
            PAN files are stored as <strong>Module Characterizations</strong> in HelioScope, which define how a given
            version of a module will perform under different irradiance and temperature assumptions. The module itself
            is stored independently, so one module may have many characterizations. All PAN files you upload are{' '}
            <strong>private</strong> by default.
        </p>

        <p>
            <strong>Note:</strong> we handle files generated by PVSyst v5 and PVSyst v6.x for version v6.40 or later. If
            the uploader fails, please send the file to{' '}
            <a href="mailto:support@helioscope.com">support@helioscope.com</a> for investigation and provide the version
            of PVSyst used.
        </p>
    </div>
);

const ModuleDetailsCell: React.FC<{ item: IModuleUploadItem }> = ({ item }) => (
    <DetailsCell
        obj={item.selectedModule}
        detailsFields={[
            { name: 'Manufacturer', path: 'manufacturer' },
            { name: 'Model', path: 'module_name' },
            { name: 'Source', path: 'source' },
        ]}
    />
);

const ModuleLinkButton: React.FC<{ item: IModuleUploadItem }> = ({ item }) => (
    <LinkButton
        text="View Module Characterization"
        icon={IconNames.DOCUMENT_OPEN}
        routeName="app.modules.module.preview"
        routeParams={{
            moduleId: item.savedChar?.module.module_id,
            characterizationId: item.savedChar?.module_characterization_id,
        }}
        target="_blank"
    />
);

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IStateProps & IDispatchProps;

interface IState {
    items: IModuleUploadItemsMap;
    previewItem: IModuleUploadItem | null;
    showUploadDialog: boolean;
}

function initialState(): IState {
    return { items: {}, previewItem: null, showUploadDialog: false };
}

class ModuleUploadLauncher extends React.PureComponent<IProps, IState> {
    state: IState = initialState();

    render() {
        const { user } = this.props;
        const { items, previewItem } = this.state;
        return (
            <>
                {previewItem && (
                    <ModuleUploadPreviewDialog
                        user={user}
                        previewItem={previewItem}
                        isOpen={true}
                        onClose={this.closePreviewDialog}
                        uploadItem={this.uploadItem}
                    />
                )}
                <CreateButton
                    icon={IconNames.UPLOAD}
                    text="Upload Module"
                    onClick={() => this.openUploadDialog()}
                    fill={true}
                />
                <BatchUploadDialog<IModuleUploadItem>
                    title="Module Upload"
                    acceptTypes=".pan, .PAN"
                    description={<Description />}
                    maxSize={MAX_PAN_FILE_SIZE_BYTES}
                    isOpen={this.state.showUploadDialog}
                    onClose={() => this.setState({ showUploadDialog: false })}
                    items={Object.values(items)}
                    onSelectFiles={this.onSelectFiles}
                    uploadItem={this.uploadItem}
                    tableProps={{
                        helperText:
                            "Use the 'Select Files' button or drag and drop .PAN files here to add modules for upload.",
                        errorMsg: 'Error parsing file or uploading characterization.',
                        removeItem: this.removeItem,
                        previewItem: (previewItem) => {
                            this.setState({
                                previewItem,
                                showUploadDialog: false,
                            });
                        },
                        isUploadDisabled: (item) =>
                            !item.selectedModule || isNonAdminWithPublicModule(user, item.selectedModule),
                        customTableColumns: [
                            {
                                title: 'Details',
                                cell: (item) => <ModuleDetailsCell item={item} />,
                            },
                            {
                                title: 'Module',
                                cell: (item) => (
                                    <ModuleUploadModuleCell
                                        setItemModule={this.setItemModule}
                                        user={user}
                                        item={item}
                                    />
                                ),
                            },
                        ],
                        postUploadActions: (item: IModuleUploadItem) => <ModuleLinkButton item={item} />,
                    }}
                />
            </>
        );
    }

    openUploadDialog() {
        /*
         * This fixes stale state bugs caused by loaded items being retained in memory if the user closes the
         * dialog and then modifies other modules (e.g., making them public / private) before re-opening the dialog.
         */
        this.setState({ ...initialState(), showUploadDialog: true });
    }

    // Given a list of files, parse them into a list of IModuleUploadItem objects.
    onSelectFiles = async (files: File[]) => {
        let newItems = { ...this.state.items };
        for (const panFile of files) {
            newItems[panFile.name] = {
                matchedChar: null,
                file: panFile,
                status: 'loading',
            };
        }
        this.setState({ items: newItems });
        newItems = { ...newItems };
        for (const panFile of files) {
            try {
                // Use the PAN endpoint to parse the file into a characterization.
                const charResponse = await this.props.panFileToCharOrSaveCharToModule({
                    file: panFile,
                });
                const matchedChar = new mod.MatchedCharacterization(charResponse);

                // Modules returned from metadata in descending order of match quality.
                // Therefore the 0th module should be the 100% match if there is one.
                // Set the 0th module as the selected module if there's a 100% match.
                const { modules } = matchedChar.metadata;
                if (modules.length > 0 && modules[0]._match_quality === 1) {
                    const topModule = modules[0];
                    newItems[panFile.name].selectedModule = {
                        ...pick(topModule, ['module_id', 'public', 'manufacturer', '_match_quality', 'source']),
                        module_name: topModule.name,
                    };
                    matchedChar.module = topModule;
                    matchedChar.module_id = topModule.module_id;
                }

                // When there are no similar modules, set selectedModule to be defined with
                // what's in the PAN file so that the upload button is not disabled.
                if (modules.length === 0) {
                    newItems[panFile.name].selectedModule = {
                        ...pick(matchedChar.module, ['module_id', 'public', 'manufacturer', 'source']),
                        module_name: matchedChar.module.name,
                        _match_quality: 0,
                    };
                }

                newItems[panFile.name].matchedChar = matchedChar;
                newItems[panFile.name].status = 'staged';
            } catch (e) {
                newItems[panFile.name].status = 'error';
                AppToaster.show({
                    message: `Error uploading ${panFile.name}`,
                    intent: Intent.DANGER,
                });
            }
        }
        this.setState({ items: newItems });
    };

    removeItem = (item: IModuleUploadItem) => {
        const { items } = this.state;
        const newModuleUploadItemsMap = { ...items };
        delete newModuleUploadItemsMap[item.file.name];
        this.setState({ items: newModuleUploadItemsMap });
    };

    closePreviewDialog = () => {
        this.setState({ previewItem: null, showUploadDialog: true });
    };

    uploadItem = async (item: IModuleUploadItem) => {
        const { selectedModule } = item;

        if (selectedModule === undefined) {
            throw new Error('No module selected to save the characterization to!');
        }

        if (item.matchedChar === null) {
            throw new Error(`Item's matched characterization is null`);
        }

        const { items } = this.state;
        let newModuleUploadItemsMap = { ...items };
        newModuleUploadItemsMap[item.file.name].status = 'uploading';
        this.setState({ items: newModuleUploadItemsMap });

        const toast = AppToaster.show({
            message: `Uploading ${item.file.name}`,
        });

        let respChar;
        try {
            if (selectedModule.module_id) {
                respChar = await this.props.panFileToCharOrSaveCharToModule({
                    file: item.file,
                    characterization: {
                        module_id: selectedModule.module_id,
                        name: item.matchedChar.name,
                        description: item.matchedChar.description,
                    },
                });
            } else {
                respChar = await this.props.createNewModule({
                    file: item.file,
                    module_name: selectedModule.module_name,
                    characterization_name: item.matchedChar.name,
                    characterization_desc: item.matchedChar.description,
                });
            }
        } catch (e) {
            newModuleUploadItemsMap = { ...items };
            newModuleUploadItemsMap[item.file.name].status = 'error';
            this.setState({ items: newModuleUploadItemsMap });

            const errorMsg = `Error saving module characterization ${item.file.name}: ${e}`;
            AppToaster.show(
                {
                    message: errorMsg,
                    intent: Intent.DANGER,
                },
                toast,
            );
            throw new Error(errorMsg);
        }

        AppToaster.show(
            {
                message: `Successfully uploaded module characterization: ${item.file.name}`,
                intent: Intent.SUCCESS,
            },
            toast,
        );
        newModuleUploadItemsMap = { ...items };
        newModuleUploadItemsMap[item.file.name].savedChar = respChar;
        newModuleUploadItemsMap[item.file.name].status = 'uploaded';
        this.setState({ items: newModuleUploadItemsMap });
    };

    setItemModule = async (item: IModuleUploadItem, moduleSelectOption: ISelectedModule) => {
        const { items } = this.state;
        const newModuleUploadItemsMap = { ...items };

        if (moduleSelectOption.module_id === null) {
            // If the user has selected the "Create new module" option or the created new module name,
            // set the item in state accordingly.
            const { matchedChar } = item;

            if (matchedChar === null) {
                throw new Error(`Characterization loading or already uploaded!`);
            }

            if (item.newModuleName) {
                // If the user has already provided a new module name use that.
                newModuleUploadItemsMap[item.file.name].selectedModule = {
                    module_name: item.newModuleName,
                    module_id: null,
                    public: matchedChar.module.public,
                    manufacturer: matchedChar.module.manufacturer,
                    _match_quality: 0,
                    source: matchedChar.module.source,
                };
            } else {
                // Otherwise, have the user provide the new module name.
                const newModuleResponse = await newModuleDialog(
                    matchedChar.module.name,
                    matchedChar.module.manufacturer,
                    matchedChar.module.team_id,
                    matchedChar.metadata.modules,
                );
                newModuleUploadItemsMap[item.file.name].newModuleName = newModuleResponse.newModuleName;
                newModuleUploadItemsMap[item.file.name].selectedModule = {
                    module_name: newModuleResponse.newModuleName,
                    module_id: null,
                    public: matchedChar.module.public,
                    manufacturer: matchedChar.module.manufacturer,
                    _match_quality: 0,
                    source: matchedChar.module.source,
                };
            }
        } else {
            // Else just save the selected similar module option.
            newModuleUploadItemsMap[item.file.name].selectedModule = moduleSelectOption;
        }
        this.setState({ items: newModuleUploadItemsMap });
    };
}

async function newModuleDialog(
    moduleName: string,
    moduleManufacturer: string,
    moduleTeamId: number,
    similarModules: mod.MatchedModule[],
) {
    return await promptModalForm<INewModuleForm>({
        title: 'Create New Module',
        Component: NewModuleForm,
        componentProps: {
            moduleName,
            moduleManufacturer,
            moduleTeamId,
            similarModules,
        },
        cancellable: true,
        submitLabel: 'Save',
        submitIntent: PrimaryIntent.SAVE,
    });
}

const mapStateToProps = (state) => ({
    user: userSelectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    panFileToCharOrSaveCharToModule: mod.charApi.panFileToCharOrSaveCharToModule,
    createNewModule: mod.api.create,
}));

export default connect(mapStateToProps, mapDispatchToProps)(ModuleUploadLauncher);
