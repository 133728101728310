import * as React from 'react';

import { RouteComponent } from 'reports/routing';

import ModuleLibrary from 'reports/modules/module/components/ModuleLibrary';
import ModulePreview from 'reports/modules/module/components/ModulePreview';

const Library: React.FC = () => (
    <>
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.modules" exact={false}>
                    <ModuleLibrary
                        preview={
                            <RouteComponent name="app.modules.module.preview">
                                {({ module, characterization }) => (
                                    <ModulePreview module={module} characterization={characterization} />
                                )}
                            </RouteComponent>
                        }
                    />
                </RouteComponent>
            </div>
        </div>
    </>
);

export default Library;
