import * as React from 'react';
import { useDispatch } from 'react-redux';

import { isEmpty } from 'lodash';

import classNames from 'classnames';

import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

import { Toaster } from 'reports/modules/Toaster';

import { RequiredFieldError } from 'reports/modules/settings/common';
import { Form } from 'reports/components/forms';
import FormTextInput from 'reports/components/forms/inputs/experimental/FormTextInput';
import { FormErrorCallout } from 'reports/components/helpers/errors';

import { api as subscriptionApi, Subscription } from 'reports/models/subscription';
import { api as teamApi, Team } from 'reports/models/team';
import { api as userApi } from 'reports/models/user';

import { Flex } from 'reports/components/core/containers';
import { styled } from 'reports/styles/styled-components';

interface Props {
    handleSubmit: () => void;
    handleClose: () => void;
    isOpen: boolean;
    subscription: Subscription;
    team: Team;
}

interface FormData {
    email: string;
    firstName: string;
    lastName: string;
}

const DialogBody = styled(Flex.ContainerV)`
    padding: 32px 24px 16px 16px;
    width: 400px;
    height: 460px;
`;

const StyledTextInput = styled(FormTextInput).attrs({
    fill: true,
})`
    .${Classes.INPUT} {
        height: 38px;
        border-radius: 4px;
    }
`;

const DIALOG_WIDTH = 840;

const InviteMemberDialog = ({ handleClose, isOpen, subscription, team }: Props) => {
    const dispatch = useDispatch();
    const isEmailTaken = ({ email }) => dispatch(userApi.isEmailTaken({ email }));
    const refreshSubscription = ({ external_id }) => dispatch(subscriptionApi.refreshCache({ external_id }));
    const reloadTeam = (team: Team) => dispatch(teamApi.get({ team_id: team.team_id }));
    const inviteMember = (formData: FormData, externalId: string) =>
        dispatch(
            subscriptionApi.inviteMember(
                {
                    email: formData.email,
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                },
                { external_id: externalId },
            ),
        );

    const onSubmit = async (formData: FormData) => {
        await validate(formData);
        await inviteMember(formData, subscription.external_id);

        await refreshSubscription({ external_id: subscription.external_id });
        await reloadTeam(team);

        Toaster.show({
            intent: Intent.SUCCESS,
            message: `An invite has been sent to ${formData.email}`,
            timeout: 5000,
        });

        handleClose();
    };

    const exceptionHandler = (exc) => {
        if (exc instanceof RequiredFieldError) {
            return {
                formErrors: [],
                fieldErrors: exc.errors as Partial<{
                    email: string[];
                    billingDetails: string[];
                }>,
            };
        }

        const { error: formError = null, ...fieldErrors } = exc.response.body;

        const { user_id } = fieldErrors;

        const formErrors = formError ? [formError] : user_id ? [user_id] : [];

        return {
            fieldErrors,
            formErrors,
        };
    };

    const validate = async ({ email, firstName, lastName }: FormData): Promise<void> => {
        const { taken } = !!email ? await isEmailTaken({ email }) : { taken: false };
        const errors = {
            ...(email === '' && { email: ['Email is required.'] }),
            ...(taken && { email: ['Email is taken already.'] }),
            ...(firstName === '' && { firstName: ['First Name is required.'] }),
            ...(lastName === '' && { lastName: ['Last Name is required.'] }),
        };

        if (!isEmpty(errors)) {
            throw new RequiredFieldError(errors);
        }
    };

    return (
        <Form
            baseValue={{ email: '', firstName: '', lastName: '' }}
            exceptionHandler={exceptionHandler}
            onSubmit={onSubmit}
        >
            {({ clearForm, formData, formErrors, submitting, submitForm }) => {
                const onClose = () => {
                    clearForm();
                    handleClose();
                };

                return (
                    <Dialog
                        isOpen={isOpen}
                        onClose={onClose}
                        style={{ width: DIALOG_WIDTH }}
                        title="Invite Team Member"
                    >
                        <DialogBody>
                            <StyledTextInput
                                disabled={submitting}
                                autoFocus
                                path="email"
                                label="Email"
                                placeholder="Email"
                                type="email"
                            />
                            <div>
                                <StyledTextInput
                                    disabled={submitting}
                                    path="firstName"
                                    label="Name"
                                    placeholder="First Name"
                                    type="text"
                                />
                                <StyledTextInput
                                    disabled={submitting}
                                    path="lastName"
                                    placeholder="Last Name"
                                    type="text"
                                />
                            </div>
                            {formErrors.length > 0 && (
                                <FormErrorCallout errorMsg={formErrors[0]} style={{ marginTop: '15px' }} />
                            )}
                        </DialogBody>

                        <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                            <Button intent={Intent.NONE} onClick={onClose} text="Cancel" />
                            <Button
                                intent={Intent.PRIMARY}
                                onClick={() => submitForm(formData)}
                                text="Invite Team Member"
                                loading={submitting}
                                disabled={submitting || !formData.email || !formData.firstName || !formData.lastName}
                            />
                        </div>
                    </Dialog>
                );
            }}
        </Form>
    );
};

export default InviteMemberDialog;
