import * as mod from 'reports/models/module';
import { User } from 'reports/models/user';
import { helioscopeAdmin } from '../auth/permissions';
import { IUploadItem } from '../files/batch_upload/common';

function isNonAdminWithPublicModule(user: User, selectedModule?: ISelectedModule): boolean {
    return !!selectedModule && selectedModule.public && !helioscopeAdmin(user);
}

interface IModuleUploadItem extends IUploadItem {
    matchedChar: mod.MatchedCharacterization | null;
    newModuleName?: string;
    selectedModule?: ISelectedModule;
    savedChar?: mod.ModuleCharacterization;
}

const USER_DEFINED_MODULE_ID = -1;

interface ISelectedModule {
    module_name: string;
    module_id: number | null;
    public: boolean;
    manufacturer: string;
    _match_quality: number;
    source?: string;
}

interface IModuleUploadItemsMap {
    [key: string]: IModuleUploadItem;
}

interface INewModuleForm {
    newModuleName: string;
}

export {
    isNonAdminWithPublicModule,
    IModuleUploadItem,
    USER_DEFINED_MODULE_ID,
    IModuleUploadItemsMap,
    ISelectedModule,
    INewModuleForm,
};
