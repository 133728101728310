import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { withRoute } from 'react-router5';
import Dropzone from 'react-dropzone';

import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';
import { IWithRouteProps } from 'reports/utils/router';

import * as auth from 'reports/modules/auth';
import * as mod from 'reports/models/module';
import { IAPIQueryOpts } from 'reports/models/types';
import { LibraryMain } from 'reports/components/library/main/components';
import FilterSelect from 'reports/components/library/main/components/search/FilterSelect';

import ModuleList from 'reports/modules/module/components/ModulesList';
import { Toaster } from 'reports/modules/Toaster';
import { Intent } from '@blueprintjs/core';
import ModuleUploadLauncher from 'reports/modules/module/components/upload/ModuleUploadLauncher';
import * as cfg from 'reports/config';

interface IOwnProps {
    preview?: React.ReactNode;
}

interface IDispatchProps {
    loadItems: (opts: IAPIQueryOpts) => Promise<mod.Module[]>;
    navigateTo: (name: string, params?: any) => any;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps & IWithRouteProps;

interface IState {
    characterizationFile?: File;
    isUploadDialogOpen: boolean;
}

class ModuleLibrary extends React.PureComponent<IProps, IState> {
    dropzoneRef = React.createRef<Dropzone>();

    state: IState = { isUploadDialogOpen: false };

    onDrop = async (acceptedFiles: File[], rejectedFiles: File[], event) => {
        event.stopPropagation();
        if (rejectedFiles.length > 0) {
            Toaster.show({
                message: `Error uploading ${rejectedFiles[0].name}: invalid file type.`,
                intent: Intent.DANGER,
            });
            return;
        }
        const file = acceptedFiles[0];
        if (!file?.name.toLowerCase().endsWith('.pan')) {
            Toaster.show({
                message: `Error uploading ${file.name}: we expected a filename ending in .pan`,
                intent: Intent.DANGER,
            });
            return;
        }
        this.setState({ characterizationFile: file });
    };

    render() {
        const { team, preview, route, loadItems, refreshItem } = this.props;
        return (
            <LibraryMain
                resourceIdName="module_id"
                loadItems={loadItems}
                refreshItem={refreshItem}
                views={[
                    {
                        view: <ModuleList />,
                        icon: IconNames.LIST,
                        id: 'module-list',
                    },
                ]}
                id="modules-views"
                quickFilters={[
                    {
                        name: 'favorite',
                        icon: IconNames.STAR,
                        text: 'Favorited',
                    },
                    {
                        name: 'team_id',
                        icon: IconNames.PEOPLE,
                        text: `Only Show My Team`,
                        value: team.team_id,
                    },
                ]}
                extraFilterControls={<CellTechnologySelect />}
                preview={preview}
                contextBarControls={<ModuleUploadLauncher />}
                selectedItemId={route.params.moduleId}
            />
        );
    }
}

const CellTechnologySelect = () => (
    <FilterSelect
        label="Filter by Cell Technology"
        items={Object.values(mod.CellTechnologyTypes)}
        emptyValue={'Show All'}
        filterName={'cell_technology_name'}
        buttonProps={{ style: { minWidth: '220px' } }}
    />
);

const mapStateToProps = (state) => ({
    refreshItem: (item) =>
        mod.selectors.byObject(state, item, ['team', 'default_characterization', 'user_default_characterization']) ||
        item,
    team: auth.selectors.getUser(state)!.team,
    config: cfg.selectors.getConfig(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    loadItems: mod.api.index,
    navigateTo: routerActions.navigateTo,
}));

export default connect(mapStateToProps, mapDispatchToProps)(withRoute(ModuleLibrary));
