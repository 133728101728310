import * as React from 'react';

import { Button, HTMLTable, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Subsection, SubsectionHeader } from './OverviewPanel';
import * as ur from 'reports/models/utility_rate';
import { INumericConfig, ParamNumeric } from 'reports/modules/financials/components/ParamNumeric';
import { ParamValueType } from 'reports/modules/financials/params';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';
import FormattedEditor from 'reports/components/helpers/FormattedEditor';

interface IParamMap {
    flat: INumericConfig;
    kwh: INumericConfig;
    day: INumericConfig;
    percent: INumericConfig;
}

const params: IParamMap = {
    flat: {
        type: ParamValueType.Currency,
        min_value: 0.0,
        max_value: 9999.0,
        default: 0.0,
        precision: 4,
    },
    kwh: {
        type: ParamValueType.Currency,
        min_value: 0.0,
        max_value: 9999.0,
        default: 0.0,
        precision: 4,
    },
    day: {
        type: ParamValueType.Currency,
        min_value: 0.0,
        max_value: 9999.0,
        default: 0.0,
        precision: 4,
    },
    percent: {
        type: ParamValueType.Percentage,
        min_value: 0.0,
        max_value: 1.0,
        default: 0.0,
        precision: 4,
    },
};
type ChargeType = keyof IParamMap;
const chargeTypes = Object.keys(params) as ChargeType[];
const chargeTypeNames = {
    flat: '$/month',
    kwh: '$/kwh',
    day: '$/day',
    percent: '% subtotal',
};

interface IOwnProps {
    fixedCharges: ur.IFixedCharge[];
    onUpdate: (propPath: string, val: any) => void;
}

const FixedCharges: React.FunctionComponent<IOwnProps> = ({ fixedCharges, onUpdate }) => {
    const addRow = () => onUpdate('fixed_rates', [...fixedCharges, { type: 'flat', amount: 0, name: '' }]);
    const deleteRow = (idx: number) =>
        onUpdate(
            'fixed_rates',
            fixedCharges.filter((_, i) => i !== idx),
        );
    const editRow = (idx: number, charge: ur.IFixedCharge) => {
        onUpdate(
            'fixed_rates',
            fixedCharges.map((c, i) => (i === idx ? charge : c)),
        );
    };

    return (
        <Subsection>
            <SubsectionHeader title="Recurring Charges" />
            <div style={{ fontSize: 12 }}>
                These are additional fixed or volumetric charges on top of energy charges that are used to cover a
                variety of utility costs and/or taxes.
            </div>
            <HTMLTable>
                {fixedCharges.map(({ type, amount, name }, idx) => (
                    <tr>
                        <td style={{ verticalAlign: 'middle' }}>
                            <FormattedEditor
                                value={name || ''}
                                onConfirm={(val: string) => editRow(idx, { type, amount, name: val })}
                                formatOnConfirm={(val) => val.trim()}
                                formatOnEdit={(val) => val.trim()}
                                formatter={(val) => val.trim()}
                                selectAllOnFocus
                            />
                        </td>
                        <td>
                            <BasicSelect<ChargeType>
                                dataSource={{
                                    items: chargeTypes,
                                }}
                                itemRenderer={(item: ChargeType) => ({
                                    key: item,
                                    text: chargeTypeNames[item],
                                })}
                                value={type}
                                onChange={(item) => editRow(idx, { amount, name, type: item })}
                                fill
                                matchSelectWidth
                            />
                        </td>
                        <td>
                            <ParamNumeric
                                parameter={params[type]}
                                value={amount}
                                updateFn={(val) => editRow(idx, { type, name, amount: val })}
                            />
                        </td>
                        <td>
                            <Button
                                minimal
                                icon={IconNames.TRASH}
                                onClick={() => deleteRow(idx)}
                                intent={Intent.DANGER}
                            />
                        </td>
                    </tr>
                ))}
                {fixedCharges.length === 0 ? (
                    <tr>
                        <td>
                            <Button icon={IconNames.PLUS} onClick={addRow}>
                                Add Recurring Charge
                            </Button>
                        </td>
                    </tr>
                ) : (
                    <tr>
                        <td />
                        <td />
                        <td />
                        <td>
                            <Button outlined icon={IconNames.PLUS} onClick={addRow} />
                        </td>
                    </tr>
                )}
            </HTMLTable>
        </Subsection>
    );
};

export default FixedCharges;
