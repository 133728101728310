import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { IAppState } from 'reports/types';

import { SortType } from 'reports/components/core/tables/DataTable';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';
import { CommonColumns } from 'reports/components/library/helpers';

import * as inc from 'reports/models/incentive';
import * as auth from 'reports/modules/auth';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;

const IncentivesList: React.SFC<IDispatchProps & IStateProps> = ({ openEditor, user }) => (
    <ResourceTable<inc.Incentive>
        columns={[
            CommonColumns.name(),
            {
                sort: { name: 'incentive_type', type: SortType.ALPHA },
                headerText: 'Incentive Type',
                renderCell: (incentive) => <>{inc.IncentiveTypes[incentive.incentive_type]}</>,
            },
            CommonColumns.lastModified(),
            CommonColumns.created(),
            CommonColumns.creator(),
            CommonColumns.team(),
            CommonColumns.publicCol(user),
        ]}
        onRowClick={(incentive) => openEditor(incentive)}
    />
);

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = {
    openEditor: ({ incentive_id }) => {
        return routerActions.navigateTo('app.incentives.incentive', {
            incentiveId: incentive_id,
        });
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(IncentivesList);
