import * as React from 'react';

import { InputGroup, Checkbox, NumericInput, Tag } from '@blueprintjs/core';

import { RouterStateLock } from 'reports/utils/router';

import { Flex, Section2 } from 'reports/components/core/containers';
import { LinkButtonWithParams, SaveCancelButtons } from 'reports/components/core/controls';
import { FormGroup } from 'reports/components/core/forms';
import { CheckboxInput, Form, FormInput, NestedFields, handleRequestException } from 'reports/components/forms';
import { FormBareSwitchInput } from 'reports/components/forms/inputs/experimental';

import * as teams from 'reports/models/team';
import * as usr from 'reports/models/user';
import * as rep from 'reports/models/report';
import * as thm from 'reports/modules/themes';

import {
    SectionContainer,
    CategoryTitle,
    SubSectionContainer,
    HelperText,
    ControlLabel,
    ControlPrimaryText,
    ControlContainer,
} from 'reports/modules/settings/common';

import CurrencySelect from './CurrencySelect';
import LocaleSelect from './LocaleSelect';
import WeatherSourceTypeSelect from './WeatherSourceTypeSelect';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const StyledSelect = styled(FormInput)`
    width: 380px;
`;

const StyledCheckbox = styled(CheckboxInput)`
    margin-top: 0px;
    margin-bottom: 0px;
`;

export interface ITeamOverviewFormProps {
    reports: rep.Report[];
    team: teams.Team;
    user: usr.User;
    authUser: usr.User;
    onSubmit: (formData: teams.ITeamForm) => any;
    disabled: boolean;
}

const TeamOverviewForm = ({ disabled, reports, onSubmit, team, authUser }: ITeamOverviewFormProps) => {
    const exportSettingsBaseValues = teams.defaultExportSettings(reports);
    return (
        <Form<teams.Team>
            baseValue={{
                ...team,
                // Need to make a deep copy of the nested report_ids list
                project_export_settings: team.project_export_settings
                    ? {
                          ...team.project_export_settings,
                          report_ids: [...team.project_export_settings.report_ids],
                      }
                    : exportSettingsBaseValues,
            }}
            onSubmit={onSubmit}
            // allows proper handling of Promises.all in onSubmit
            exceptionHandler={handleRequestException}
        >
            {({ dirty, submitForm, formData, updateValue, clearForm }) => (
                <>
                    <RouterStateLock
                        title="Save Team Settings?"
                        prompt="You have unsaved changes on team settings."
                        cancellable={true}
                        showExitPrompt={dirty}
                        onSave={submitForm}
                        onDontSave={clearForm}
                    />
                    <Section2 title="Team Information">
                        <Flex.Container>
                            <CategoryTitle>Company Name</CategoryTitle>
                            <div style={{ width: 540 }}>
                                <FormInput
                                    path="name"
                                    placeholder={team.name}
                                    defaultValue={team.name}
                                    inputComponent={InputGroup}
                                    inputProps={{
                                        inline: true,
                                    }}
                                    disabled={disabled && !authUser.is_admin}
                                />
                            </div>
                        </Flex.Container>
                    </Section2>

                    <Section2 title="Permissions and Sharing">
                        <Flex.Container>
                            <ControlLabel>
                                <ControlPrimaryText>Project Sharing</ControlPrimaryText>
                                <HelperText>
                                    Share projects automatically between members of the team. If this is disabled, users
                                    must explicitly add others to the project. Team admins can always see all projects
                                    for the team.
                                </HelperText>
                            </ControlLabel>
                            <ControlContainer>
                                <FormBareSwitchInput path="share_projects" disabled={disabled} />
                            </ControlContainer>
                        </Flex.Container>
                    </Section2>

                    <Section2 title="Preferences">
                        <SectionContainer>
                            <CategoryTitle>General</CategoryTitle>
                            <SubSectionContainer>
                                <FormGroup
                                    label="Currency"
                                    helperText="Default currency used across the application."
                                    inline={true}
                                >
                                    <StyledSelect
                                        path="currency"
                                        inputComponent={CurrencySelect}
                                        inputProps={{
                                            selectedItem: formData.currency,
                                            onItemSelect: (currency) => updateValue('currency', currency),
                                        }}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Locale"
                                    inline={true}
                                    helperText="Default regional language used for number formatting."
                                >
                                    <StyledSelect
                                        path="locale"
                                        inputComponent={LocaleSelect}
                                        inputProps={{
                                            selectedItem: formData.locale,
                                            onItemSelect: (locale) => updateValue('locale', locale),
                                        }}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                                <Flex.Container>
                                    <FormGroup
                                        label="Theme"
                                        inline={true}
                                        helperText="Default reports theme for your team."
                                    >
                                        <StyledSelect
                                            path="theme"
                                            inputComponent={thm.ThemeSelect}
                                            inputProps={{
                                                selectedTheme: formData.theme,
                                                onItemSelect: (theme) => {
                                                    updateValue('theme', theme);
                                                    updateValue('theme_id', theme.theme_id);
                                                },
                                            }}
                                            disabled={disabled}
                                        />
                                    </FormGroup>
                                    <div>
                                        <LinkButtonWithParams
                                            text="Manage Themes"
                                            routeName="app.settings.team.themes"
                                            style={{ marginLeft: 5 }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </Flex.Container>
                            </SubSectionContainer>
                        </SectionContainer>
                        <SectionContainer>
                            <CategoryTitle>Weather Files</CategoryTitle>
                            <SubSectionContainer>
                                <FormGroup label="Preferred File Type" inline={true}>
                                    <StyledSelect
                                        path="preferred_weather_source_type"
                                        inputComponent={WeatherSourceTypeSelect}
                                        inputProps={{
                                            selectedType: formData.preferred_weather_source_type,
                                            onItemSelect: (weatherSourceType) => {
                                                updateValue('preferred_weather_source_type', weatherSourceType);
                                                if (weatherSourceType == null) {
                                                    updateValue('preferred_weather_source_radius', null);
                                                } else {
                                                    if (formData.preferred_weather_source_radius == null) {
                                                        updateValue('preferred_weather_source_radius', 80467);
                                                    }
                                                }
                                            },
                                        }}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                                {formData.preferred_weather_source_type != null && (
                                    <FormGroup label="Weather File Radius" inline={true}>
                                        <FormInput
                                            path="preferred_weather_source_radius"
                                            inputComponent={NumericInput}
                                            defaultValue={80467}
                                            inputProps={{
                                                inline: true,
                                                placeholder: 'Distance',
                                                buttonPosition: 'none',
                                                rightElement: <Tag>meters</Tag>,
                                                value: formData.preferred_weather_source_radius,
                                                onValueChange: (val) =>
                                                    updateValue('preferred_weather_source_radius', val),
                                            }}
                                            disabled={disabled}
                                            style={{ width: 380 }}
                                        />
                                    </FormGroup>
                                )}
                            </SubSectionContainer>
                        </SectionContainer>
                        <SectionContainer>
                            <CategoryTitle>Project Exports</CategoryTitle>
                            <SubSectionContainer>
                                <NestedFields path="project_export_settings">
                                    <FormGroup label="Design Files" inline={true}>
                                        <StyledCheckbox
                                            label="Design Render"
                                            path="design_render"
                                            disabled={disabled}
                                        />
                                        <StyledCheckbox
                                            label="Layout CAD"
                                            path="design_layout_cad"
                                            disabled={disabled}
                                        />
                                        <StyledCheckbox
                                            label="Single-Line Diagram"
                                            path="design_sld"
                                            disabled={disabled}
                                        />
                                    </FormGroup>
                                    <FormGroup label="Energy Simulation" inline={true}>
                                        <StyledCheckbox
                                            label="Hourly Performance Data"
                                            path="sim_hourly_results"
                                            disabled={disabled}
                                        />
                                    </FormGroup>
                                    <FormGroup label="Reports" inline={true}>
                                        {reports.length > 0 ? (
                                            reports.map((report) => {
                                                const reportIds = formData.project_export_settings.report_ids;
                                                return (
                                                    <FormInput
                                                        path="report_ids"
                                                        inputComponent={Checkbox}
                                                        inputProps={{
                                                            label: report.name,
                                                            checked: reportIds.includes(report.report_id),
                                                            onChange: () => {
                                                                if (reportIds.includes(report.report_id)) {
                                                                    const index = reportIds.indexOf(report.report_id);
                                                                    if (index > -1) {
                                                                        reportIds.splice(index, 1);
                                                                        updateValue('report_ids', [...reportIds]);
                                                                    }
                                                                } else {
                                                                    reportIds.push(report.report_id);
                                                                    updateValue('report_ids', [...reportIds]);
                                                                }
                                                            },
                                                        }}
                                                        key={report.report_id}
                                                        disabled={disabled}
                                                        style={{
                                                            marginTop: 0,
                                                            marginBottom: 0,
                                                        }}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <p style={{ marginTop: 5 }}>No bookmarked reports available</p>
                                        )}
                                    </FormGroup>
                                </NestedFields>
                            </SubSectionContainer>
                        </SectionContainer>
                    </Section2>

                    <SaveCancelButtons
                        onSave={submitForm}
                        hasChanges={dirty}
                        onCancel={clearForm}
                        style={{ marginBottom: 10 }}
                    />
                </>
            )}
        </Form>
    );
};

export default TeamOverviewForm;
