import * as React from 'react';

import { IconNames } from '@blueprintjs/icons';

import { RouteComponent } from 'reports/routing';

import { RedirectHelperContainer } from 'reports/components/helpers/common';

import Toaster from 'reports/modules/Toaster';

import UtilityRateEditor from 'reports/modules/utility_rate/components/UtilityRateEditor';
import UtilityRateLibrary from '../components/UtilityRateLibrary';

const Library: React.SFC = () => {
    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.utility-rates">
                    <UtilityRateLibrary />
                </RouteComponent>
                <RouteComponent name="app.utility-rates.utility-rate">
                    {({ utilityRate }) => {
                        if (!utilityRate) {
                            return (
                                <RedirectHelperContainer
                                    name="app.utility-rates"
                                    params={{}}
                                    callback={() =>
                                        Toaster.show({
                                            icon: IconNames.WARNING_SIGN,
                                            message: <div>Utility rate does not exist</div>,
                                            timeout: 2500,
                                        })
                                    }
                                />
                            );
                        }

                        return <UtilityRateEditor utilityRate={utilityRate} />;
                    }}
                </RouteComponent>
            </div>
        </div>
    );
};

export default Library;
