import { Classes, Dialog, Intent, ProgressBar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import { Flex } from 'reports/components/core/containers';
import { CreateButton, PrimaryButton } from 'reports/components/core/controls';
import DropzoneContainer from 'reports/modules/files/components/DropzoneContainer';
import { Toaster } from 'reports/modules/Toaster';
import { IUploadItem } from './common';
import BatchUploadTable, { IProps as BatchUploadTableProps } from './BatchUploadTable';

const StyledDialog = styled(Dialog)`
    width: 90%;
    min-width: 800px;
`;

const DialogBody = styled.div.attrs({ className: Classes.DIALOG_BODY })`
    .mainActions {
        margin-top: 20px;
        margin-bottom: 8px;
    }
    button {
        margin-left: 0;
    }
    .nowrap {
        white-space: nowrap;
    }
`;

interface IProps<T extends IUploadItem> {
    title: string;
    acceptTypes: string;
    maxSize: number;
    description: React.ReactNode;
    items: T[];
    isOpen: boolean;
    onClose: (event: React.SyntheticEvent<HTMLElement>) => void;
    onSelectFiles: (files: File[]) => Promise<void>;
    uploadItem: (item: T) => Promise<void>;
    tableProps: Omit<BatchUploadTableProps<T>, 'items' | 'uploadItem'>;
}

// Abstracted for shared use with weather source upload
export class BatchUploadDialog<T extends IUploadItem> extends React.PureComponent<IProps<T>> {
    dropzoneRef = React.createRef<Dropzone>();

    render() {
        const { isOpen, onClose, items, description, title, acceptTypes, maxSize, uploadItem, tableProps } = this.props;
        const uploadingItemsCount = items.filter((i) => i.status === 'uploading').length;
        const loadingItemsCount = items.filter((i) => i.status === 'loading').length;
        return (
            <StyledDialog title={title} isOpen={isOpen} onClose={onClose}>
                {uploadingItemsCount > 0 && (
                    <ProgressBar
                        intent={Intent.PRIMARY}
                        stripes={false}
                        value={(items.length - uploadingItemsCount) / items.length}
                    />
                )}
                {loadingItemsCount > 0 && (
                    <ProgressBar
                        intent={Intent.WARNING}
                        stripes={false}
                        value={(items.length - loadingItemsCount) / items.length}
                    />
                )}
                <DialogBody>
                    <DropzoneContainer
                        ref={this.dropzoneRef}
                        onDrop={this.onDrop}
                        accept={acceptTypes}
                        maxSize={maxSize}
                        multiple={true}
                        disableClick={true}
                    >
                        {description}
                        <Flex.Container align={Flex.Align.SPACE_BETWEEN} className="mainActions">
                            <PrimaryButton
                                text="Select Files"
                                icon={IconNames.ADD}
                                onClick={() => this.dropzoneRef.current!.open()}
                            />
                            <CreateButton
                                text="Upload All Files"
                                icon={IconNames.UPLOAD}
                                disabled={items.length === 0}
                                onClick={() => this.uploadItems(items)}
                            />
                        </Flex.Container>
                        <BatchUploadTable<T> items={items} uploadItem={uploadItem} {...tableProps} />
                    </DropzoneContainer>
                </DialogBody>
            </StyledDialog>
        );
    }

    uploadItems = (items: T[]) => {
        for (const item of items) {
            if (item.status === 'staged') {
                this.props.uploadItem(item);
            }
        }
    };

    onDrop = async (acceptedFiles: File[], rejectedFiles: File[], event) => {
        event.stopPropagation();
        if (rejectedFiles.length > 0) {
            Toaster.show({
                message: `Error uploading ${rejectedFiles[0].name}: invalid file type.`,
                intent: Intent.DANGER,
            });
            return;
        }
        this.props.onSelectFiles(acceptedFiles);
    };
}

export default BatchUploadDialog;
