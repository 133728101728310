import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import styled from 'styled-components';

import { IAppState } from 'reports/store';
import { fromNow } from 'reports/utils/formatters';
import { bindActions } from 'reports/utils/redux';

import { Flex } from 'reports/components/core/containers';
import Section from 'reports/components/core/containers/Section2';
import { FavoriteStar, PublishButton } from 'reports/components/core/controls';
import { LibraryPreview } from 'reports/components/core/layout';
import { PreviewDrawer } from 'reports/components/library/main/components';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import * as auth from 'reports/modules/auth';
import * as mod from 'reports/models/module';
import { canMakeResourcePublic } from 'reports/modules/auth/permissions';

import CharacterizationSpecs from './CharacterizationSpecs';
import CharacterizationViewControls from './CharacterizationViewControls';
import ModeledPerformance from './ModeledPerformance';
import ModuleSpecs from './ModuleSpecs';
import RawParameters from './RawParameters';

const RightPanel = styled(Flex.ContainerV)`
    flex: 1;
    margin-left: 20px;
`;

interface IOwnProps {
    module: mod.Module;
    characterization: mod.ModuleCharacterization;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IDispatchProps & IStateProps;

interface IState {
    drawerOpen: boolean;
}

export const ModulePreviewContents: React.FC<IOwnProps> = ({ module, characterization }) => (
    <Flex.Container>
        <Flex.ContainerV>
            <Section title="Module Model">
                <ModuleSpecs module={module} />
            </Section>
            <Section title="Module Characterization">
                <CharacterizationSpecs characterization={characterization} />
            </Section>
        </Flex.ContainerV>
        <RightPanel>
            <Section title="Modeled Performance">
                <ModeledPerformance characterization={characterization} />
            </Section>
            <Section title="Raw Parameters">
                <RawParameters characterization={characterization} />
            </Section>
        </RightPanel>
    </Flex.Container>
);

class ModulePreview extends React.PureComponent<IProps, IState> {
    state: IState = {
        drawerOpen: true,
    };

    render() {
        const { module, characterization, user, navigateTo, toggleFavorite, togglePublic } = this.props;
        const { favorite, last_update } = module;

        return (
            <PreviewDrawer
                isOpen={this.state.drawerOpen}
                onClose={() => this.setState({ drawerOpen: false })}
                onClosed={() => navigateTo('app.modules')}
                title={
                    <LibraryPreview.Header
                        title={module.name}
                        subtitle={last_update && `Last modified ${fromNow(last_update)}`}
                        favorite={
                            <FavoriteStar
                                empty={!favorite}
                                onClick={() => {
                                    addPromiseToastsToggle(
                                        'favorite',
                                        toggleFavorite(),
                                        'module',
                                        module.name,
                                        favorite,
                                    );
                                }}
                            />
                        }
                    />
                }
                publishButton={
                    <PublishButton
                        isPublic={module.public}
                        resourceName={module.name}
                        resourceType={'module'}
                        privatePrompt={
                            <p>
                                Making this module private will also revoke access to this resource for any HelioScope
                                users that are not on the Folsom Labs team and are currently using this module in their
                                designs.
                            </p>
                        }
                        publicPrompt={
                            <p>
                                Making this module public will give all HelioScope users access to this module. Everyone
                                will be able to find and use this module in their designs, but not make any changes to
                                the resource itself.
                            </p>
                        }
                        togglePublic={togglePublic}
                        disabled={!canMakeResourcePublic(user, module)}
                    />
                }
                widthInPercent={80}
                viewControls={<CharacterizationViewControls module={module} selectedChar={characterization} />}
            >
                <div style={{ padding: 10 }}>
                    <ModulePreviewContents module={module} characterization={characterization} />
                </div>
            </PreviewDrawer>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(({ module }: IOwnProps) => ({
    navigateTo: routerActions.navigateTo,
    toggleFavorite: () => {
        const { module_id, favorite } = module;
        return mod.api.save({ module_id, favorite: !favorite });
    },
    togglePublic: () => {
        const { module_id, public: isPublic } = module;
        return mod.api.save({ module_id, public: !isPublic });
    },
}));

export default connect(mapStateToProps, mapDispatchToProps)(ModulePreview);
