import * as React from 'react';

import * as DS from '@aurorasolar/ds';
import * as styles from 'reports/styles/styled-components';

import { HelioScopeTheme } from 'reports/styles/theme';

import { WIRING_UNITS } from 'reports/models/wire';
import { useWiringTableData } from './Hooks';

const styled = styles.styled;

const WiringPage = styled(DS.Page)`
    nav {
        display: none;
    }
`;

const WiringLibrary = () => {
    const { columns, data } = useWiringTableData();

    const tableProps = {
        columns,
        overrideTableOptions: {
            manualPagination: true,
        },
    };
    const tabs = [
        {
            key: 'awg',
            label: 'American wire gauge',
            view: () => <DS.Table {...tableProps} data={data.filter((row) => row.units === WIRING_UNITS.awg)} />,
        },
        {
            key: 'metric',
            label: 'Metric (mm2)',
            view: () => <DS.Table {...tableProps} data={data.filter((row) => row.units === WIRING_UNITS.metric)} />,
        },
    ];

    return (
        <DS.StyleProvider colorMode="light" theme={HelioScopeTheme}>
            <WiringPage bg="white">
                <DS.PageTemplate bg="white" header={<DS.Text text="h1">Wires</DS.Text>}>
                    <DS.TabbedView applySystemPadding={true} fullWidth={false} tabs={tabs} />
                </DS.PageTemplate>
            </WiringPage>
        </DS.StyleProvider>
    );
};

export { WiringLibrary };
