import * as React from 'react';
import { Intent, Tag } from '@blueprintjs/core';

import { Section2 } from 'reports/components/core/containers';

import AddTeamMember from 'reports/modules/settings/admin/components/AddTeamMember';

import { Team } from 'reports/models/team';

interface Props {
    team: Team;
}

const MergeTeams = ({ team }: Props) => (
    <Section2 title="Merge Team Users" contextEl={<Tag intent={Intent.DANGER}>HelioScope admin only</Tag>}>
        <AddTeamMember team={team} />
    </Section2>
);

export default MergeTeams;
