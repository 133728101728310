import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';
import { actions as routerActions } from 'redux-router5';

import { IAppState } from 'reports/types';

import { LibraryConsumer } from 'reports/components/library/main/context';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';
import { CommonColumns } from 'reports/components/library/helpers';
import { ColWidth } from 'reports/components/core/tables/DataTable';
import { FavoriteStar } from 'reports/components/core/controls';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import * as pro from 'reports/models/profile';
import * as auth from 'reports/modules/auth';
import * as analytics from 'reports/analytics';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

const ProfilesList: React.FC<IDispatchProps & IStateProps> = ({
    deleteProfile,
    openPreview,
    setBookmarked,
    user,
    isProfileInUse,
}) => (
    <LibraryConsumer>
        {({ updateSearch }) => (
            <ResourceTable<pro.Profile>
                columns={[
                    {
                        colWidth: ColWidth.SMALL,
                        headerText: 'Favorite',
                        renderCell: (profile) => (
                            <FavoriteStar
                                empty={!profile.bookmarked()}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addPromiseToastsToggle(
                                        'favorite',
                                        setBookmarked(profile, !profile.bookmarked()),
                                        'profile',
                                        profile.name,
                                        profile.bookmarked(),
                                    );
                                    analytics.track('profile.favorite', {
                                        profile_id: profile.profile_id,
                                        profile_type: profile.type,
                                    });
                                }}
                            />
                        ),
                    },
                    CommonColumns.name(),
                    {
                        headerText: 'Profile Type',
                        renderCell: (profile) => <>{pro.ProfileTypes[profile.type]}</>,
                    },
                    CommonColumns.lastModified(),
                    CommonColumns.created(),
                    CommonColumns.creator(),
                    CommonColumns.team(),
                    CommonColumns.publicCol(user),
                    CommonColumns.actionsCol(
                        user,
                        {
                            title: 'Delete Profile',
                            prompt: `Deleting this profile will remove
                                it for all users on your team.
                                Would you like to delete this profile?`,
                            resName: 'Profile',
                            checkFn: async (profile) => {
                                const resp = await isProfileInUse({
                                    profile_id: profile.profile_id,
                                });
                                if (resp.is_profile_in_use) {
                                    if (profile.type === 'project') {
                                        return `This profile is currently in use by one or more projects.
                                        You must remove the profile from all projects before you are able to
                                        delete this profile.`;
                                    }
                                    return `This profile is currently in use by one or more project profiles.
                                    You must remove the profile from all project profiles before you are able
                                    to delete this profile.`;
                                }
                                return null;
                            },
                        },
                        async (profile) => {
                            await deleteProfile(profile);
                            analytics.track('profile.delete', {
                                profile_id: profile.profile_id,
                                profile_type: profile.type,
                            });
                        },
                        updateSearch,
                    ),
                ]}
                onRowClick={(profile) => openPreview(profile)}
            />
        )}
    </LibraryConsumer>
);

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions({
    openPreview: ({ profile_id }) =>
        routerActions.navigateTo('app.profiles.profile.preview', {
            profileId: profile_id,
        }),
    deleteProfile: ({ profile_id }) => pro.api.delete({ profile_id }),
    setBookmarked: (profile, bookmarked) => {
        return pro.api.patchTeamSettings({
            bookmarked,
            profile_id: profile.profile_id,
        });
    },
    isProfileInUse: ({ profile_id }) => pro.api.isProfileInUse({ profile_id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesList);
