import * as React from 'react';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import Flex from 'reports/components/core/containers/Flex';

import Section2 from 'reports/components/core/containers/Section2';

import { Subscription } from 'reports/models/subscription';
import * as usr from 'reports/models/user';

import { PlanInfo } from './PlanInfo';
import { TrialPlanInfo } from './TrialPlanInfo';
import UsageGraphContainer from './UsageGraphContainer';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface Props {
    manageBilling: boolean;
    subscription?: Subscription;
    user: usr.User;
}

const ColumnsContainer = styled(Flex.Container)`
    gap: 20px;
`;

const GraphWrapper = styled.div`
    width: 65%;
    // minimum width that keeps all labels showing
    min-width: 330px;
`;

const PlanOverview = ({ subscription, user, manageBilling }: Props) => {
    const title = 'Plan Overview & Usage';

    return !!subscription && subscription.status !== 'incomplete_expired' ? (
        <Section2 title={title}>
            <ColumnsContainer>
                <GraphWrapper>
                    <UsageGraphContainer user={user} />
                </GraphWrapper>
                <PlanInfo
                    manageBilling={manageBilling}
                    subscription={subscription}
                    user={user}
                    referrer={ReferrerTypes.billing_page}
                />
            </ColumnsContainer>
            {!manageBilling && (
                <i>
                    To get more projects for you and your team
                    {subscription.is_self_serve && ' or upgrade your subscription'}, please contact your admin
                </i>
            )}
        </Section2>
    ) : (
        <Section2 title={title}>
            <ColumnsContainer>
                <GraphWrapper>
                    <UsageGraphContainer user={user} />
                </GraphWrapper>
                <TrialPlanInfo
                    manageBilling={manageBilling}
                    periodEnd={user.current_period_end}
                    user={user}
                    referrer={ReferrerTypes.billing_page}
                />
            </ColumnsContainer>
        </Section2>
    );
};

export { PlanOverview };
