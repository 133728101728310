import * as React from 'react';

import { RouteComponent } from 'reports/routing';

import PowerDeviceLibrary from 'reports/modules/power_device/components/PowerDeviceLibrary';
import PowerDevicePreview from 'reports/modules/power_device/components/PowerDevicePreview';

const Library: React.SFC = () => (
    <>
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.power-devices" exact={false}>
                    <PowerDeviceLibrary
                        preview={
                            <RouteComponent name="app.power-devices.power-device.preview">
                                {({ powerDevice, characterization }) => (
                                    <PowerDevicePreview powerDevice={powerDevice} characterization={characterization} />
                                )}
                            </RouteComponent>
                        }
                    />
                </RouteComponent>
            </div>
        </div>
    </>
);

export default Library;
