import * as React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withRoute } from 'react-router5';

import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';

import * as pd from 'reports/models/power_device';
import * as pdChar from 'reports/models/power_device/PowerDeviceCharacterization';
import * as s3file from 'reports/models/s3file';

import * as auth from 'reports/modules/auth';
import { Toaster } from 'reports/modules/Toaster';
import { actions as uploadActions } from 'reports/modules/files/uploads';
import DropzoneContainer from 'reports/modules/files/components/DropzoneContainer';
import PowerDeviceList from 'reports/modules/power_device/components/PowerDevicesList';

import { bindActions } from 'reports/utils/redux';
import { IWithRouteProps } from 'reports/utils/router';
import { BYTES_PER_MEGABYTE } from 'reports/utils/units';

import PowerDeviceUploadDialog from './PowerDeviceUploadDialog';

interface IOwnProps {
    preview?: React.ReactNode;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps & IWithRouteProps;

interface IState {
    uploadedCharacterization?: pd.PowerDeviceCharacterization;
    uploadedS3File?: s3file.S3File;
}

class PowerDeviceLibrary extends React.PureComponent<IProps, IState> {
    dropzoneRef = React.createRef<Dropzone>();

    state: IState = {};

    onDrop = async (acceptedFiles: File[], rejectedFiles: File[], event) => {
        const { uploadFile, tryParse } = this.props;
        event.stopPropagation();
        if (rejectedFiles.length > 0) {
            Toaster.show({
                message: `Error uploading ${rejectedFiles[0].name}: invalid file type.`,
                intent: Intent.DANGER,
            });
            return;
        }
        const file = acceptedFiles[0];
        if (!file?.name.toLowerCase().endsWith('.ond')) {
            Toaster.show({
                message: `Error uploading ${file.name}: we expected a filename ending in .ond`,
                intent: Intent.DANGER,
            });
            return;
        }
        const uploadedS3File = (await uploadFile({
            file,
            meta: { tags: ['power device'] },
        })) as s3file.S3File;
        try {
            const characterization = await tryParse({
                file_id: uploadedS3File.file_id,
            });
            this.setState({
                uploadedS3File,
                uploadedCharacterization: characterization,
            });
        } catch (err) {
            Toaster.show({
                message: `Error parsing ${file.name}`,
                intent: Intent.DANGER,
            });
        }
    };

    render() {
        const { user, preview, route, loadItems, refreshItem } = this.props;
        const { uploadedCharacterization, uploadedS3File } = this.state;
        return (
            <LibraryMain
                resourceIdName="power_device_id"
                loadItems={loadItems}
                refreshItem={refreshItem}
                views={[
                    {
                        view: <PowerDeviceList />,
                        icon: IconNames.LIST,
                        id: 'power-device-list',
                    },
                ]}
                id="power-devices-views"
                quickFilters={[
                    {
                        name: 'favorite',
                        icon: IconNames.STAR,
                        text: 'Favorited',
                    },
                    {
                        name: 'team_id',
                        icon: IconNames.PEOPLE,
                        text: `Only Show My Team`,
                        value: user.team.team_id,
                    },
                    {
                        name: 'device_type',
                        text: `Only Show Inverters`,
                        value: 'inverter',
                    },
                    {
                        name: 'device_type',
                        text: `Only Show Optimizers`,
                        value: 'optimizer',
                    },
                ]}
                preview={preview}
                contextBarControls={
                    <DropzoneContainer
                        ref={this.dropzoneRef}
                        onDrop={this.onDrop}
                        accept=".ond, .OND"
                        maxSize={10 * BYTES_PER_MEGABYTE}
                        disableClick={true}
                    >
                        <CreateButton
                            icon={IconNames.UPLOAD}
                            text="Upload Inverter"
                            onClick={() => this.dropzoneRef.current!.open()}
                            fill={true}
                        />
                        {uploadedCharacterization && (
                            <PowerDeviceUploadDialog
                                uploadedCharacterization={uploadedCharacterization}
                                uploadedPowerDevice={uploadedCharacterization.power_device}
                                uploadedS3File={uploadedS3File}
                            />
                        )}
                    </DropzoneContainer>
                }
                selectedItemId={route.params.powerDeviceId}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    refreshItem: (item) => pd.selectors.byObject(state, item, { team: true }) || item,
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    loadItems: pd.api.index,
    uploadFile: uploadActions.uploadFileWithProgress,
    tryParse: pdChar.api.tryParse,
}));

export default connect(mapStateToProps, mapDispatchToProps)(withRoute(PowerDeviceLibrary));
