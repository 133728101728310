import * as React from 'react';
import { assign } from 'lodash';
import { connect } from 'react-redux';
import { withRoute } from 'react-router5';
import { actions as routerActions } from 'redux-router5';

import { Button, ButtonGroup, Callout, Intent, Switch } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';
import { IAppState, IWithRouteProps } from 'reports/types';
import { RouterStateLock } from 'reports/utils/router';

import { Card, Flex, Section2 } from 'reports/components/core/containers';
import { ContextBarControls } from 'reports/components/ContextBar';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import SaveDropdown from 'reports/components/library/SaveDropdown';
import UpsellBanner from 'reports/components/UpsellBanner';

import { FinancialTemplate, saver } from 'reports/models/financial_template';

import * as auth from 'reports/modules/auth';

import FinancialTemplatePreview from './EditorPreview';
import FinancialTemplateEditor from './Editor';

import { verifyAdvancedPipeline, getLastIncentiveIndex } from 'reports/modules/financials/model/pipeline/utils';
import { FinancialPipelineTree } from 'reports/modules/financials/model/pipeline';
import { actions as templateActions } from 'reports/modules/financials';

interface IOwnProps {
    template: FinancialTemplate;
}

interface IState {
    advancedMode: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IDispatchProps & IStateProps & IWithRouteProps;

class FinancialTemplateEditContainer extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            advancedMode: verifyAdvancedPipeline(FinancialPipelineTree.fromRawData(this.props.template.data.root)),
        };
    }

    render() {
        const { template, user } = this.props;
        const { advancedMode } = this.state;

        const advancedEditorAccess = auth.permissions.canAccessAdvFinancialFeatures(user);
        const pipelineTree = FinancialPipelineTree.fromRawData(template.data.root);
        const advancedTemplate = verifyAdvancedPipeline(pipelineTree);
        const depreciationInFinancing = advancedTemplate && !verifyAdvancedPipeline(pipelineTree, true);

        // TODO: refactor using ButtonGroupSelect
        const AdvancedToggle = (
            <ButtonGroup>
                <Button
                    text="Default"
                    intent={advancedMode ? undefined : Intent.SUCCESS}
                    active={!advancedMode}
                    onClick={() => this.setState({ advancedMode: false })}
                    disabled={advancedTemplate}
                />
                <Button
                    text="Advanced"
                    intent={advancedMode ? Intent.SUCCESS : undefined}
                    active={advancedMode}
                    onClick={() => this.setState({ advancedMode: true })}
                    disabled={advancedTemplate}
                />
            </ButtonGroup>
        );

        const SaveCancelControls = (
            <SaveDropdown
                save={this.saveAndClose}
                saveAsNewOptions={{
                    saveAsNew: this.saveAsNew,
                    text: 'Save as New Financial Model',
                }}
                saveEnabled={
                    this.props.hasChanges() && auth.permissions.canModifySharedTeamResource(this.props.user, template)
                }
                cancel={this.cancel}
                toastDescr={template.description}
            />
        );

        const AdvancedNotification = depreciationInFinancing ? (
            <Callout icon={IconNames.WARNING_SIGN} intent={Intent.WARNING}>
                <Flex.Container>
                    <Flex.Main>
                        Depreciation has been moved from the Financing to the Incentives category. Update your model to
                        use the updated categorization and continue using the default mode for editing.
                    </Flex.Main>
                    <div>
                        <Button
                            text="Update"
                            intent={Intent.WARNING}
                            onClick={() => this.moveDepreciation(pipelineTree)}
                        />
                    </div>
                </Flex.Container>
            </Callout>
        ) : (
            <Callout icon={IconNames.INFO_SIGN} intent={Intent.PRIMARY}>
                This model is in an advanced state. You cannot use default mode for editing.
            </Callout>
        );

        return (
            <>
                <RouterStateLock
                    title="Save financial model changes?"
                    prompt={
                        `You have unsaved changes on ` +
                        `${this.props.template.description || 'this financial model'}` +
                        `, would you like to save these changes before leaving?`
                    }
                    cancellable={true}
                    showExitPrompt={this.props.hasChanges}
                    onSave={this.props.saveFinancialTemplate}
                    onDontSave={this.props.clearChanges}
                />
                <UpsellBanner />
                <div className="content-header">
                    <EditableTitleSubHeader
                        value={template.description}
                        updateFn={(val) => this.updateDescription(val)}
                        right={<>{advancedEditorAccess && AdvancedToggle}</>}
                    />
                </div>
                <div className="sub-content-inner" style={{ padding: '8px' }}>
                    {advancedEditorAccess && advancedTemplate && AdvancedNotification}
                    <Flex.Container>
                        <Card style={{ flex: 1 }}>
                            <Section2
                                title="Financial Model"
                                contextEl={
                                    auth.permissions.canMakeResourcePublic(user, template) && (
                                        <Switch
                                            label="Public"
                                            checked={template.public}
                                            onChange={this.togglePublic}
                                            large={true}
                                            style={{ marginBottom: 0 }}
                                        />
                                    )
                                }
                            >
                                <FinancialTemplateEditor
                                    template={template}
                                    update={this.props.updateFinancialTemplate}
                                    advanced={advancedMode}
                                />
                            </Section2>
                        </Card>
                        <Card style={{ justifyContent: 'flex-start', flex: 1 }}>
                            <Section2 title="Preview">
                                <Callout icon={IconNames.EYE_OPEN} intent={Intent.PRIMARY}>
                                    View Only: Financial configuration inputs are disabled for model preview.
                                </Callout>
                                <FinancialTemplatePreview template={template} advanced={advancedMode} />
                            </Section2>
                        </Card>
                    </Flex.Container>
                </div>
                <div className="sub-content-footer" />
                <ContextBarControls>{SaveCancelControls}</ContextBarControls>
            </>
        );
    }

    togglePublic = () =>
        this.props.updateFinancialTemplate({
            public: !this.props.template.public,
        });

    updateDescription(val) {
        this.props.updateFinancialTemplate({ description: val });
    }

    saveAndClose = async () => {
        await this.props.saveFinancialTemplate();
        this.props.goToView();
    };

    saveAsNew = async () => {
        const template = await this.props.saveAsNew();
        await this.props.clearChanges();
        this.props.goToView(template);
    };

    cancel = async () => {
        await this.props.clearChanges();
        this.props.goToView();
    };

    updateTree = (tree: FinancialPipelineTree) => {
        const root = tree.toRawData();
        const { template, updateFinancialTemplate } = this.props;
        const { data } = template;
        updateFinancialTemplate({ data: assign({}, data, { root }) });
    };

    moveDepreciation = (pipelineTree: FinancialPipelineTree) => {
        const depreciationIndex = pipelineTree.findNode('BasicTaxDepreciationSimple');
        const incentiveIndex = getLastIncentiveIndex(pipelineTree);
        if (depreciationIndex && incentiveIndex) {
            this.updateTree(pipelineTree.moveNodeAfter(depreciationIndex, incentiveIndex));
        }
        this.setState({ advancedMode: false });
    };
}

const mapStateToProps = (state: IAppState, _props: IOwnProps) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(({ template }) => ({
    clearChanges: saver.get(template).clear,
    hasChanges: saver.get(template).hasChanges,
    saveFinancialTemplate: saver.get(template).save,
    saveAsNew: () => templateActions.saveAsNew(template),
    updateFinancialTemplate: (patch) => saver.get(template).patch(patch),
    goToView: ({ financial_template_id } = template) =>
        routerActions.navigateTo('app.financial-templates.financial-template.preview', {
            finTemplateId: financial_template_id,
        }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(withRoute(FinancialTemplateEditContainer));
