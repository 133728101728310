import * as React from 'react';
import { useRoute } from 'react-router5';
import LinkButton, { ILinkButtonProps } from './LinkButton';

const LinkButtonWithParams = ({ text, ...linkButtonProps }: { text: string } & ILinkButtonProps) => {
    const { route } = useRoute();
    return <LinkButton routeParams={route.params} {...linkButtonProps} />;
};

export default LinkButtonWithParams;
