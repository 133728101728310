import { Intent } from '@blueprintjs/core';
import * as React from 'react';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import Callout from 'reports/components/core/controls/Callout';
import { User } from 'reports/models/user';
import { Subscription } from 'reports/models/subscription';
import { canManageBilling } from 'reports/modules/auth/permissions';
import { SummaryCard } from 'reports/modules/settings/common';

import { PlanInfo } from './PlanInfo';
import { TrialPlanInfo } from './TrialPlanInfo';

interface Props {
    includeHSAdmin?: boolean;
    subscription?: Subscription;
    user: User;
}

const MultiTeamCallout = () => (
    <Callout intent={Intent.WARNING}>
        Your team has multiple active subscriptions. Please <a href="mailto:support@helioscope.com">contact support</a>{' '}
        if you would like to merge your team{"'"}s subscriptions into one.
    </Callout>
);

const AccountStatusBox = ({ subscription, user }: Props) => {
    const { current_period_end, team } = user;

    const manageBilling = canManageBilling(user);
    if (!!subscription && subscription.status !== 'incomplete_expired') {
        // only render Billing Admin Callout component if current user is a billing admin
        // and the subscription payment has failed
        return (
            <SummaryCard>
                {team.num_active_subscriptions > 1 && <MultiTeamCallout />}
                <PlanInfo
                    manageBilling={manageBilling}
                    subscription={subscription}
                    user={user}
                    referrer={ReferrerTypes.manage_team_page}
                />
            </SummaryCard>
        );
    }
    return (
        <SummaryCard>
            <TrialPlanInfo
                manageBilling={manageBilling}
                periodEnd={current_period_end}
                user={user}
                referrer={ReferrerTypes.manage_team_page}
            />
        </SummaryCard>
    );
};

export default AccountStatusBox;
