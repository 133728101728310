import { find } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';

import * as auth from 'reports/modules/auth';
import * as inc from 'reports/models/incentive';

import { getModuleFromRegistry } from 'reports/modules/financials/model/modules';
import IncentivesList from 'reports/modules/incentive/components/IncentivesList';
import UpsellBanner from 'reports/components/UpsellBanner';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IStateProps & IDispatchProps;

class IncentiveLibrary extends React.PureComponent<IProps> {
    render() {
        return (
            <>
                <UpsellBanner />
                <LibraryMain
                    resourceIdName="incentive_id"
                    loadItems={this.props.loadItems}
                    refreshItem={this.props.refreshItem}
                    views={[
                        {
                            view: <IncentivesList />,
                            icon: IconNames.LIST,
                            id: 'incentive-list',
                        },
                    ]}
                    id="incentives-views"
                    quickFilters={[
                        {
                            name: 'team_id',
                            icon: IconNames.PEOPLE,
                            text: `Only Show My Team`,
                            value: this.props.user!.team.team_id,
                        },
                    ]}
                    contextBarControls={<CreateButton text="New Incentive" onClick={() => this.addSimpleIncentive()} />}
                />
            </>
        );
    }

    addSimpleIncentive() {
        const randid = Math.floor(performance.now() * 99999 + Math.random() * 99999);
        const incentiveRebateModule = getModuleFromRegistry('BasicIncentivesRebateSimple');

        this.props
            .createIncentive({
                name: `new incentive ${randid.toString(16)}`,
                incentive_type: 'fixed',
                configuration: {
                    amount: find(incentiveRebateModule.parameters, (i) => i.path === 'flat_rebate')!.default,
                    year: find(incentiveRebateModule.parameters, (i) => i.path === 'year')!.default,
                } as any,
                public: false,
            })
            .then((res) => this.goToEditIncentive(res.incentive_id));
    }

    goToEditIncentive(incentiveId) {
        this.props.navigateTo('app.incentives.incentive', { incentiveId });
    }
}

const mapStateToProps = (state) => ({
    refreshItem: (item) => inc.selectors.byObject(state, item, { creator: true, team: true }) || item,
    user: auth.selectors.getUser(state),
});

const mapDispatchToProps = bindActions(() => ({
    createIncentive: inc.api.create,
    loadItems: inc.api.index,
    navigateTo: routerActions.navigateTo,
}));

export default connect(mapStateToProps, mapDispatchToProps)(IncentiveLibrary);
