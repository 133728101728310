/* tslint:disable:max-line-length */
// prettier is buggy and sometimes formats lines to 121 chars
import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { Classes, H1, H2 } from '@blueprintjs/core';
import Colors from 'reports/styles/colors';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import { PrimaryButton, PrimaryIntent } from 'reports/components/core/controls';
import { Card, Flex } from 'reports/components/core/containers';

import * as usr from 'reports/models/user';
import { selectors as userSelectors } from 'reports/modules/auth';

const logo = require('reports/static/fl_logo_chrome.png');
const ShadeImage = require('reports/static/screen_shade.png');

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// Beta onboarding demo video URL
const WELCOME_VIDEO_URL =
    'https://player.vimeo.com/video/463665277?title=0&amp;byline=0&amp;portrait=0&amp;badge=\
0&amp;autopause=0&amp;player_id=0&amp;app_id=56727';

const PROPOSALS_LEARN_MORE_URL =
    'https://help-center.helioscope.com/hc/en-us/articles/8547323292051-Proposals-Overview';

const ContentContainer = styled(Card)`
    flex: 1 0 auto;
    padding: 0px;
    margin: 0px;

    .image-background {
        background: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(${ShadeImage});
        background-position: 'center';
        background-size: 'cover';
        background-repeat: 'no-repeat';
    }
`;

const Section = styled(Flex.ContainerV)`
    flex: 1;
    padding: 40px;
`;

const ContentRow = styled(Flex.Container)`
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;

const ContentSection = styled(Flex.ContainerV)`
    flex: 1;
    height: 100%;
`;

const Content = styled.div`
    flex: 1;
    font-size: 16px;
    text-align: justify;
    color: #4c4c4c;
`;

const Title = styled(H1)`
    && {
        color: #37474f;
        font-size: 32px;
        line-height: 1.3em;
        letter-spacing: 0.01em;
    }
`;

const SubtitleContent = styled.div`
    padding: 15px 0px 25px;
    font-size: 18px;
    text-align: justify;
    color: #4c4c4c;
    border-bottom: 1px solid ${Colors.HELIO_ORANGE};
`;

const Subtitle = styled(H2)`
    margin-bottom: 5px;

    &&& {
        color: #607d8b;
    }
`;

const ContentHeader = styled.div`
    .${Classes.HEADING} {
        color: #303030;
        font-size: 20px;
    }
`;

const VideoContainer = styled.div`
    min-height: 300px;
    padding: 10px;
`;

const ActionButton = styled(PrimaryButton).attrs({ large: true })`
    align-self: center;
    width: 250px;
    height: 50px;

    && {
        font-size: 20px;
    }
`;

const GetStartedButton = styled(ActionButton).attrs({
    text: 'Get Started',
    intent: PrimaryIntent.CREATE,
})``;

interface IDispatchProps {
    navigateTo: (name: string, params: any) => any;
    updateUser: (form: usr.IUserForm) => Promise<usr.User>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IDispatchProps;

class Welcome extends React.PureComponent<IProps> {
    componentDidMount() {
        const { user } = this.props;

        if (!user.beta_access_timestamp) {
            this.props.updateUser({ ...user, first_beta_access: true });
        }
    }

    render() {
        return (
            <div className="sub-container">
                <div
                    className="sub-sidebar"
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <ContentContainer>
                        <Section>
                            <ContentRow align={Flex.Align.CENTER} alignV={Flex.AlignV.CENTER}>
                                <ContentSection>
                                    <Title style={{ marginBottom: '0px' }}>
                                        <img
                                            src={logo}
                                            style={{
                                                margin: '-6px 10px 0px 0px',
                                                height: '30px',
                                            }}
                                        />
                                        Welcome to our Next Release
                                    </Title>
                                    <SubtitleContent>
                                        We’re excited to introduce you to the new version of HelioScope. We're adding
                                        much requested features like financial analysis and proposal generation. After
                                        months of testing and development we’re now ready for Open Beta – a phase where
                                        we encourage you to test the product and provide feedback before we launch the
                                        completed version.
                                    </SubtitleContent>
                                </ContentSection>
                            </ContentRow>
                            <ContentRow style={{ paddingTop: '25px' }}>
                                <ContentSection style={{ marginRight: '40px' }}>
                                    <ContentHeader>
                                        <Subtitle>Feedback Wanted</Subtitle>
                                    </ContentHeader>
                                    <Content>
                                        During this phase we want to hear from you…a lot. We’re still waiting to meet a
                                        customer who gives us too much feedback – and especially during Open Beta we are
                                        actively seeking your input on how to improve the tool. Is there something you
                                        want to do but can’t? Are you looking for something but don’t see it? Whatever
                                        it is, please let us know by using the 'Give Feedback' button in the navigation
                                        bar above.
                                    </Content>
                                </ContentSection>
                                <ContentSection>
                                    <ContentHeader>
                                        <Subtitle>Pricing</Subtitle>
                                    </ContentHeader>
                                    <Content>
                                        Financials and custom reports are early access features that are not included as
                                        part of the Basic plan subscription. We're still finalizing our pricing, but if
                                        you're excited about and would like to continue using these features, please{' '}
                                        <a href="mailto:support@helioscope.com" target="_blank">
                                            contact us
                                        </a>
                                        .
                                    </Content>
                                </ContentSection>
                            </ContentRow>
                            <ContentRow style={{ paddingTop: '25px' }}>
                                <ContentSection>
                                    <GetStartedButton onClick={() => this.props.navigateTo('app.projects', {})} />
                                </ContentSection>
                            </ContentRow>
                        </Section>
                        <Section className="image-background" style={{ paddingTop: '20px' }}>
                            <ContentRow>
                                <ContentSection style={{ marginRight: '75px' }}>
                                    <Title
                                        style={{
                                            paddingBottom: '10px',
                                            fontSize: '24px',
                                            borderBottom: `1px solid ${Colors.HELIO_ORANGE}`,
                                        }}
                                    >
                                        Key Features
                                    </Title>
                                </ContentSection>
                                <ContentSection />
                            </ContentRow>
                            <ContentRow>
                                <ContentSection style={{ marginRight: '75px' }}>
                                    <ContentHeader>
                                        <Subtitle style={{ fontSize: '18px' }}>Financial Analysis</Subtitle>
                                    </ContentHeader>
                                    <Content>
                                        You can perform detailed financial analyses for your customers and build custom
                                        financial models that match the way your team works. Inputting user consumption
                                        data is easy to do, and with a direct connection to your HelioScope design
                                        you’ll quickly see payback, ROI, and other financial metrics at the click of a
                                        button.
                                    </Content>
                                    <ContentHeader>
                                        <Subtitle style={{ fontSize: '18px' }}>Custom Proposal Documents</Subtitle>
                                    </ContentHeader>
                                    <Content>
                                        Looking professional is important, and with HelioScope’s beautiful proposal
                                        templates you can easily get your brand in a customer-facing document.
                                        Customizing the proposals to match your sales style is a simple drag and drop
                                        process. Smart tokens allow you to bring in design details and financial results
                                        into any text you want.
                                    </Content>
                                </ContentSection>
                                <ContentSection>
                                    <VideoContainer className="embed-responsive embed-responsive-16by9">
                                        <iframe
                                            className="embed-responsive"
                                            src={WELCOME_VIDEO_URL}
                                            allow="autoplay; fullscreen"
                                            title="HelioScope Beta"
                                        />
                                    </VideoContainer>
                                </ContentSection>
                            </ContentRow>
                            <ContentRow>
                                <ContentSection style={{ paddingTop: '25px' }}>
                                    <ActionButton
                                        text="Learn More"
                                        onClick={() => window.open(PROPOSALS_LEARN_MORE_URL, '_blank')}
                                    />
                                </ContentSection>
                            </ContentRow>
                        </Section>
                    </ContentContainer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: userSelectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
    updateUser: (form: usr.IUserForm) => usr.api.save({ ...form, email: 'me' }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
