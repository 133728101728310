import { FormGroup, Spinner } from '@blueprintjs/core';
import * as React from 'react';

import { capitalize } from 'lodash';

import { ReferrerType } from 'reports/analytics/ReferrerTypes';
import { IntentContainer } from 'reports/components/helpers/errors';
import { DetailLine } from 'reports/modules/settings/common';

import { getSubscriptionStatusIntent, Subscription, SubscriptionStatuses } from 'reports/models/subscription';
import { ITeamLimitsAndUsage } from 'reports/models/team';
import { User } from 'reports/models/user';

import { LicenseAllocation } from './LicenseAllocation';
import { CurrentPeriod } from './CurrentPeriod';
import { CurrentUsage } from './CurrentUsage';
import { canManageBilling } from 'reports/modules/auth/permissions';

interface Props {
    currentUsageAndLimits?: ITeamLimitsAndUsage | null;
    subscription: Subscription;
    user: User;
    referrer: ReferrerType;
}

const NOT_SET_TEXT = 'Not Set';

const ContractPlanInfo = ({ currentUsageAndLimits, referrer, subscription, user }: Props) => {
    if (!subscription) {
        return <Spinner />;
    }

    return (
        <>
            <FormGroup label="Account Status">
                <DetailLine>
                    <IntentContainer intent={getSubscriptionStatusIntent(subscription.status)}>
                        {SubscriptionStatuses[subscription.status]}
                    </IntentContainer>
                </DetailLine>
            </FormGroup>
            <FormGroup label="Invoice Term">
                <DetailLine>
                    {subscription.invoice_term_days ? `Net ${subscription.invoice_term_days}` : NOT_SET_TEXT}
                </DetailLine>
            </FormGroup>
            <FormGroup label="Contract Value">
                <DetailLine>
                    {subscription.total_contract_value
                        ? `$${subscription.total_contract_value.toLocaleString()}`
                        : NOT_SET_TEXT}
                </DetailLine>
            </FormGroup>
            <LicenseAllocation showUpdateCTA={false} subscription={subscription} referrer={referrer} />
            <FormGroup label="Payment Terms">
                <DetailLine>{subscription.interval ? capitalize(subscription.interval) : NOT_SET_TEXT}</DetailLine>
            </FormGroup>
            <CurrentUsage
                currentUsageAndLimits={currentUsageAndLimits}
                isTeamAdmin={user.team_admin}
                planType={user.subscription?.plan_type}
                showUpsell={false}
                subscription={subscription}
            />
            <CurrentPeriod
                manageBilling={canManageBilling(user)}
                referrer={referrer}
                subscription={subscription}
                showUpdateCTA={false}
                team={user.team}
                upcomingInvoice={undefined}
            />
        </>
    );
};

export { ContractPlanInfo };
