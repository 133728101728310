import { get, toPairs } from 'lodash';
import { Dispatch, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import * as des from 'reports/models/design';
import * as scen from 'reports/models/scenario';
import * as sim from 'reports/models/simulation';
import { actions as projActions } from 'reports/modules/project';

import { makeChannel } from 'helioscope/app/utilities/pusher';

export interface PusherChannel {
    watch(eventName: string, callback: (data: object) => void);
    triggerLocal(eventName: string, data: object);
    unsubscribe(): void;
}

const channels: { [k in string]: PusherChannel } = {};

const updateDesign = (partialDesign: { design_id: number; render_url?: string; currently_rendering: boolean }) =>
    des.schemaObj.dataLoaded(partialDesign);

const PROJECT_LISTENERS: {
    [k in string]: (data: any) => Action | ThunkAction<any, any, any, any>;
} = {
    'render.rendering': updateDesign,
    'render.complete': updateDesign,
    // 'render.saving': noop,
    'render.error': updateDesign,

    // TODO: handle deleted design is the primary design for the project
    deleteDesign: ({ design_id }) => des.schemaObj.entityDeleted({ design_id }),

    // simulations
    progress: (simData) => (dispatch, getState) => {
        const current = sim.selectors.byObject(getState(), simData);
        if (
            get(current, 'metadata.complete_slices', 0) < simData.metadata.complete_slices ||
            simData.metadata.finalized === true
        ) {
            dispatch(sim.schemaObj.dataLoaded(simData));
            if (simData.metadata.pending_slices === 0) {
                dispatch(sim.api.get({ simulation_id: simData.simulation_id }));
            }
        }
    },

    createdInitialScenario: (initialScenarioData) => (dispatch, getState) => {
        const initialScenario = initialScenarioData.scenario;
        dispatch(scen.schemaObj.dataLoaded(initialScenario));
        const scenario = scen.selectors.byObject(getState(), initialScenario)!;
        dispatch(projActions.setPrimaryScenario(scenario.project, scenario));
    },
};

export function installProjectListeners(projectId: number | string, dispatch: Dispatch) {
    if (channels[projectId] != null) return;

    const channel: PusherChannel = (channels[projectId] = makeChannel(`project@${projectId}`));

    for (const [evtName, callback] of toPairs(PROJECT_LISTENERS)) {
        channel.watch(evtName, (data) => dispatch(callback(data) as any)); // naive dispatch doesn't support thunks
    }
}

export function removeProjectListeners(projectId: number | string) {
    const channel = channels[projectId];
    delete channels[projectId];

    if (channel != null) {
        channel.unsubscribe();
    }
}
