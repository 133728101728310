import { get, set } from 'lodash';
import moment from 'moment';

import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { Alignment, Classes, ISwitchProps, PopoverPosition, Switch as BPSwitch, Tab, Tabs } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';

import { bindActions } from 'reports/utils/redux';
import { IAppState } from 'reports/types';
import { DEFAULT_LOCALE } from 'reports/localization';

import { fromNow } from 'reports/utils/formatters';

import SaveDropdown from 'reports/components/library/SaveDropdown';
import { ContextBarControls } from 'reports/components/ContextBar';
import { Card, Flex } from 'reports/components/core/containers';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import { Warning } from 'reports/components/helpers/errors';
import Toaster from 'reports/modules/Toaster';

import * as usr from 'reports/models/user';
import * as ur from 'reports/models/utility_rate';
import * as auth from 'reports/modules/auth';

import { getRateParam } from 'reports/modules/financials/utils';
import { validateSeasonalInput } from 'reports/modules/utility_rate/utils';
import { actions as rateActions } from 'reports/modules/utility_rate';

import OverviewPanel from './OverviewPanel';
import RatesPanel from './RatesPanel';

import * as styles from 'reports/styles/styled-components';
import { RouterStateLock } from 'reports/utils/router';

const styled = styles.styled;

enum TABS {
    DEMAND = 'demand',
    ENERGY = 'energy',
    OVERVIEW = 'overview',
}

const ContentBody = styled(Flex.Main)`
    margin: 10px 12px;

    .${Classes.TABS} {
        margin: 0 8px;
    }

    .${Classes.TAB_PANEL} {
        margin-top: 0;
        overflow: hidden;

        ${Card} {
            overflow-x: auto;
        }
    }
`;

export interface IRow {
    label: string;
    helperText?: string;
    labelStyle?: React.CSSProperties;
    leftEl?: JSX.Element;
    rightEl?: JSX.Element | string;
    showIf?: boolean;
    style?: React.CSSProperties;
}

export const Row = ({ label, rightEl, leftEl, helperText, style, showIf = true, labelStyle = { width: 150 } }: IRow) =>
    showIf ? (
        <Flex.Container className="center" style={{ minHeight: 30, margin: '6px 0', ...style }}>
            {leftEl}
            <Flex.ContainerV className="center" style={labelStyle}>
                <div>{label}</div>
                {helperText && <div style={{ fontSize: 12, fontWeight: 400 }}>{helperText}</div>}
            </Flex.ContainerV>
            {typeof rightEl === 'string' ? <div>{rightEl}</div> : rightEl}
        </Flex.Container>
    ) : null;

export const Switch = ({ style, ...props }: ISwitchProps & { style?: React.CSSProperties }) => (
    <BPSwitch style={{ marginBottom: 0, ...style }} large={true} {...props} />
);

interface IOwnProps {
    utilityRate: ur.UtilityRate;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IState {
    selectedTabId: string;
}

type IStateProps = { user: usr.User }; // Assume user exists at this point

class UtilityRateEditor extends React.PureComponent<IOwnProps & IDispatchProps & IStateProps, IState> {
    state: IState = {
        selectedTabId: TABS.OVERVIEW,
    };

    energyParam = getRateParam('energy_rates');
    demandParam = getRateParam('demand_rates');

    render() {
        const { utilityRate } = this.props;

        const lastModified = utilityRate.last_modified || utilityRate.created;
        const lastModifiedBy = utilityRate.last_modified_by_user || utilityRate.creator;

        const demandRates = this.getRateProp('data.demand_rates');
        const seasonalInput = this.getRateProp('data.seasonal_input');

        return (
            <>
                <RouterStateLock
                    title="Save utility rate changes?"
                    prompt={
                        `You have unsaved changes on ` +
                        `${utilityRate.description || 'this utility rate'}` +
                        `, would you like to save these changes before leaving?`
                    }
                    cancellable={true}
                    showExitPrompt={this.props.hasChanges}
                    onSave={this.save}
                    onDontSave={this.props.clearChanges}
                />

                <Flex.ContainerV style={{ flex: 1 }}>
                    <div className="content-header">
                        {!auth.permissions.canModifySharedTeamResource(this.props.user, utilityRate) && (
                            <div className="sub-content-box-1">
                                <div style={{ textAlign: 'center' }}>
                                    <Warning msg={'View Only. No permissions to edit.'} intent="warning" />
                                </div>
                            </div>
                        )}
                        <EditableTitleSubHeader
                            value={utilityRate.description}
                            updateFn={(val) => this.updateParameter('description', val)}
                        />
                    </div>

                    <Flex.Container style={{ margin: '12px 20px' }}>
                        <Flex.Main>
                            <Row
                                label="Owner"
                                rightEl={utilityRate.creator && utilityRate.creator.fullName()}
                                showIf={!!utilityRate.creator}
                            />
                            <Row
                                label="Last Modified"
                                rightEl={
                                    <div>
                                        <div>{lastModifiedBy && lastModifiedBy.fullName()}</div>
                                        <div>{fromNow(lastModified!)}</div>
                                    </div>
                                }
                                showIf={!!lastModifiedBy}
                                style={{ alignItems: 'flex-start' }}
                            />
                            <Row
                                label="Date Effective"
                                rightEl={
                                    <DateInput
                                        value={utilityRate.apply_date.toDate()}
                                        onChange={(dateObj) => this.updateParameter('apply_date', moment(dateObj))}
                                        formatDate={(dateObj) => dateObj.toLocaleDateString(DEFAULT_LOCALE.code)}
                                        parseDate={(dateStr) => new Date(dateStr)}
                                        placeholder={'M/D/YYYY'}
                                        popoverProps={{
                                            position: PopoverPosition.BOTTOM,
                                        }}
                                        minDate={new Date('1980-01-01T00:00:00')}
                                        maxDate={new Date('2120-01-01T00:00:00')}
                                    />
                                }
                            />
                        </Flex.Main>
                        <Flex.Main
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {auth.permissions.canMakeResourcePublic(this.props.user, utilityRate) && (
                                <Switch
                                    label="Public"
                                    checked={utilityRate.public}
                                    onChange={() => this.updateParameter('public', !utilityRate.public)}
                                    alignIndicator={Alignment.RIGHT}
                                    style={{ marginTop: 6 }}
                                />
                            )}
                        </Flex.Main>
                    </Flex.Container>

                    <ContentBody>
                        <Tabs
                            id="utility-rate-editor"
                            selectedTabId={this.state.selectedTabId}
                            onChange={(selectedTabId: string) => this.setState({ selectedTabId })}
                        >
                            <Tab
                                id={TABS.OVERVIEW}
                                title="Overview"
                                panel={
                                    <OverviewPanel
                                        onUpdate={(subpath, value) => this.updateParameter(`data.${subpath}`, value)}
                                        onDelete={this.onDelete}
                                        user={this.props.user}
                                        utilityRate={utilityRate}
                                    />
                                }
                            />
                            <Tab
                                id={TABS.ENERGY}
                                title="Energy Charges"
                                panel={
                                    <RatesPanel
                                        isEnergy={true}
                                        param={this.energyParam}
                                        rates={this.getRateProp('data.energy_rates')}
                                        seasonalInput={seasonalInput}
                                        onUpdate={(subpath, value) => this.updateParameter(`data.${subpath}`, value)}
                                    />
                                }
                            />
                            {demandRates && !seasonalInput ? (
                                <Tab
                                    id={TABS.DEMAND}
                                    title="Demand Charges"
                                    panel={
                                        <RatesPanel
                                            isEnergy={false}
                                            param={this.demandParam}
                                            rates={demandRates}
                                            seasonalInput={seasonalInput}
                                            onUpdate={(subpath, value) =>
                                                this.updateParameter(`data.${subpath}`, value)
                                            }
                                        />
                                    }
                                />
                            ) : null}
                        </Tabs>
                    </ContentBody>
                    <div className="sub-content-footer" />
                </Flex.ContainerV>
                <ContextBarControls>
                    <SaveDropdown
                        save={this.saveAndClose}
                        saveAsNewOptions={{
                            saveAsNew: this.saveAsNew,
                            text: 'Save as New Utility Rate',
                        }}
                        saveEnabled={
                            this.props.hasChanges() &&
                            auth.permissions.canModifySharedTeamResource(this.props.user, utilityRate)
                        }
                        cancel={this.cancel}
                        toastDescr={utilityRate.description}
                    />
                </ContextBarControls>
            </>
        );
    }

    getRateProp = (path: string) => get(this.props.utilityRate!, path, null);

    onDelete = async () => {
        try {
            await this.props.safeDeleteUtilityRate();
        } catch {
            Toaster.show({
                icon: 'warning-sign',
                message: <div>Can not delete rate: in use by one or more projects</div>,
                timeout: 2500,
            });
        }
    };

    preSave = async () => {
        const { utilityRate } = this.props;
        const { seasonal_input: seasonalInput, fixed_rates: fixedCharges } = utilityRate.data;

        if (seasonalInput) {
            const validation = validateSeasonalInput(utilityRate);

            if (validation.error) {
                return Promise.reject(validation.error);
            }

            // Update rate config if valid, currently only handles energy rates
            const config = utilityRate.toRateConfig();
            this.updateParameter('data.energy_rates', config);
        }

        if (fixedCharges) {
            fixedCharges.forEach((charge) => {
                if (!charge.name) {
                    throw 'All fixed charges must have names.';
                }
            });
        }
    };

    save = async () => {
        await this.preSave();
        return this.props.saveUtilityRate();
    };
    saveAndClose = async () => {
        await this.save();
        this.props.goToListView();
    };

    saveAsNew = async () => {
        await this.preSave();
        const newRate = await this.props.saveAsNew();
        await this.props.clearChanges();
        this.props.editUtilityRate(newRate.utility_rate_id);
    };

    cancel = async () => {
        await this.props.clearChanges();
        this.props.goToListView();
    };

    updateParameter = (path, val) => {
        if (this.props.user.team_id !== this.props.utilityRate.team_id) return;
        const patch = {};
        set(patch, path, val);
        this.props.updateUtilityRate(patch);
    };
}

const mapStateToProps = (state: IAppState, _ownProps: IOwnProps) => ({
    user: auth.selectors.getUser(state),
});

const mapDispatchToProps = bindActions(({ utilityRate }) => ({
    editUtilityRate: (id) =>
        routerActions.navigateTo('app.utility-rates.utility-rate', {
            rateId: id,
        }),
    goToListView: () => routerActions.navigateTo('app.utility-rates', {}),
    clearChanges: ur.saver.get(utilityRate).clear,
    hasChanges: ur.saver.get(utilityRate).hasChanges,
    saveUtilityRate: ur.saver.get(utilityRate).save,
    saveAsNew: () => rateActions.saveAsNew(utilityRate),
    updateUtilityRate: (patch) => ur.saver.get(utilityRate).patch(patch),
    safeDeleteUtilityRate: () => rateActions.safeDeleteUtilityRate(utilityRate),
}));

export default connect(mapStateToProps, mapDispatchToProps)(UtilityRateEditor);
