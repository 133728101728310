import * as React from 'react';
import { withRoute } from 'react-router5';
import { Text, Button, ButtonGroup } from '@aurorasolar/ds';
import { goToLogin, goToSignup } from './common';

export const PasswordUpdatedMessage = withRoute(({ router }) => {
    const goToForgotPassword = () => router?.navigate('app.forgot-password');
    return (
        <>
            <Text>Log into your account with your new password to start using HelioScope.</Text>
            <Button fullWidth size="md" type="submit" variant="primary" action={goToLogin}>
                Log In
            </Button>
            <ButtonGroup justifyContent="space-between">
                <Button size="md" variant="text" action={goToForgotPassword}>
                    Forgot password?
                </Button>
                <Button size="md" variant="text" action={goToSignup}>
                    Sign up
                </Button>
            </ButtonGroup>
        </>
    );
});

export default PasswordUpdatedMessage;
