import { Intent } from '@blueprintjs/core';
import * as React from 'react';
import { FormGroup } from 'reports/components/core/forms';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';
import { IntentContainer } from 'reports/components/helpers/errors';
import * as u from 'reports/models/user';
import {
    IModuleUploadItem,
    ISelectedModule,
    isNonAdminWithPublicModule,
    USER_DEFINED_MODULE_ID,
} from 'reports/modules/module/module_upload';
import { percentage } from 'reports/utils/formatters';
import ModuleUploadHelperText from './ModuleUploadHelperText';

interface IProps {
    item: IModuleUploadItem;
    user: u.User;
    setItemModule: (item: IModuleUploadItem, module: ISelectedModule) => void;
}

const ModuleUploadModuleCell: React.FC<IProps> = ({ item, user, setItemModule }) => {
    const { matchedChar, selectedModule, newModuleName, savedChar, status } = item;
    if (savedChar != null) {
        return (
            <td>
                <IntentContainer intent={Intent.SUCCESS}>
                    <strong>Successfully uploaded</strong>
                </IntentContainer>
            </td>
        );
    }

    // Rely on DetailsCell to span this cell when:
    // 1. Loading a PAN file, there's an error, or we're uploading a characterization.
    if (matchedChar == null || status === 'error' || status === 'uploading') {
        return null;
    }

    // 2. There are no similar modules (new module).
    const { module, metadata } = matchedChar;
    if (metadata.modules.length === 0) {
        return (
            <td>
                <i>
                    We'll add{' '}
                    <strong>
                        {module.manufacturer}, {module.name}
                    </strong>{' '}
                    to your database when saved.
                </i>
            </td>
        );
    }

    const perfectMatchExists = metadata.modules[0]._match_quality === 1;

    const selectOptions: ISelectedModule[] = metadata.modules.map((matchedMod) => {
        return {
            module_name: matchedMod.name,
            module_id: matchedMod.module_id,
            public: matchedMod.public,
            manufacturer: matchedMod.manufacturer,
            _match_quality: matchedMod._match_quality,
            source: matchedMod.source,
        };
    });

    // We either want to show "Create a new module" if newModuleName hasn't been defined,
    // or show the defined new module.
    selectOptions.unshift({
        module_name: newModuleName ? newModuleName : '',
        module_id: null,
        public: matchedChar.module.public,
        manufacturer: matchedChar.module.manufacturer,
        _match_quality: 0,
    });

    return (
        <td>
            <FormGroup
                helperText={
                    <ModuleUploadHelperText
                        perfectMatchExists={perfectMatchExists}
                        isPublicNonAdmin={isNonAdminWithPublicModule(user, selectedModule)}
                    />
                }
            >
                <BasicSelect
                    dataSource={{
                        items: selectOptions,
                    }}
                    itemRenderer={(m) => {
                        if (m.module_id === null) {
                            return {
                                key: USER_DEFINED_MODULE_ID,
                                text: m.module_name ? `${m.manufacturer}, ${m.module_name}` : 'Create a new module',
                            };
                        }
                        return {
                            key: m.module_id,
                            text: `${m.manufacturer}, ${m.module_name} (${percentage(m._match_quality)})`,
                        };
                    }}
                    value={selectedModule || null}
                    noneSelected={'- Select a Module -'}
                    onChange={(m: ISelectedModule) => setItemModule(item, m)}
                    maxButtonWidth={200}
                />
            </FormGroup>
        </td>
    );
};

export default ModuleUploadModuleCell;
