import * as React from 'react';

import { fallback } from 'reports/utils/helpers';
import BasicTable from 'reports/components/core/tables/BasicTable';
import { Power } from 'reports/components/core/numbers';
import { Module, CellTechnologyTypes } from 'reports/models/module';

interface IOwnProps {
    module: Module;
}

const ModuleSpecs: React.FC<IOwnProps> = ({ module }) => (
    <BasicTable tableTheme="specs" width="100%">
        <tbody>
            <tr>
                <th>Name</th>
                <td>{module.name}</td>
            </tr>
            <tr>
                <th>Manufacturer</th>
                <td>{module.manufacturer}</td>
            </tr>
            <tr>
                <th>Power</th>
                <td>
                    <Power value={module.power} />
                </td>
            </tr>
            <tr>
                <th>Technology</th>
                <td>
                    {CellTechnologyTypes[module.cell_technology_name]} ({module.series_cells * module.parallel_cells}{' '}
                    cells)
                </td>
            </tr>
            <tr>
                <th>Source</th>
                <td>{fallback(module.source, '-')}</td>
            </tr>
            <tr>
                <th>Team</th>
                <td>{fallback(module.team?.name, '-')}</td>
            </tr>
        </tbody>
    </BasicTable>
);
export default ModuleSpecs;
