import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import classNames from 'classnames';
import styled from 'styled-components';

import { Classes, Dialog, Divider, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Form, handleRequestException } from 'reports/components/forms';
import { FormTextInput, FormTextAreaInput, FormSwitchInput } from 'reports/components/forms/inputs/experimental';
import { Flex, Section2 as Section } from 'reports/components/core/containers';
import { Button as SecondaryButton, CreateButton } from 'reports/components/core/controls';

import * as pd from 'reports/models/power_device';
import * as pdChar from 'reports/models/power_device/PowerDeviceCharacterization';
import * as s3file from 'reports/models/s3file';

import * as auth from 'reports/modules/auth';
import { canMakeResourcePublic } from 'reports/modules/auth/permissions';
import AppToaster from 'reports/modules/Toaster';
import EfficiencyTable from 'reports/modules/power_device/components/EfficiencyTable';
import ModeledPerformanceChart from 'reports/modules/power_device/components/ModeledPerformanceChart';
import SpecSheet from 'reports/modules/power_device/components/SpecSheet';

import { IAppState } from 'reports/types';

import { bindActions } from 'reports/utils/redux';

const RightPanel = styled(Flex.ContainerV)`
    flex: 2;
    margin-left: 20px;
`;

const LeftPanel = styled(Flex.ContainerV)`
    flex: 1.4;
`;

interface IOwnProps {
    uploadedCharacterization: pd.PowerDeviceCharacterization;
    uploadedS3File: s3file.S3File;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IDispatchProps & IStateProps;

interface IState {
    isFileDialogOpen: boolean;
}

const DIALOG_WIDTH = 720;
const FORM_LABEL_WIDTH = 80;

class PowerDeviceUploadDialog extends React.PureComponent<IProps, IState> {
    state: IState = {
        isFileDialogOpen: true,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.uploadedCharacterization !== this.props.uploadedCharacterization) {
            this.setState({
                isFileDialogOpen: true,
            });
        }
    }

    onSave = async ({ name, description, public: isPublic }) => {
        const { uploadedS3File, createCharacterization, navigateTo } = this.props;

        const toast = AppToaster.show({ message: `Uploading ${name}` });

        let characterization;
        try {
            characterization = await createCharacterization({
                name,
                description,
                file_id: uploadedS3File.file_id,
                public: isPublic,
            });
        } catch (err) {
            AppToaster.dismiss(toast);
            throw err;
        }

        AppToaster.show(
            {
                message: `Successfully uploaded ${name}`,
                intent: Intent.SUCCESS,
            },
            toast,
        );

        this.onClose();
        navigateTo('app.power-devices.power-device.preview', {
            powerDeviceId: characterization.power_device_id,
            characterizationId: characterization.power_device_characterization_id,
        });
    };

    onClose = () =>
        this.setState({
            isFileDialogOpen: false,
        });

    render() {
        const { uploadedCharacterization, user } = this.props;
        const { isFileDialogOpen } = this.state;
        const uploadedPowerDevice = uploadedCharacterization.power_device;
        return (
            <Dialog
                title="Inverter Upload"
                isOpen={isFileDialogOpen}
                isCloseButtonShown={true}
                onClose={this.onClose}
                style={{ width: DIALOG_WIDTH, overflow: 'hidden' }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div style={{ padding: 10 }}>
                        <Form
                            baseValue={{ name: uploadedPowerDevice.name }}
                            onSubmit={this.onSave}
                            exceptionHandler={handleRequestException}
                        >
                            {({ submitForm }) => (
                                <>
                                    <FormTextInput
                                        inline
                                        fill
                                        bold
                                        autoFocus
                                        path="name"
                                        label="Name"
                                        type="text"
                                        labelWidth={FORM_LABEL_WIDTH}
                                    />
                                    <FormTextAreaInput
                                        inline
                                        fill
                                        bold
                                        path="description"
                                        label="Description"
                                        placeholder="Inverter Description"
                                        labelWidth={FORM_LABEL_WIDTH}
                                    />
                                    {canMakeResourcePublic(user, uploadedPowerDevice) && (
                                        <FormSwitchInput
                                            inline
                                            fill
                                            bold
                                            path="public"
                                            label="Public"
                                            labelWidth={FORM_LABEL_WIDTH}
                                            helperText="Make inverter available to all users."
                                        />
                                    )}
                                    <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                                        <SecondaryButton text="Cancel" onClick={this.onClose} />
                                        <CreateButton text="Upload" icon={IconNames.UPLOAD} onClick={submitForm} />
                                    </div>
                                </>
                            )}
                        </Form>

                        <Divider style={{ margin: '10 0' }} />

                        <Flex.Container>
                            <LeftPanel>
                                <Section title="Spec Sheet">
                                    <SpecSheet device={uploadedPowerDevice} />
                                </Section>
                            </LeftPanel>
                            <RightPanel>
                                <Section title="Modeled Performance">
                                    <ModeledPerformanceChart characterization={uploadedCharacterization} />
                                </Section>
                                <Section title="Efficiency Table">
                                    <EfficiencyTable characterization={uploadedCharacterization} />
                                </Section>
                            </RightPanel>
                        </Flex.Container>
                    </div>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    navigateTo: routerActions.navigateTo,
    createCharacterization: pdChar.api.create,
}));

export default connect(mapStateToProps, mapDispatchToProps)(PowerDeviceUploadDialog);
